/**  checkbox list */

export const assignCheckbox = [
    { id: "lcm_bal", label: "OUC" },
    { id: "cst_name", label: "CST Name" },
    { id: "cable", label: "Cable ID" },
    { id: "exchange_name", label: "Exchange name" },
    { id: "pcp", label: "PCP" },
    { id: "No_jumpers", label: "No.Jumpers" },
    { id: "postcode", label: "Post Code" },
    { id: "file_name", label: "Jumper Schedule" },
    { className:"planner_name_assign",id: "planner_name", label: "Planner Name" },
    { id: "assign_plannermobno", label: "Planner Mobile No" },
    { id: "assignee_name", label: "Assigned Name" },
    { className:"assigned_date" , id: "assigned_date", label: "Assigned Date" },
    { id: "completed_name", label: "Completed Name" },
    { id: "completed_date", label: "Completed Date" },
    { className:"status_assign",  id: "status", label: "Status"}

];

/** new checkbox list */


export const newCheckboxes = [    
{ id: "assigned_for", label: "Assigned for" },
{ id: "comp_status", label: "Cable Status" },
{ className:"comments_assign",id: "comments", label: "Comments" },
{ id: "routings_changed", label: "No.RoutingsChanged" },
{ id: "incorrect_routings", label: "No. Incorrect Routings" },
{ id: "engineer_name", label: "Completed Name2" },
{ id: "created_at", label: "Created Date" }

];

/**  header list */

export const assignHeader = [
    {
        id: 'lcm_bal',
        numeric: false,
        disablePadding: true,
        label: 'OUC',
        width: '10%',
    },
    {
        id: 'cst_name',
        numeric: false,
        disablePadding: true,
        label: 'CST Name',
        width: '9%',
    },
    {
        id: 'cable',
        numeric: false,
        disablePadding: true,
        label: 'Cable ID',
        width: 95,
    },
    {
        id: 'exchange_name',
        numeric: false,
        disablePadding: true,
        label: 'Exchange name',
        width: '100',
    },
    {
        id: 'pcp',
        numeric: false,
        disablePadding: true,
        label: 'PCP',
        width: 90,
    },
    {
        id: 'No_jumpers',
        numeric: false,
        disablePadding: true,
        label: 'No.Jumpers',
        width: 90,
    },
    {
        id: 'postcode',
        numeric: false,
        disablePadding: true,
        label: 'Post Code',
        width: 90,
    },
    {
        id: 'file_name',
        numeric: false,
        disablePadding: true,
        label: 'Jumper Schedule',
        width: '11%',
    },
    {
        id: 'planner_name',
        numeric: false,
        disablePadding: true,
        label: 'Planner Name',
        width: 90,
    },
    {
        id: 'assign_plannermobno',
        numeric: false,
        disablePadding: true,
        label: 'Planner Mobile No',
        width: 90,
    },
    {
        id: 'assignee_name',
        numeric: false,
        disablePadding: true,
        label: 'Assigned Name',
        width: 90,
    },
    {
        id: 'assigned_date',
        numeric: false,
        disablePadding: true,
        label: 'Assigned Date',
        width: 90,
    },
    
    {
        id: 'completed_name',
        numeric: false,
        disablePadding: true,
        label: 'Completed Name',
        width: 90,
    },
    {
        id: 'completed_date',
        numeric: false,
        disablePadding: true,
        label: 'Completed Date',
        width: 90,

    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
        width: 90,
    }];

/** select  Header  */

export const selectHeader = [
    {
        id: 'workstack_select',
        numeric: false,
        disablePadding: true,
        label: 'Select',
        width: 90,


    }]

/**  new header list */

export const newHeaders = [
    {
        id: 'assigned_for',
        numeric: false,
        disablePadding: true,
        label: 'Assigned for',
        width: 90,
    },
     
    

    {
        id: 'comp_status',
        numeric: false,
        disablePadding: true,
        label: 'Cable Status',
        width: 90,
    },
    {
        id: 'comments',
        numeric: false,
        disablePadding: true,
        label: 'Comments',
        width: 90,
    },
    {
        id: 'routings_changed',
        numeric: false,
        disablePadding: true,
        label: 'No. Routings Changed',
        width: 90,
    },
   
   
    {
        id: 'incorrect_routings',
        numeric: false,
        disablePadding: true,
        label: 'No. Incorrect Routings',
        width: 90,
    },
    {
        id: 'engineer_name',
        numeric: false,
        disablePadding: true,
        label: 'Completed Name2',
        width: 90,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: true,
        label: 'Created Date',
        width: 90,
    }



];



