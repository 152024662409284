import React, { useState, useEffect } from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Tablepagination from '../common/Tablepagination';

const CabinetInfo = ({ cabinetinfo }) => {
    // table cells
    const columns = ["BT Object Class", "BT Label", "Equipment Name", "Structure Name", "Other Termination Cables"]

    useEffect(() => {
        if (cabinetinfo) {
            if (cabinetinfo.length <= rowsPerPage) { setPage(0) }
        }
        // eslint-disable-next-line
    }, [cabinetinfo])
    const [page, setPage] = useState(parseInt(localStorage.getItem("cabinetpage")) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }


    return (
        <div>
            <p style={{ marginTop: "5px", fontSize: "15px", textAlign: "center", fontWeight: "bold", color: "#5488c7" }}>Cabinet Info</p>
            <TableContainer id="cabinet-info-TableContainer" component={Paper}>
                <Table id="cabinet-info-Table" >

                    <TableHead id="cabinet-info-TableHead">
                        <TableRow id="cabinet-info-Table-row" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                            {columns.map((headcell) => (
                                <TableCell id={"cabinet-info-" + headcell} key={headcell} align='center' sx={{ color: "white", minWidth: "50px", margin: 0, padding: "4px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px" }}>
                                    {headcell}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            cabinetinfo !== null && cabinetinfo !== undefined ? (cabinetinfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((row, index) => (
                                <TableRow id={"cabinet-info-Table-row" + index} key={row.BT_Label}>
                                    <TableCell id={"cabinet-info-tablecell-BT_Object_Class-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.BT_Object_Class}</TableCell>
                                    <TableCell id={"cabinet-info-tablecell-BT_Label-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.BT_Label}</TableCell>
                                    <TableCell id={"cabinet-info-tablecell-Equipment_Name-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Equipment_Name}</TableCell>
                                    <TableCell id={"cabinet-info-tablecell-Structure_Name-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Structure_Name}</TableCell>
                                    <TableCell id={"cabinet-info-tablecell-Other_Termination_cables-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Other_Termination_cables}</TableCell>

                                </TableRow>
                            )) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            
             <Tablepagination
                        setPage={setPage}
                        page={page}
                        count={cabinetinfo !== null && cabinetinfo !== undefined && cabinetinfo.length ? cabinetinfo.length : 0}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPage={rowsPerPage}
                    />
        </div>
    )
}

export default CabinetInfo