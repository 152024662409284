import React, { useState, useEffect } from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Tablepagination from '../common/Tablepagination';

const CableInfo = ({ cableinfo }) => {
    // table cells
    const columns = ["Recovery Cable", "Bar Pair Range", "Working", "Spares", "Faulty", "Others", "From Structure", "To Structure", "From Equipment", "To Equipment", "Direct DPs"]

    const [page, setPage] = useState(parseInt(localStorage.getItem("cablepage")) || 0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (cableinfo) {
            if (cableinfo.length <= rowsPerPage) { setPage(0); }
        }
        // eslint-disable-next-line
    }, [cableinfo])

    // pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    }

    return (
        <div>
            <p id="cable-info" style={{ marginTop: "2px", fontSize: "15px", textAlign: "center", fontWeight: "bold", color: "#5488c7" }}>Cable Info</p>
            <TableContainer id="cable-info-table-container" component={Paper}>
                <Table id="cable-info-table" >

                    <TableHead id="cable-info-table-head">
                        <TableRow id="cable-info-table-row" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                            {columns.map((headcell, index) => (
                                <TableCell id={"cable-info-table-" + headcell} key={headcell} align='center' sx={{ color: "white", minWidth: "50px", margin: 0, padding: "4px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px" }}>
                                    {headcell}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            cableinfo !== null && cableinfo !== undefined ? (cableinfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((row, index) => (
                                <TableRow id={"cable-info-table-row" + index} key={row.id} >
                                    <TableCell id={"cable-info-tablecell-Recovery_Cable-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Recovery_Cable}</TableCell>
                                    <TableCell id={"cable-info-tablecell-Bar_Pair_Range-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Bar_Pair_Range}</TableCell>
                                    <TableCell id={"cable-info-tablecell-Working-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Working}</TableCell>
                                    <TableCell id={"cable-info-tablecell-Spares-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Spares}</TableCell>
                                    <TableCell id={"cable-info-tablecell-Faulty-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Faulty}</TableCell>
                                    <TableCell id={"cable-info-tablecell-Others-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Others}</TableCell>
                                    <TableCell id={"cable-info-tablecell-From_Structure-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.From_Structure}</TableCell>
                                    <TableCell id={"cable-info-tablecell-To_Structure-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.To_Structure}</TableCell>
                                    <TableCell id={"cable-info-tablecell-From_Equipment-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.From_Equipment}</TableCell>
                                    <TableCell id={"cable-info-tablecell-To_Equipment-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.To_Equipment}</TableCell>
                                    <TableCell id={"cable-info-tablecell-Direct_DPs-" + index} sx={{ border: "1px solid #c2c2c2", margin: 0, fontSize: '11px', padding: 0.5 }} align='center'>{row.Direct_DPs}</TableCell>
                                </TableRow>
                            )) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>


            <Tablepagination
                setPage={setPage}
                page={page}
                count={cableinfo !== null && cableinfo !== undefined && cableinfo.length ? cableinfo.length : 0}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
            />
        </div>
    )
}

export default CableInfo