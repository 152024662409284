/** existing checkbox list */

export const workstackAssignCheckboxList = [
    { id: "ouc", label: "OUC" },
    { id: "cst_name", label: "CST Name" },
    { id: "cable_id", label: "Cable ID" },
    { id: "exchange_name", label: "Exchange name" },
    { id: "pcp", label: "PCP" },
    { id: "no_jumpers", label: "No.Jumpers" },
    { id: "post_code", label: "Post Code" },
    { id: "fn", label: "Jumper Schedule" },
    { id: "planner_name", label: "Planner Name" },
    { id: "planner_mobno", label: "Planner Mobile No" },
    { id: "assigned_name", label: "Assigned Name" },
    { id: "assigned_date", label: "Assigned Date" },
    { id: "completed_name", label: "Completed Name" },
    { id: "completed_date", label: "Completed Date" },
    { id: "js_id", label: "Records+ Id "},
    { id: "status", label: "Status" }

];

/** new checkbox list */


export const newCheckboxes = [ { id: "assigned_for", label: "Assigned for" },
    { id: "cable_status", label: "Cable Status" },
{ id: "no_routings_changed", label: "No. Routings Changed" },
{ id: "comments", label: "Comments" },
{ id: "completed_name2", label: "Completed Name2" },
{ id: "no_incorrect_routings", label: "No. Incorrect Routings" },
{ id: "created_date", label: "Created Date" }

];

/** existing table-header list */

export const workstackHeader = [
    {
        id: 'ouc',
        numeric: false,
        disablePadding: true,
        label: 'OUC',
        width: '10%'
    },
    {
        id: 'cst_name',
        numeric: false,
        disablePadding: true,
        label: 'CST Name',
        width: '9%'
    },
    {
        id: 'cable_id',
        numeric: false,
        disablePadding: true,
        label: 'Cable ID',
        width: 95
    },
    {
        id: 'exchange_name',
        numeric: false,
        disablePadding: true,
        label: 'Exchange name',
        width: '100'
    },
    {
        id: 'pcp',
        numeric: false,
        disablePadding: true,
        label: 'PCP',
        width: 90
    },
    {
        id: 'no_jumpers',
        numeric: false,
        disablePadding: true,
        label: 'No.Jumpers',
        width: 90
    },
    {
        id: 'post_code',
        numeric: false,
        disablePadding: true,
        label: 'Post Code',
        width: 90
    },
    {
        id: 'fn',
        numeric: false,
        disablePadding: true,
        label: 'Jumper Schedule',
        width: '11%'
    },
    {
        id: 'planner_name',
        numeric: false,
        disablePadding: true,
        label: 'Planner Name',
        width: 90
    },
    {
        id: 'planner_mobno',
        numeric: false,
        disablePadding: true,
        label: 'Planner Mobile No',
        width: 90
    },
    {
        id: 'assigned_name',
        numeric: false,
        disablePadding: true,
        label: 'Assigned Name',
        width: 90
    },
    {
        id: 'assigned_date',
        numeric: false,
        disablePadding: true,
        label: 'Assigned Date',
        width: 90
    },
    {
        id: 'completed_name',
        numeric: false,
        disablePadding: true,
        label: 'Completed Name',
        width: 90
    },
    {
        id: 'completed_date',
        numeric: false,
        disablePadding: true,
        label: 'Completed Date',
        width: 90

    },
    {
        id: 'js_id',
        numeric: false,
        disablePadding: true,
        label: 'Records+ Id ',
        width: 90

    },

    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
        width: 90
    }];


export const selectHeader = [
    {
        id: 'workstack_select',
        numeric: false,
        disablePadding: true,
        label: 'Select',
        width: 90,


    }]

/** new table-header list */

export const newHeadcell = [
    {
        id: 'assigned_for',
        numeric: false,
        disablePadding: true,
        label: 'Assigned For',
        width: 90

    },
    {
        id: 'cable_status',
        numeric: false,
        disablePadding: true,
        label: 'Cable Status',
        width: 90
    },
    {
        id: 'comments',
        numeric: false,
        disablePadding: true,
        label: 'Comments',
        width: 90
    },
    {
        id: 'no_routings_changed',
        numeric: false,
        disablePadding: true,
        label: 'No. Routings Changed',
        width: 90
    },
   
   
    {
        id: 'no_incorrect_routings',
        numeric: false,
        disablePadding: true,
        label: 'No. Incorrect Routings',
        width: 90
    },
    {
        id: 'completed_name2',
        numeric: false,
        disablePadding: true,
        label: 'Completed Name2',
        width: 90
    },
    {
        id: 'created_date',
        numeric: false,
        disablePadding: true,
        label: 'Created Date',
        width: 90
    }



];
