const headCells = [
         {
        id: 'Exch1141',
        label: 'Exch1141',
        padding: "0 0 0 3px",
        width: 75
        },
    {
        id: 'RecoveryCable',
        label: 'Recovery Cable',
        padding: "0 0 0 8px",
        width: 80

    },
    {
        id: 'Cabinets',
        label: 'Cabinets',
        padding: "0 0 0 8px",
        width: 75
        },
    {
        id: 'Diverts',
        label: 'Diverts',
        padding: "0 0 0 10px",
        width: 70
        },
    {
        id: 'Distance',
        label: 'Distance',
        padding: "0 13px",
        width: 80
        },
    {
        id: 'Tonnage',
        label: 'Tonnage',
        padding: "0 0 0 6px",
        width: 75
        },
    {
        id: 'PotentialNet',
        label: 'Potential Net',
        padding: "0 7px",
        width: 80

    },
    {
        id: 'Category',
        label: 'Category',
        padding: "0 6px",
        width: 75
        },
    {
        id: 'Solution',
        label: 'Solution',
        padding: "0 0 0 10px",
        width: 75
        },
    {
        id: 'View',
        label: 'View',
        padding: "0 0 0 15px",
        width: 30
        },
    {
        id: 'Warnings',
        label: 'Warnings',
        padding: "0 0 0 18px",
        width: 80
        },
    {
        id: 'Username',
        numeric: false,
        padding: "0 0 0 15px",
        label: 'Username',
        width: 80
    },
];
export default headCells;