import React, { useState, useEffect, useContext } from 'react';
import { BASE_URL, HCR, UPDATE_POPUP, SECOND_ENGINEER } from "../../constant/urls";

import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert, Snackbar, Link, CircularProgress, Backdrop, Button, Grid, Dialog, Typography, MenuItem, Box, Paper, TextField } from '@mui/material';
import axios from 'axios';
import { ADMIN, ENGINEER_MANAGER, PLANNER } from "../../constant/userConfig"
import { AllContext } from '../common/BreadCrumbContext';
import { useMsal } from '@azure/msal-react';
import Select from 'react-select';
import { extractDigits, handlesecondEngineerChange } from './HcrPlanningPopup.util';


export default function ViewPopup({ planningPopup, onDownload,
    getHomeDetails, pcp, row, cable_id, sau, clickEventFromChild, jobId, getCardDetails }) {
    const { userData } = useContext(AllContext)
    const { accounts } = useMsal()



    const params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    const FIRST_NAME = JSON.parse(localStorage.getItem("FIRST_NAME"))
    const LAST_NAME = JSON.parse(localStorage.getItem("LAST_NAME"))
     const handlevalue = () => {
        if ((userData?.ROLE === ADMIN || userData?.ROLE === ENGINEER_MANAGER) && (planningPopup === "Completed" || planningPopup === "Issue")) {
            return row.assigned_name
        }
        else {
            return FIRST_NAME + " " + LAST_NAME +`[${accounts[0]?.idTokenClaims.EIN}]` || ""
        }
    }
    const [popupOpen, setPopUpOpen] = useState(false);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [notes, setNotes] = useState('');
    const [showLink, setShowLink] = React.useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const handleClose = () => {
        setAlertOpen(false);
    };
    const [isComplete, setIsComplete] = React.useState(false)


    /** routing incorrect */
    const [selectedRoutingIncorrectNumber, setSelectedRoutingIncorrectNumber] = useState(null);

    /**routing changed number */
    const [selectedRoutingChangedNumber, setSelectedRoutingChangedNumber] = useState(null);
    const [engineers, setEngineers] = useState([]);
    const [engineersObject, setEngineersObject] = useState([]);
    const [selectedEngineer, setselectedEngineer] = useState(null);

    /**
     * routing incorrect handler
     */
    const handleRoutingIncorrect = (newNumber) => {
        setSelectedRoutingIncorrectNumber(newNumber);
    };

    /**
     * routing changed
     */
    const handleRoutingChanged = (newNumber) => {
        setSelectedRoutingChangedNumber(newNumber);
    };


    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    const handleOption = (option) => {
        if (option === "yes") {
            setShowAdditionalFields(true);
            setIsComplete(true)


        } else if (option === "no") {
            setShowAdditionalFields(true);
            setIsComplete(false)


        }
    }

    /**
     * pop up open event
     *
     */
    const handleClickOpen = () => {
        setPopUpOpen(true);
        getSecondEngineer();

    };

    /**
    * pop up close event
    *
    */
    const handleClosePopup = () => {
        setPopUpOpen(false);
        setShowAdditionalFields(false);
        setShowLink(false);
        setselectedEngineer(null);
        setSelectedRoutingIncorrectNumber(null);
        setSelectedRoutingChangedNumber(null);
        setNotes('');


    };


    /**
     *
     * note update section 
     * @param {*} event
     */
    const handleChange = (event) => {
        setNotes(event?.target.value);
    }


    /**
     *  format date time
     *
     * @param {*} current
     * @return {*} 
     */
    const setTime = (current) => {
        let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        let cTime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        let dateTime = cDate + ' ' + cTime;
        return dateTime
    }


    /**
     *  apply changes
     *
     */

    const applydisable = !selectedEngineer || !(selectedRoutingIncorrectNumber === 0 ? (JSON.stringify(selectedRoutingIncorrectNumber)) : selectedRoutingIncorrectNumber) || !(selectedRoutingChangedNumber === 0 ? (JSON.stringify(selectedRoutingChangedNumber)) : selectedRoutingChangedNumber)

    const handleApply = (e) => {

        e.preventDefault();
        let params = {
            "pcp": row['pcp'] ? row['pcp'] : '',
            "cable": cable_id,
            "logged_user": accounts[0]?.idTokenClaims.EIN,
            "sau": sau,
            "is_complete": isComplete,

            "s_eng": selectedEngineer,

            "start_date": setTime(startDate),
            "end_date": setTime(endDate),
            "incorrect_routings": selectedRoutingIncorrectNumber,
            "changed_routings": selectedRoutingChangedNumber,
            "note": notes,
            "assignee_name": (userData?.ROLE === ADMIN || userData?.ROLE === ENGINEER_MANAGER) && (planningPopup === "Completed" || planningPopup === "Issue") ? firstEnginner : FIRST_NAME + " " + LAST_NAME +`[${accounts[0]?.idTokenClaims.EIN}]`,

        }

        const url = `${BASE_URL}${HCR}/${UPDATE_POPUP}`;
        axios.put(url, params,)
            .then((res) => {

                getHomeDetails();
                getCardDetails();

                setOpen(true);
                setSnackMsg("data saved sucessfully");
                setShowAdditionalFields(false);
                setselectedEngineer(null);
                setFirstEnginner(null);
                setSelectedRoutingIncorrectNumber(null);
                setSelectedRoutingChangedNumber(null);
                setNotes('');
            })
            .catch((error) => {
                setSnackMsg('Something went wrong!.Please try again!');
                setAlertOpen(true);
            })
        clickEventFromChild();
        handleClosePopup();
    }

    const getSecondEngineer = () => {
        const url = `${BASE_URL}${HCR}/${SECOND_ENGINEER}?login_user=${selectedEngineer}`;
        axios.get(url, { params: params })
            .then((res) => {
                const value = res.data.results
                const enginnersobject = value.map(item => {
                    const { first_name, ...rest } = item;
                    return { ...rest, "value": item.first_name, "label": item.first_name }
                })
                setEngineersObject(enginnersobject)
                setEngineers(value);
                setLoading(false);
                setOpen(true);
                setSnackMsg("data saved sucessfully");
            })
            .catch((error) => {
                setLoading(false);
                setSnackMsg('Something went wrong!.Please try again!');
                setAlertOpen(true);
            })
    }


    /** jumper schedule download in popup */

    const jumperScheduleDownload = () => {
        clickEventFromChild('jumper', row['fn'], jobId);
        setShowLink(true)
    }
    const dropdown = handlevalue()
    const value = engineers?.find((item) => item.first_name.includes(dropdown));
    const firstengg = value ? value.first_name : null
    const [firstEnginner, setFirstEnginner] = useState(firstengg);
    useEffect(() => {
        if (firstEnginner === null) {
            setFirstEnginner(firstengg)
        }
    }, [firstEnginner, dropdown, firstengg])

 
    const handleEngineerChange = (notes) => {
        const selectedValue = notes.value;
        setselectedEngineer(selectedValue);
    };
    const customstyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: '12px',
            width: '260px',
            minHeight: '40px'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        }),
        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999
        })
    }
    


    return (
        <div>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }

            <Button data-testid="status-button" style={{ background: userData?.ROLE === PLANNER && (planningPopup === "Completed" || planningPopup === "Issue") ? 'rgb(150, 150, 180)' : "#142032", color: "white", fontSize: 11, margin: 0, cursor: "pointer" }}
                onClick={handleClickOpen} disabled={userData?.ROLE === PLANNER && (planningPopup === "Completed" || planningPopup === "Issue")}>{planningPopup}</Button>

            <Dialog
                id="workstack_popup"
                open={popupOpen}
                onClose={handleClosePopup}
                aria-labelledby="popup-dialog-title"
                aria-describedby="popup-dialog-description"
                sx={{ marginTop: "-30px", marginLeft: "-231px" }}
            >
                <Box sx={{ flexGrow: 1, width: 900, overflow: "hidden", height: "auto", display: "flex", padding: "20px", alignItems: "center", flexDirection: 'column' }} component={Paper}>
                    <Typography sx={{ paddingTop: "10px", marginLeft: "20px", width: "930px", paddingLeft: "353px", fontSize: 18, background: "#142032", color: 'white', marginTop: "-20px", height: "50px" }}>
                        HCR: Close PCP
                    </Typography>
                    <Button style={{ width: "260px", marginRight: "598px", marginTop: "17px", color: "white", backgroundColor: "#142032", height: "40px", float: "right" }} data-tsetid="view-jumper-schedule" onClick={jumperScheduleDownload}>View Jumper Schedule</Button>
                    {showLink &&
                        <div  >
                            <Link
                                id="download-Jumper-Report"
                                variant="body2"
                                underline='hover'
                                onClick={() => {
                                    onDownload(row['fn'], jobId);
                                    setOpen(false);
                                }}
                                data-testid="download-Jumper-Report"
                                sx={{ fontSize: 14, color: 'Red', marginLeft: "-429px", '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the Jumper Report
                            </Link>
                        </div>

                    }
                    <Box sx={{ '& .MuiTextField-root': { m: 2, width: '260px' }, }} noValidate autoComplete="off"  >

                        <form>
                            <div style={{ marginLeft: "0" }}>

                                <TextField
                                    size="small"
                                    id="postcode"
                                    label="Postcode"
                                    inputProps={{ readOnly: true }}
                                    value={row.post_code || ""}

                                />

                            </div>
                            <div style={{ marginLeft: "300px", marginTop: "-124px" }}>
                                <TextField
                                    size="small"
                                    id={"pcpName"}
                                    label="Pcp"
                                    value={row.pcp || ""}
                                    inputProps={{ readOnly: true }}
                                    autoComplete="current-Pcp"

                                />

                            </div>
                            <div style={{ marginLeft: "600px", marginTop: "-72px" }}>
                                <TextField
                                    size="small"
                                    id="no_jumpers"
                                    label="No Jumpers"
                                    inputProps={{ readOnly: true }}
                                    value={row.no_jumpers || ""}


                                />
                            </div>
                            <div style={{ marginLeft: "300px", marginTop: "-21px" }} >
                                <TextField
                                    size="small"
                                    id="wtw"
                                    label="WTW"
                                    inputProps={{ readOnly: true }}
                                />
                            </div>
                            <div style={{ marginLeft: "600px", marginTop: "-72px" }}>
                                <TextField
                                    size="small"
                                    id="plannermn"
                                    label="Planner Mobile No"
                                    inputProps={{ readOnly: true }}

                                />
                            </div>
                            <div style={{ marginLeft: "0px", marginTop: "-10px" }}>
                                <TextField
                                    id="check_yes_no"
                                    size="small"
                                    select
                                    data-testid="check_yes_no"
                                    label="Have you completed all Jumpers?"

                                >

                                    <MenuItem id="yes-menu-item" value="1" data-testid="yes-menu-item" onClick={() => handleOption('yes')}>yes </MenuItem>
                                    <MenuItem id="no-menu-item" value="2" data-testid="no" onClick={() => handleOption('no')}>no </MenuItem>

                                </TextField>
                            </div>

                            {showAdditionalFields && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        {(userData?.ROLE === ADMIN || userData?.ROLE === ENGINEER_MANAGER) && (planningPopup === "Completed" || planningPopup === "Issue") ?
                                            <Select
                                                onChange={(choose)=>handlesecondEngineerChange(choose,setFirstEnginner)}
                                                menuPortalTarget={document.body}
                                                options={engineersObject}
                                                styles={customstyles}
                                                data-testid="1_engineer"
                                                placeholder="1st Engineer*"
                                                id="first_engineer"
                                                inputId="1_engineer"
                                                getOPtionValue={(option) => "1st_engineer-" + extractDigits(option.value)} /> :
                                            <TextField
                                                size="small"
                                                id="1_engineer"
                                                label="1st Engineer"
                                                inputProps={{ readOnly: true }}
                                                value={handlevalue()}

                                            />}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            onChange={handleEngineerChange}
                                            menuPortalTarget={document.body}
                                            options={engineersObject}
                                            styles={customstyles}
                                            data-testid="2_engineer"
                                            placeholder="2nd Engineer*"
                                            id="second_engineer-select-label"
                                            inputId="planning-select-second_engineer"
                                            getOPtionValue={(option) => "2nd_engineer-" + extractDigits(option.value)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Datepicker
                                            id="startdate_popup"
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Datepicker
                                            id="enddate_popup"
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            showTimeInput
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="no_incorrect_routings"
                                            size="small"
                                            select
                                            label="No of Incorrect Routings*"
                                            value={selectedRoutingIncorrectNumber}
                                            data-testid="no_incorrect_routings"
                                            onChange={(e) => {
                                                setSelectedRoutingIncorrectNumber(e.target.value);
                                            }}
                                        >
                                            {Array.from({ length: 101 }, (_, index) => (
                                                <MenuItem id={'incorrect_routings-' + index} key={index} data-testid={`incorrect_routings-${index}`} value={index} onClick={() => handleRoutingIncorrect(index)}>
                                                    {index}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} sx={{ marginLeft: "301px", marginTop: "-88px" }}>

                                        <TextField
                                            id="no_routings_changed"
                                            size="small"
                                            select
                                            label="No of Routings Changed*"
                                            value={selectedRoutingChangedNumber}
                                            onChange={(e) => {
                                                setSelectedRoutingChangedNumber(e.target.value);
                                            }}

                                        >
                                            {Array.from({ length: 101 }, (_, index) => (
                                                <MenuItem id={'incorrect_routings-' + index} key={index} data-testid={`changed_routings-${index}`} value={index} onClick={() => handleRoutingChanged(index)}>
                                                    {index}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} sx={{ marginTop: "-13px" }}>
                                        <TextField
                                            size="small"
                                            id="closure_notes"
                                            label="Closure Notes"
                                            value={notes} onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            )}


                            <div style={{ marginTop: "8px", display: "flex", justifyContent: "center", gap: "20px" }}>
                                <Button id="planning-popup-dismiss" data-testid="planning-popup-dismiss" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} onClick={handleClosePopup}>Dismiss</Button>


                                <Button id="planning-popup-apply" data-testid="planning-popup-apply" style={{ color: "white", backgroundColor: applydisable ? "rgba(20, 32, 50, 0.5)" : "#142032", height: "30px", float: "right" }}
                                    onClick={handleApply} type="submit" disabled={applydisable}>Apply</Button>
                            </div>
                        </form>
                    </Box>
                </Box>

            </Dialog>

        </div >

    );
}