import React, { useContext, useEffect, useState } from "react";
import {
    TableSortLabel, Card, CardContent, Typography, MenuItem, FormControl, Select,
    Paper, InputLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Link, Grid, Stack, InputAdornment, Input, Snackbar, Alert, Box, Backdrop, CircularProgress
} from "@mui/material";
import "./HcrPlanning.css";
import { cable_checkbox_header, cable_header } from './HcrPlanningConst';
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ViewPopup from "./HcrPlanningPopup";
import { useMsal } from "@azure/msal-react";
import { AllContext } from "../common/BreadCrumbContext";
import { ADMIN, ENGINEER_MANAGER } from "../../constant/userConfig";
import {
    EventFromChild, HomeDetails, cardDetails, download, getComparator, getCsvReport,
    getExcelReport, handleChangeRowsPerPage, handleClose, handleSort, headcellfn,
    jumperSchedule, onCsvDownload, onExcelDownload, requestSearch, role, rollBack, selectedRowsColor, userdataRole
} from "./HcrPlanningCable.util";
import Tablepagination from "../common/Tablepagination";

export function HcrPlanningCable() {
    const [search, setSearch] = useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [sucessalertOpen, setsucessAlertOpen] = React.useState(false);
    const { userData } = useContext(AllContext)




    const { accounts } = useMsal()


    const params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    /** assigining searchparams */

    const [searchParams] = useSearchParams();
    const job_id = (searchParams.get('job_id'));
    const planner_name = (searchParams.get('planner_name'));
    const cable_id = (searchParams.get('cable_id'));
    const exchange_name = (searchParams.get('exchange_name'));
    const offloaded_pcps = (searchParams.get('offloaded_pcps'));

    /**Download Primary Report */



    const [csvResp, setCsvResp] = React.useState(null);
    const [showCsvLink, setCsvLink] = React.useState(false);
    const [excelResp, setExcelResp] = React.useState(null);
    const [showExcelLink, setExcelLink] = React.useState(false);


    /** Routing- Navgation */
    const navigate = useNavigate();

    /** Card variables */
    const [cardList, setCardList] = React.useState({});

    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');

    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);



    /** Table data */
    const [tabledata, setTabledata] = React.useState([]);
    const [tabledataCopy, setTabledataCopy] = React.useState([]);

    /** Pagination */
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
   

    const handleRowsPerPageChange = event => {
        handleChangeRowsPerPage(event, setRowsPerPage, setPage)
    };


    /** Table Sorting */
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('exch_sau');






    /** Checkbox */
    const [checkboxInfo, setCheckboxInfo] = useState(cable_checkbox_header);
    const handleCheckBox = (item) => {
        const isChecked = item.target?.checked;
        const value = item.target?.value;
        let filteredArr = checkboxInfo?.map((data) => {
            if (data?.id === value) {
                data.checked = isChecked;
            }
            return data;
        });
        setCheckboxInfo(filteredArr)
    }

    /** @type {*} */
    const [tableHeader, setTableHeader] = useState(cable_header);

    const setHeader = () => {

        const anyCheckboxChecked = checkboxInfo.some((checkId) => checkId.checked);

        const filteredHeader = anyCheckboxChecked
            ? cable_header.filter((headerId) => {

                return checkboxInfo.some((checkId) => {

                    return checkId.id === headerId.id && checkId.checked;

                });
            })
            : cable_header; // Return all headers if no checkbox is checked

        setTableHeader(filteredHeader);

    };

    useEffect(() => {

        if (tableHeader.length > 0) {
            setLoading(true);
            setOpen(true)
            getHomeDetails();

        }

    }, [tableHeader]);

    useEffect(() => {
        setHeader();
        getCardDetails();
    }, [])

    const getCardDetails = () => {
        cardDetails(job_id, params, setOpen, setLoading, setPage, setCardList, setAlertOpen, setSnackMsg)
    }



    /** for passing to child component*/

    const clickEventFromChild = (type, fileName, job_id) => {
        EventFromChild(type, fileName, job_id, getHomeDetails, getJumperSchedule)
    };


    /**
     *  get landing details
     *
     */
    const getHomeDetails = () => {
        HomeDetails(tableHeader, checkboxInfo, job_id, planner_name, cable_id, exchange_name, offloaded_pcps, accounts, setLoading, setOpen, setPage, setTabledata, setTabledataCopy, setAlertOpen, setSnackMsg)
    }




    const [jumperResp, setJumperResp] = React.useState(null);

    /**
         Jumper Report code for prod env
  */

    const getJumperSchedule = (file_name, jobId) => {

        jumperSchedule(file_name, jobId, accounts, setLoading, setOpen, setJumperResp, setAlertOpen, setSnackMsg)
    }

    const onDownload = (file_name) => {
        download(file_name, setLoading, setOpen, jumperResp, setAlertOpen, setSnackMsg)

    };

    const [selectedRows, setSelectedRows] = useState([]);




    const handleRollBack = () => {
        rollBack(setLoading, accounts, selectedRows, cable_id, job_id, exchange_name, setSelectedRows, getCardDetails, getHomeDetails, setsucessAlertOpen, setSnackMsg, setAlertOpen)
    }


    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => handleClose(setAlertOpen, setsucessAlertOpen)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={() => handleClose(setAlertOpen, setsucessAlertOpen)} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>

            <Snackbar open={sucessalertOpen} autoHideDuration={6000} onClose={() => handleClose(setAlertOpen, setsucessAlertOpen)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={() => handleClose(setAlertOpen, setsucessAlertOpen)} severity="success" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>

            <Grid container item lg={12} sx={{ ml: '-1.5%', width: '900px' }} spacing={2}>
                <Grid item lg={6} sm={6}>
                    <Stack spacing={1}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: '4%', gap: 4, width: 400 }}>
                            <Card sx={{ width: 195, height: 50, background: 'linear-gradient(to bottom, #142032, #2e3a4c)' }}>
                                <CardContent style={{ marginTop: '-6%' }}>
                                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                                            No. of PCPs
                                        </Typography>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white' }}>
                                            {cardList?.['total_no_pcps']}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card sx={{ width: 195, height: 50, background: 'linear-gradient(to bottom, #5488c7, #75a5e0)' }}>
                                <CardContent style={{ marginTop: '-6%' }}>
                                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                                            Total Jumpers
                                        </Typography>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white' }}>
                                            {cardList?.['no_jumpers']}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ display: 'flex', gap: 6 }}>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom, #3a537a,#3a537a)' }}> <span className="circleText"> Completed PCPs <br />
                                {cardList?.['no_of_complete_pcps']}
                            </span></div>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom, #43B072, #52be81)' }}> <span className="circleText"> Completed Jumpers <br />
                                {cardList?.['no_of_complete_jumpers']}</span></div>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom,#818387, #b8bbc0 )' }}> <span className="circleText">PCPs Percentage <br />
                                {cardList?.['pcps_percentage']}</span></div>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom, #d0d0d0,#d1d1d1)' }}> <span className="circleText"> Jumpers Percentage <br />
                                {cardList?.['jumpers_percentage']}</span></div>
                        </div>
                    </Stack>
                </Grid>
                <Grid item lg={6} sm={6}>
                    <Card sx={{ width: "600px", height: "190px", lineHeight: 1.05, background: "#d1d1d1", border: "1.5px solid whitesmoke", marginLeft: "19px", marginTop: '4%' }}>
                        <CardContent>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignContent: 'center' }}>
                                <Typography sx={{ width: "600px", marginLeft: "-16px", paddingLeft: "15px", height: "26px", paddingTop: "3px", fontSize: 16, fontWeight: 700, color: 'white', marginTop: "-19px", background: "#142032" }}>
                                    Select Column
                                </Typography>

                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "0 15px",
                                    marginTop: "5px"
                                }}>
                                    {checkboxInfo?.map((value) => {
                                        return (
                                            <div key={value.id} >
                                                <input
                                                    onChange={handleCheckBox}
                                                    value={value['id']}
                                                    data-testid={`checkbox-${value.id}`}
                                                    style={{ marginLeft: "-10px", marginTop: "0px" }}
                                                    checked={value.checked}
                                                    type="checkbox"
                                                    name={value['label']}
                                                />
                                                <label style={{ fontWeight: 700, fontSize: "11px", marginLeft: "-1px", marginTop: "4px" }} htmlFor="name">{value.label}</label>

                                            </div>

                                        );
                                    })}

                                </div>

                            </div>
                            <Button id="button_viewallselect" sx={{ padding: "2px 8px", color: "white", border: "whitesmoke", background: "#142032", marginLeft: "222px", marginTop: "30px", fontSize: "14px", textTransform: "none" }} variant="contained" onClick={() => setHeader()} >Select</Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <div>
                    <FormControl sx={{ m: 1, width: '25ch', marginTop: "41px" }} variant="standard">
                        <InputLabel >Search</InputLabel>
                        <Input
                            id="search"
                            type='text'
                            size="small"
                            data-testid="input-search"
                            onChange={(searchVal) => { requestSearch(searchVal, setSearch, tabledataCopy, setTabledata); setPage(0) }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>

                <div style={{ marginTop: "32px", marginLeft: "-1102px" }}>
                    <FormControl size="small" sx={{ marginTop: "20px", minWidth: "130px", marginLeft: "290px" }}>
                        <InputLabel id="demo-select-small-label" sx={{ fontSize: 12, fontWeight: 'bold' }} >Primary Report</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            label="PrimaryReport"
                            sx={{ fontSize: 12 }}
                        >

                            <MenuItem id='csv-planningcable' value='csv' style={{ fontSize: 12, textDecoration: 'none', color: 'black' }} onClick={() => getCsvReport((job_id, planner_name, cable_id, exchange_name, offloaded_pcps, search, accounts, setLoading, setOpen, setCsvLink, setCsvResp, setExcelLink, setAlertOpen, setSnackMsg))}>
                                CSV Report
                            </MenuItem>
                            <MenuItem id='excel-planningcable' sx={{ fontSize: 12 }} value='excel' onClick={() => getExcelReport(job_id, setExcelLink, setAlertOpen, setSnackMsg, planner_name, cable_id, exchange_name, offloaded_pcps, search, accounts, setLoading, setOpen, setExcelResp, setCsvLink)}> Excel Report</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    {showCsvLink &&
                        <div style={{ marginTop: '65px' }} >
                            <Link
                                id="download-csv-Report"
                                variant="body2"
                                underline='hover'
                                onClick={() => onCsvDownload(setOpen, csvResp, setLoading, setAlertOpen, setSnackMsg, setCsvLink)}
                                data-testid="download-link"
                                sx={{ fontSize: 14, color: 'red', marginLeft: "-533px", '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the CSV Report
                            </Link>
                        </div>

                    }
                    {showExcelLink &&
                        <div style={{ marginTop: '65px' }} >
                            <Link
                                id="download-csv-Report"
                                variant="body2"
                                underline='hover'
                                onClick={() => onExcelDownload(excelResp, setLoading, setOpen, setExcelLink, setAlertOpen, setSnackMsg)}
                                data-testid="download-link"
                                sx={{ fontSize: 14, color: 'red', marginLeft: "-533px", '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the Excel Report
                            </Link>
                        </div>

                    }
                    {(userData?.ROLE === ADMIN || userData?.ROLE === ENGINEER_MANAGER) ? <Button id="rollback" style={{ color: "white", backgroundColor: selectedRowsColor(selectedRows), width: "100px", height: "30px", marginLeft: "-103px", marginTop: " 56px", fontSize: 12 }} onClick={() => handleRollBack()} disabled={!selectedRows.length} >Rollback</Button> : null}

                </div>


            </div>
            <TableContainer sx={{ marginTop: "28px", overflowX: "scroll" }} component={Paper}>
                <Table stickyHeader sx={{ width: "100%" }} size="small">
                    <TableHead>

                        <TableRow
                            sx={{
                                "& th": {
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    backgroundColor: "#5488c7",
                                    color: "white",
                                    padding: "0px"
                                }
                            }}>
                            {(tableHeader)?.map((headcell) => (

                                <TableCell key={headcell?.id} sx={{ color: "white", minWidth: "80px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">




                                    <TableSortLabel
                                        active={orderBy === headcell?.id}
                                        direction={orderBy === headcell?.id ? order : 'asc'}
                                        data-testid={`sort-${headcell?.id}`}
                                        onClick={() => handleSort(headcell?.id, order, orderBy, setOrder, setOrderBy)}
                                    > {headcell.label}</TableSortLabel>

                                </TableCell>



                            ))}
                            <TableCell key="cable_view" sx={{ color: "white", minWidth: "80px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">
                                View
                            </TableCell>
                            {role(userData)}
                        </TableRow>
                    </TableHead>
                    <TableBody>


                        {
                            (tabledata?.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))?.map((row) => (

                                <TableRow
                                    key={row.fn}
                                >
                                    {(tableHeader)?.map((headcell) => (


                                        headcell?.id === "status" ? (<TableCell  >
                                            <ViewPopup getHomeDetails={getHomeDetails} getCardDetails={getCardDetails} planningPopup={row[headcell?.id]} onDownload={onDownload} row={row} jobId={job_id} cable_id={cable_id} sau={exchange_name} clickEventFromChild={clickEventFromChild} />

                                        </TableCell>
                                        ) :
                                            <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{headcellfn(headcell, row, onDownload, job_id, getJumperSchedule)}</TableCell>

                                    ))}

                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="right">
                                        <Link
                                            component="button"
                                            id={"view-pcp-" + row.pcp}
                                            variant="body2"
                                            onClick={() => {
                                                navigate({
                                                    pathname: '/hcrpcp',
                                                    search: `?job_id=${job_id}&pcp=${row.pcp}&planner_name=${row.planner_name}&post_code=${row.post_code}&fn=${row.fn}`
                                                });
                                            }}
                                            data-testid="view-pcp"
                                        >
                                            View PCP
                                        </Link>
                                    </TableCell>

                                    {userdataRole(row, userData, selectedRows, setSelectedRows)}

                                </TableRow>
                            ))



                        }


                    </TableBody>
                </Table>
               
                  <Tablepagination
                        setPage={setPage}
                        page={page}
                        count={tabledata?.length}
                        handleChangeRowsPerPage={handleRowsPerPageChange}
                        rowsPerPage={rowsPerPage}
                    />
            </TableContainer >

        </>
    )
}

export default HcrPlanningCable

