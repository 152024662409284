export const assigntoCheckboxList = [
    { id: "cable_id", name: "Cable ID", checked: true },
    { id: "updated_by", name: "Updated By", checked: true },
    { id: "issued_jumpers", name: "No. of Jumpers With Issues", checked: true },
    { id: "ouc", name: "OUC", checked: false },
    { id: "updated", name: "Updated", checked: false },
    { id: "no_incorrect_routings", name: "No.Incorrect Routings", checked: false },
    { id: "cst_name", name: "CST Name", checked: false },
    { id: "no_pcps", name: "No.PCPs", checked: true },
    { id: "no_routings_changed", name: "No.Routings Changed", checked: false },
    { id: "exchange_name", name: "Exchange Name", checked: true },
    { id: "no_jumpers", name: "No. Jumpers", checked: true },
    { id: "cable_status", name: "Cable Status", checked: true },
    { id: "created_by", name: "Created By", checked: true },
    { id: "completed_pcps", name: "No. Complete PCPs", checked: true },
    { id: "per_completed", name: "% Complete", checked: false },
    { id: "created", name: "Created", checked: true },
    { id: "completed_jumpers", name: "No. Complete Jumpers", checked: true },
    { id: "completed_date", name: "Complete Date", checked: false }
];


export const table_header = [
    {
        id: "cable_id", label: "Cable ID",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "ouc", label: "OUC",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "cst_name", label: "CST Name",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "exchange_name", label: "Exchange Name",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "created_by", label: "Created By",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "created", label: "Created",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "updated_by", label: "Updated By",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "updated", label: "Updated",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "no_pcps", label: "No.PCPs",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "no_jumpers", label: "No. Jumpers",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "issued_jumpers", label: "No. of Jumpers With Issues",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    
   
    {
        id: "no_incorrect_routings", label: "No.Incorrect Routings",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
   
   
    {
        id: "no_routings_changed", label: "No.Routings Changed",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
   
    
    {
        id: "cable_status", label: "Cable Status",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "per_completed", label: "% Complete",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "completed_date", label: "Complete Date",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
    {
        id: "completed_pcps", label: "No. Complete PCPs",
        numeric: false,
        disablePadding: true,
        width: '10%'
    },
   
    {
        id: "completed_jumpers", label: "No. Complete Jumpers",
        numeric: false,
        disablePadding: true,
        width: '10%'
    }

];



export const cable_header = [
    {
        id: 'pcp',
        numeric: false,
        disablePadding: true,
        label: 'PCP',
        width: '10%'
    },
    {
        id: 'no_jumpers',
        numeric: false,
        disablePadding: true,
        label: 'No. Jumpers',
    },
    {
        id: 'post_code',
        numeric: false,
        disablePadding: true,
        label: 'Post Code',
    },
    {
        id: 'fn',
        numeric: false,
        disablePadding: true,
        label: "Jumper Schedule",
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: true,
        label: "Created ",
    },
    {
        id: 'planner_name',
        numeric: false,
        disablePadding: true,
        label: 'Planner Name',
        width: '10%'
    },
    {
        id: 'assigned_name',
        numeric: false,
        disablePadding: true,
        label: 'Assigned Name',

    },
    {
        id: 'assigned_date',
        numeric: false,
        disablePadding: true,
        label: 'Assigned Date',
    },
    {
        id: 'completed_name',
        numeric: false,
        disablePadding: true,
        label: "Completed Name",
    },
    {
        id: 'completed_date',
        numeric: false,
        disablePadding: true,
        label: "Completed Date",
    },
  
    {
        id: 'file_size',
        numeric: false,
        disablePadding: true,
        label: 'File Size',
        width: '10%'
    },
    
    
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
    },
   
    
    {
        id: 'no_incorrect_routings',
        numeric: false,
        disablePadding: true,
        label: 'No.Incorrect Routings',
    },

   
    {
        id: 'no_routings_changed',
        numeric: false,
        disablePadding: true,
        label: "No.Routings Changed",
    }
   
   
];

export const cable_checkbox_header = [
    {
        id: 'pcp',
        label: 'PCP',
        checked: true
    },
   
    {
        id: 'planner_name',
        label: 'Planner Name',
        checked: true
    },
    {
        id: 'file_size',
        label: 'File Size',
        checked: false
    },
    {
        id: 'no_jumpers',
        label: 'No. Jumpers',
        checked: true
    },

    {
        id: 'assigned_name',
        label: 'Assigned Name',

        checked: true
    },
    {
        id: 'status',
        label: 'Status',
        checked: true
    },
    {
        id: 'post_code',
        label: 'Post Code',
        checked: true
    },
    {
        id: 'assigned_date',
        label: 'Assigned Date',
        checked: false
    },
    {
        id: 'no_incorrect_routings',
        label: 'No.Incorrect Routings',
        checked: false
    },
    {
        id: 'fn',
        label: "Jumper Schedule",
        checked: true
    },
    {
        id: 'completed_name',
        label: "Completed Name",
        checked: false
    },
    {
        id: 'no_routings_changed',
        label: "No.Routings Changed",
        checked: false
    },
    {
        id: 'created_at',
        label: "Created ",
        checked: false
    },
    {
        id: 'completed_date',
        label: "Completed Date",
        checked: false
    }
];

export const view_pcp = [
    { id: "cable_id", label: "Cable Id" },
    { id: "pcp", label: "PCP" },
    { id: "no_jumpers", label: "No.Jumpers" },
    { id: "no_incorrect_routings", label: "No.Incorrect Routings" },
    { id: "no_routings_changed", label: "No.Routing Changed" },
    { id: "post_code", label: "Post Code" },
    { id: "wtw", label: "wtw" },
    { id: "document", label: "Document" },
    { id: "length", label: "Length" },
    { id: "comments", label: "Comments" },
    { id: "planner_name", label: "Planner Name" },
    { id: "created", label: "Created" },
    { id: "assigned_name", label: "Assigned Name" },
    { id: "assigned_date", label: "Assigned Date" },
    { id: "completed_name", label: "Completed Name" },
    { id: "completed_date", label: "Completed Date" },
    { id: "notification_completed", label: "Notification Complete" },
    { id: "engineer_start", label: "Engineer Start" },
    { id: "engineer_end", label: "Engineer End" },
    { id: "objective", label: "Objective" },
    { id: "job_description", label: "Job Description" },
]

