export const BASE_URL = process.env.REACT_APP_BACKEND;
export const CARD_URL = "/card";
export const DISTRICTS = "/districts"
export const HCR = "/hcr";
export const HCR_REPORT = "/report"
export const RD = "/rd"
export const HCRPATH = ['hcr', 'hcrworkstack', 'hcrreporting', 'hcrplanning','planningcable','hcrpcp','hcrassignunassign']
export const HIGH_LEVEL_EXPORT = 'high_level_export';
export const ACR = 'acr'
export const CABLE_VIEW = "cable_view";
export const CABINET_VIEW = "cabinet_view";
export const PERMUTAION_VIEW = "permutations_view"
export const HAZARD_VIEW = "hazards_view"
export const EXCHANGE_VIEW = "exchange_view"
export const HOME = "/home";
export const USER_DETAILS = "user_details";
export const UPDATE_JUMPER = "update-jumper"
export const ADD_CIRCUIT = "add-circuit"
export const EDIT_CIRCUIT = "edit-circuit"
export const GET_JUMPER = "get-jumper"
export const SAVE_JUMPER = "save-jumper"
export const GENERATE_JUMPER = "generate-jumper"
export const VIEW_JOB = "view_job";
export const GET_REMARKS ="get_remarks"
export const ADD_REMARKS = "add_remarks";
export const STAGES = "stages"
export const NEXT_STAGE = "move_to_next_stage";
export const UNVIABLE = "marked_as_unviable";
export const HOLD = "marked_as_onhold";
export const CHANGE_OWNERSHIP = "change_ownership";
export const JOB_LIST = "job_list";
export const VIEW_JOB_DETAILS = "view_job_details";
export const ROLL_BACK = "roll_back";
export const ADMIN_REPORT = "admin_report"
export const ALL_PCP = "all_pcp";
export const WORKSTACK_HOME = "workstack_home";
export const ASSIGNED_PCPS = "assigned_pcp";
export const PAGE = "page";
export const CHANGE_STATUS = "change_status";
export const DOWNLOAD_JS_REPORT="download_js_report"
export const SUB_JS_ID="sub_js_id"
export const UPDATE_POPUP="update_popup"
export const UPDATE_PLANNING_POPUP="update_planning_popup"
export const UPDATE_WORKSTACK_POPUP="update_workstack_popup"
export const ASSIGN_UNASSIGN = "assign-unassign";
export const ASSIGN_PCP ="assign-pcp";
export const UNASSIGN_PCP ="unassign-pcp";
export const DOWNLOAD_PCP="download-pcps";
export const DOWNLOAD_JUMPER="download-jumper";
export const EXCHANGE_JOB_OWNERS="exchange_job_owners";
export const SECOND_ENGINEER="second_engineer";
export const PAGE_CARD="page_card";
export const DETAILS="details";
export const HOME_HCR = "home_hcr";
export const UPDATE_HOME="update_home";
export const HOST_CABLES="hostcables"
export const POST_API_DATA_PROCESSING="post_api_data_processing";
export const WFMT_POST_REQUEST="wfmt_post_request";
export const GET_WFMT_PROJECT_DETAILS="get_wfmt_project_details"
export const WFMT = "wfmt"
export const GENERATE_SCHEMATICS="generate-schematics";