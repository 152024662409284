export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const requestSearch = (event, setSearch, tabledataCopy, setTabledata) => {
  let searchedVal = event?.target?.value;
  setSearch(searchedVal);
  const filteredRows = tabledataCopy.filter((row) => {
    return (row.cable_id.toLowerCase().includes(searchedVal.toLowerCase())
      || row.exchange_name.toLowerCase().includes(searchedVal.toLowerCase())
      || row.created_by.toLowerCase().includes(searchedVal.toLowerCase()));
  });
  setTabledata(filteredRows);
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { descendingComparator, requestSearch, getComparator }