import { Box, Button, Modal, Paper, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { handleEditData } from './JumperEdit.util';


export default function JumperEdit({ isOpen, onBack, dropdownEditCircuit, dropdownEdittoMdf, dropdownEditfromMdf, closeModal, data, pcpName, onEdit, onNext,setTableData,selectedIndex,tabNames,tableData,selectedrow,dataforSelectedtab }) {

    const [editedData, setEditedData] = useState(data);
    const [errors, setError] = useState(
        {
            "Circuit": "",
            "From_MDF_Bar": "",
            "From_MDF_No": "",
            "From_MDF_Pair": '',
        });

    useEffect(() => {
        setEditedData(data)
    }, [data])

    const [tomdfeditbar, setTomdfeditbar] = useState();
    const [tomdfeditpair, setTomdfeditpair] = useState([]);
    const [eside, setEside] = useState('');
    const [status, setstatus] = useState('');
    const [hostcable, setHostcable] = useState('');

    const handleChange = (event) => {
        if (event.target.value === "T" || event.target.value === "SOGEA_D2") {
            const data = { ...editedData,"Status": event.target.value, "To_Status": null,"To_MDF_Pair":null, "New_E_Side": null, "To_MDF_No": null, "To_MDF_Bar": null };
            setEditedData(data);
        }

        let { name, value } = event.target;
        if (name === "To_MDF_Bar") {
            setTomdfeditpair([]);
            setEside('');
            setstatus('');
            setHostcable('')
        }
        if (!editedData?.To_MDF_Pair) {
            setEside('');
            setstatus('');
            setHostcable('')
        }
        if (name === "D_side" || name === "Recovery_e_side") {
            const intvalues = name === "D_side" || name === "Recovery_e_side" ? parseInt(value, 10) : value;
            const values = isNaN(intvalues) ? "" : parseInt(value, 10)
            setEditedData({
                ...editedData, [name]: values
            })
        }
        else {
            const intvalues = name === 'From_MDF_No' || name === "From_MDF_Pair" || name === "D_side" || name === "Recovery_e_side" ? parseInt(value, 10) : value;
            setEditedData({
                ...editedData, [name]: intvalues
            })
        }
    }

    // dropdown values for from_mdf_no
    const formmdfno = React.useMemo(() => {
        if (editedData !== undefined) {
            return dropdownEditfromMdf?.from_mdf_no
        }
        // eslint-disable-next-line
    }, [editedData.From_MDF_No])

    // dropdown values for from_mdf_bar
    const formmdfbar = React.useMemo(() => {
        if (formmdfno !== undefined) {
            return dropdownEditfromMdf?.from_mdf_bar[formmdfno]
        }
        // eslint-disable-next-line
    }, [editedData.From_MDF_Bar])

    // dropdown values for from_mdf_pair
    const formmdfpair = React.useMemo(() => {
        if (formmdfbar !== undefined) {
            return dropdownEditfromMdf?.from_mdf_pair[formmdfno][formmdfbar]
        }

        // eslint-disable-next-line
    }, [editedData.From_MDF_Pair])

    // dropdown values for to_mdf_no
    const tomdfeditno = React.useMemo(() => {
        if (dropdownEdittoMdf !== undefined) {
            return dropdownEdittoMdf?.to_mdf_no
        }
        // eslint-disable-next-line
    }, [editedData.To_MDF_No,editedData])

    useEffect(() => {
        if (dropdownEdittoMdf !== undefined && dropdownEdittoMdf?.to_mdf_bar !== undefined && tomdfeditno !== undefined && editedData?.To_MDF_No !== undefined && editedData?.To_MDF_No !== null) {
            setTomdfeditbar(dropdownEdittoMdf?.to_mdf_bar[editedData.To_MDF_No]);
            if (data.To_MDF_Bar !== editedData.To_MDF_Bar) {
                const data = { ...editedData, "To_MDF_Pair": "" }
                setEditedData(data)
            }
        }
        if (editedData?.To_MDF_Pair === "") {
            setEside('');
            setstatus('');
            setHostcable('')
        }
        // eslint-disable-next-line
    }, [editedData.To_MDF_No, editedData.To_MDF_Bar, tomdfeditno])

    // dropdown values for to_mdf_pair
    useEffect(() => {
        if (dropdownEdittoMdf !== undefined && editedData?.To_MDF_Bar) {
            setTomdfeditpair(Object.keys(dropdownEdittoMdf?.to_mdf_pair[editedData?.To_MDF_No][editedData?.To_MDF_Bar]))
        }
        // eslint-disable-next-line
    }, [editedData.To_MDF_Bar])

    // values for eside, status and hostcable
    useEffect(() => {
        if (dropdownEdittoMdf !== undefined  && editedData?.To_MDF_Pair && dropdownEdittoMdf?.to_mdf_pair[editedData?.To_MDF_No][editedData?.To_MDF_Bar] !== undefined && dropdownEdittoMdf?.to_mdf_pair !== undefined) {
            const data = dropdownEdittoMdf?.to_mdf_pair[editedData?.To_MDF_No][editedData?.To_MDF_Bar][editedData?.To_MDF_Pair]
            if (data !== undefined) {
                setEside(data.eside);
                setstatus(data.status);
                setHostcable(data.host_cable)
            }
        }
        // eslint-disable-next-line
    }, [editedData?.To_MDF_Pair, editedData?.To_MDF_Bar]);
    
    //to handle edit
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!editedData?.Circuit || !editedData?.From_MDF_No || !editedData?.From_MDF_Bar || !editedData?.From_MDF_Pair) {
            setError({
                Circuit: editedData?.Circuit ? "" : "Circuit id cannot be empty",
                From_MDF_No: editedData?.From_MDF_No ? "" : "From_MDF_No cannot be empty",
                From_MDF_Bar: editedData?.From_MDF_Bar ? "" : "From_MDF_Bar id cannot be empty",
                From_MDF_Pair: editedData?.From_MDF_Pair ? "" : "To_MDF_Pair cannot be empty"
            })
        } else {
            if(editedData?.Status ==="T" || editedData?.Status ==="SOGEA_D2"){
                const data = { ...editedData, "To_Status": null, "New_E_Side": null, "To_MDF_No": null, "To_MDF_Bar": null,"To_MDF_Pair":null };
                handleEditData(data, dataforSelectedtab, selectedrow, tableData, selectedIndex, tabNames, setTableData)
            }
           else{
            const data = { ...editedData, "To_Status": status, "New_E_Side": eside, "Host_Cable": hostcable };
            handleEditData(data, dataforSelectedtab, selectedrow, tableData, selectedIndex, tabNames, setTableData)}
        }
    }

    const handleNext = () => {
        onNext();
    }

    const handleBack = () => {
        onBack();
    }

    const isdisabled = editedData?.Status === "T" || editedData?.Status === "SOGEA_D2";
    return (
        <div>

            <Modal
                id={"edit-" + pcpName}
                data-testid={"edit-modal-" + pcpName}
                open={isOpen}
                sx={{ marginTop: "30px", overflowY: "scroll" }}
                aria-labelledby="title"
                aria-describedby="description">
                <Box  noValidate autoComplete="off" sx={{ flexGrow: 1, maxWidth: "800px", height: "auto",'& .MuiTextField-root': { m: 1, width: '25ch' }, display: "flex", padding: "20px", alignItems: "center", flexDirection: 'column', margin: 'auto' }} component={Paper}>                   
                        <form onSubmit={handleSubmit}>
                            <div>
                                <TextField
                                    size="small"
                                    id={pcpName + "-Circuit"}
                                    label="Edit Circuit"
                                    name="Circuit"
                                    autoComplete="current-Circuit"
                                    value={editedData.Circuit || ""}
                                    onChange={handleChange}
                                    error={errors.Circuit}
                                    helperText={errors.Circuit}
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-Status"}
                                    select
                                    label="Edit Status"
                                    name="Status"
                                    value={editedData.Status || ""}
                                    onChange={handleChange}
                                >
                                    {dropdownEditCircuit?.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    size="small"
                                    id={pcpName + "-Tie"}
                                    label="Edit Tie"
                                    name="Tie"
                                    autoComplete="current-Tie"
                                    value={editedData.Tie || ""}
                                    onChange={handleChange}
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-ADSL_TAM_LLU"}
                                    label="Edit ADSL/TAM/LLU"
                                    name="ADSL_TAM_LLU"
                                    value={editedData.ADSL_TAM_LLU || ""}
                                    autoComplete="current-ADSL_TAM_LLU"
                                    onChange={handleChange}
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-LLU"}
                                    label="Edit LLU"
                                    name="LLU"
                                    autoComplete="current-LLU"
                                    value={editedData.LLU || ""}
                                    onChange={handleChange}
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-Tie1"}
                                    label="Edit Tie1"
                                    name="Tie1"
                                    autoComplete="current-Tie1"
                                    value={editedData.Tie1 || ""}
                                    onChange={handleChange}
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-EET"}
                                    label="Edit EET"
                                    name="EET"
                                    autoComplete="current-EET"
                                    value={editedData.EET || ""}
                                    onChange={handleChange}
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-Unit"}
                                    label="Edit Unit"
                                    name="Unit"
                                    autoComplete="current-Unit"
                                    value={editedData.Unit || ""}
                                    onChange={handleChange}
                                />

                                <TextField
                                    select
                                    size="small"
                                    id={pcpName + "-From_MDF_No"}
                                    label="Edit From MDF No"
                                    name="From_MDF_No"
                                    data-testid="Edit-From-MDF-No"
                                    value={editedData.From_MDF_No || ""}
                                    autoComplete="current-From_MDF_No"
                                    onChange={handleChange}
                                    error={errors.From_MDF_No}
                                    helperText={errors.From_MDF_No}>
                                    {dropdownEditfromMdf.from_mdf_no?.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    select
                                    size="small"
                                    id={pcpName + "-From_MDF_Bar"}
                                    label="Edit From MDF Bar"
                                    name="From_MDF_Bar"
                                    value={editedData.From_MDF_Bar || ""}
                                    autoComplete="current-add_from_mdf_bar"
                                    onChange={handleChange}
                                    error={errors.From_MDF_Bar}
                                    helperText={errors.From_MDF_Bar}
                                >

                                    {formmdfbar?.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    select
                                    size="small"
                                    id={pcpName + "-From_MDF_Pair"}
                                    label="Edit From MDF Pair"
                                    name="From_MDF_Pair"
                                    value={editedData.From_MDF_Pair || ""}
                                    autoComplete="current-add_from_mdf_Pair"
                                    onChange={handleChange}
                                    error={errors.From_MDF_Pair}
                                    helperText={errors.From_MDF_Pair}
                                >

                                    {formmdfpair?.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    size="small"
                                    id={pcpName + "-Amendments"}
                                    label="Edit Amendments"
                                    name="Amendments"
                                    autoComplete="current-Amendments"
                                    value={editedData.Amendments || ""}
                                    onChange={handleChange}

                                />
                                <TextField
                                    size="small"
                                    id="Pcp"
                                    name="Pcp"
                                    value={editedData.Pcp}
                                    autoComplete="current-Pcp"
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-Recovery_e_side"}
                                    label="Edit Recovery E Side"
                                    name="Recovery_e_side"
                                    autoComplete="current-Recovery_e_side"
                                    value={editedData.Recovery_e_side || ""}
                                    onChange={handleChange}
                                />

                                <TextField
                                    size="small"
                                    id={pcpName + "-D_side"}
                                    label="Edit D Side"
                                    name="D_side"
                                    autoComplete="current-D_side"
                                    value={editedData.D_side === "____" ? "" : editedData.D_side || ''}
                                    onChange={handleChange}
                                />

                                <TextField
                                    id={pcpName + "-To_MDF_No"}
                                    size="small"
                                    select
                                    label="Edit To MDF No"
                                    name="To_MDF_No"
                                    value={editedData.To_MDF_No || ""}
                                    onChange={handleChange}
                                    disabled={isdisabled}
                                >
                                    {tomdfeditno?.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id={pcpName + "-To_MDF_Bar"}
                                    size="small"
                                    select
                                    label="Edit To MDF Bar"
                                    name="To_MDF_Bar"
                                    value={editedData.To_MDF_Bar || ""}
                                    onChange={handleChange}
                                    disabled={isdisabled}
                                >
                                    {tomdfeditbar?.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id={pcpName + "-To_MDF_Pair"}
                                    size="small"
                                    select
                                    label="Edit New Bar Pair"
                                    name="To_MDF_Pair"
                                    value={editedData.To_MDF_Pair || ""}
                                    onChange={handleChange}
                                    disabled={isdisabled}
                                >
                                    {tomdfeditpair?.map((option) => (
                                        <MenuItem key={option} value={parseInt(option)}>{option} </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    size="small"
                                    id={pcpName + "-New_E_Side"}
                                    name="New_E_Side"
                                    value={editedData.To_MDF_Pair === "____" ? "" : eside || ''}
                                    inputProps={{ readOnly: true }}
                                    autoComplete="current-Pcp"
                                    onChange={handleChange}
                                    label="Edit New E Side"
                                    disabled={isdisabled}
                                />

                                <TextField
                                    id={pcpName + "-To_Status"}
                                    size="small"
                                    name="To_Status"
                                    value={editedData.To_MDF_Pair === "____" ? "" : status || ''}
                                    inputProps={{ readOnly: true }}
                                    autoComplete="current-Pcp"
                                    onChange={handleChange}
                                    label="Edit To Status"
                                    disabled={isdisabled}
                                />
                                <TextField
                                    id={pcpName + "-Host_Cable"}
                                    size="small"
                                    label="Host cable"
                                    value={hostcable || ""}
                                    name="Host_Cable"
                                    onChange={handleChange}
                                    disabled={isdisabled}
                                />
                            </div>
                            <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", gap: "20px" }}>
                                <Button id={pcpName + "-button-back"}  data-testid="button-back" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} onClick={handleBack}  >Back</Button>
                                <Button id={pcpName + "-button-next"} data-testid="button-next" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} onClick={handleNext} >Next</Button>
                                <Button id={pcpName + "-button-dismiss"} data-testid="button-dismiss" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} onClick={closeModal}>Dismiss</Button>
                                <Button id={pcpName + "-button-apply"} data-testid="button-apply" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} type='submit'>Apply</Button>
                            </div>
                        </form>
                     
                </Box>
            </Modal>
        </div>
    );
}




