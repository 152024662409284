import React, { useEffect, useState } from "react";
import {
    Card, Link, CardContent, Typography, Box, Grid, TableSortLabel, Dialog, DialogActions, DialogContent,
    Paper, Table, InputAdornment, Input, TableBody, TableCell, TableContainer, TableHead, TableRow, Snackbar, Alert, CircularProgress, Backdrop
} from "@mui/material";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './HcrWorkstack.css';
import axios from 'axios';
import { BASE_URL, HCR, ALL_PCP, WORKSTACK_HOME, ASSIGNED_PCPS, DOWNLOAD_JS_REPORT } from "../../constant/urls";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { workstackAssignCheckboxList, newCheckboxes, workstackHeader, selectHeader, newHeadcell } from './HcrWorkstackConst';
import ViewPopup from "./HcrWorkstackPopup";
import { getComparator, handleAssignCheckbox, handleCheckboxChange, handleColor, handleReportExcel, handleSort, handleUnassign, headcellfn, headcelljs_id, onCsvAssignDownload, onCsvDownload, onExcelAssignDownload, onExcelDownload, requestSearch, rowStatus } from "./HcrWorkstack.util";
import Tablepagination from "../common/Tablepagination";


export default function HcrWorkstack() {
    const FIRST_NAME = JSON.parse(localStorage.getItem("FIRST_NAME"))
    const LAST_NAME = JSON.parse(localStorage.getItem("LAST_NAME"))


    const params = {
        logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
    }
    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    /** Dialogbox Implementation */
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    /**  jsid implementation */
    const [responseData, setResponseData] = useState(null);

    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsOpen, setSnackbarsOpen] = useState(false);

    const handleClose = () => {
        setAlertOpen(false);
    };

    /**  Table data */

    const [tabledata, setTabledata] = React.useState([]);
    const [tabledataTemp, setTabledataTemp] = React.useState([]);
    const [search, setSearch] = useState('');


    /** checkbox  */

    const [checkboxInfo, setCheckboxInfo] = useState(workstackAssignCheckboxList);
    const resetCheckboxInfo = checkboxInfo.map(item => ({ ...item, checked: false }));

    const viewAllPcpCheckbox = [...resetCheckboxInfo, ...newCheckboxes];

    const ViewPcpFlag = false;

    /** Table header */

    const [tableHeader, setTableHeader] = React.useState([...workstackHeader, ...selectHeader]);
    const [headerTable, setHeaderTable] = React.useState([...workstackHeader, ...selectHeader]);
    const workstackHomeHeader = [...workstackHeader, ...selectHeader]
    const [newHeaderAdded, setNewHeaderAdded] = useState(false);
    const viewAllPcpHeader = [...workstackHeader, ...newHeadcell];






   


    /**  checkbox-header select button*/
    const handleSelectButton = () => {
        const selectedCheckboxIDs = checkboxInfo.filter((item) => item.checked)?.map((item) => item.id);

        if (selectedCheckboxIDs.length === 0) {
            if (currentState === "home") {
                setTableHeader([...workstackHomeHeader]);
                setHeaderTable([...workstackHomeHeader])
            }
            else if (currentState === "viewAssigned") {
                setTableHeader([workstackHomeHeader]);
                setHeaderTable([workstackHomeHeader]);
            }
            else {
                setTableHeader([...viewAllPcpHeader]);
                setHeaderTable([...viewAllPcpHeader]);

            }

        } else {

            const tempHeader = currentState === "home" ? workstackHomeHeader : viewAllPcpHeader
            const checkedHeaders = tempHeader.filter((headcell) =>
                selectedCheckboxIDs.includes(headcell.id)
            );


            if ((currentState === "home") || (currentState === "viewAssigned")) {
                setTableHeader([...checkedHeaders, ...selectHeader]);
                setHeaderTable([...checkedHeaders, ...selectHeader]);
            }

            else if (currentState === "allPCP") {

                setTableHeader(checkedHeaders);
                setHeaderTable(checkedHeaders);

            }
        }

    };


    /** view all checkbox */

    const viewAllPcpButtonClick = () => {
        setSearch('');
        setCheckboxInfo(viewAllPcpCheckbox);
        setTableHeader(viewAllPcpHeader);
        setHeaderTable(viewAllPcpHeader)
        setNewHeaderAdded(!newHeaderAdded);
        setCurrentState('allPCP');
        getWorkstackHomeData(!ViewPcpFlag);
        setExcelLink(false);
        setCsvLink(false);
    };


    /** Pagination */
    const [page, setPage] = React.useState(parseInt(localStorage.getItem("tablepage")) || 0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    /** Table Sorting */
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('exch_sau');
  





    /** current page */

    const [currentState, setCurrentState] = useState('home');

    /** view assigned pcp button click*/

    const handleViewAssignedCheckbox = () => {
        setTableHeader(workstackHomeHeader);
        setHeaderTable(workstackHomeHeader);
        setSelectedCheckboxes([]);
        setSearch('');
        setCheckboxInfo(workstackAssignCheckboxList);
        setCurrentState('viewAssigned');
        getAssignedPCPs();
        setExcelLink(false);
        setCsvLink(false);
    };
    /** back button from view assigned pcp button */

    const handleAssignBack = () => {
        setSearch('');
        setSelectedCheckboxes([]);
        setCheckboxInfo(workstackAssignCheckboxList);
        setTableHeader(workstackHomeHeader);
        setHeaderTable(workstackHomeHeader)
        setCurrentState('home');
        getWorkstackHomeData(ViewPcpFlag);
        setExcelLink(false);
        setCsvLink(false);
    }

    /** back button from view all pcp button */

    const handleBack = () => {
        setSearch('');
        setCheckboxInfo(workstackAssignCheckboxList);
        setTableHeader(workstackHomeHeader);
        setHeaderTable(workstackHomeHeader);
        setNewHeaderAdded(false);
        setCurrentState('home')
        getWorkstackHomeData(ViewPcpFlag);
        setExcelLink(false);
        setCsvLink(false);
    }



    // State to track selected checkboxes in table
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);



    /** to disable buttons unless anyone checkbox is selected */

    const isViewButtonDisabled = selectedCheckboxes.length === 0;
    const isSelected = (row) => selectedCheckboxes.indexOf(row) !== -1;


    /** primary report handle click */

    const handleReportCsv = () => {
        if (currentState === "allPCP") {
            getCsvReport(!ViewPcpFlag);
        }
        else if (currentState === "viewAssigned") {
            getCsvAssignReport();
        }
        else {
            getCsvReport(ViewPcpFlag);
        }
    }



    /** get api for view assigned pcp*/

    const getAssignedPCPs = () => {
        const url = `${BASE_URL}${HCR}/${ASSIGNED_PCPS}`;
        let params = {
            assignee_name: FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`,
            search_key: '',
            filter_column: '',
            is_primary_report: `False,''`,
            logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
        }
        setLoading(true);
        setOpen(true);
        axios.get(url, { params: params })
            .then((res) => {
                if (res.data.status === "Fail" && res.data.status_code === 200) {
                    setAlertOpen(true);
                    setSnackMsg('Something went wrong!.Please try again!');
                }
                else if (res.data) {
                    setTabledata(res.data.results);
                    setTabledataTemp(res.data.results);
                    const data = res.data.results;
                    const sorted = [...data].sort((a, b) => new Date(b.assigned_date) - new Date(a.assigned_date));
                    setTabledata(sorted);
                    setTabledataTemp(sorted)
                    setPage(0);
                    setOpen(false);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                setOpen(false);
                setAlertOpen(true);
                console.error(error)
                setSnackMsg('Something went wrong!.Please try again!');
            })
    }




    /** get api for workstack home and view all pcp details*/

    const getWorkstackHomeData = (isAllPcp) => {
        const filterColumn = ''
        const primarykey = ['False', 'xlsx'].join(",");
        const assignName = FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`;
        const searchKey = ''

        let homeurl = `${BASE_URL}${HCR}/${WORKSTACK_HOME}?assignee_name=${assignName}&search_key=${searchKey}&filter_column=${filterColumn}&is_primary_report=${primarykey}`;
        let allurl = `${BASE_URL}${HCR}/${ALL_PCP}?search_key=${searchKey}&filter_column=${filterColumn}&is_primary_report=${primarykey}`
        const url = isAllPcp ? allurl : homeurl

        setLoading(true);
        setOpen(true);

        axios.get(url, { params: params })

            .then((res) => {
                if (res.data.status === "Fail" && res.data.status_code === 200) {
                    setAlertOpen(true);
                    setSnackMsg('Something went wrong!.Please try again!');
                }
                else if (res.data) {
                    const data = res.data.results;
                    const sorted = [...data].sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
                    setTabledata(sorted);
                    setTabledataTemp(sorted)
                    setPage(0);
                    setOpen(false);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.error(error)
                setLoading(false);
                setOpen(false);
                setAlertOpen(true);
                setSnackMsg('Something went wrong!.Please try again!');
            })
    }


    useEffect(() => {
        getWorkstackHomeData(ViewPcpFlag);
        // eslint-disable-next-line
    }, [])






    /** get api for jumper schedule */

    const [jumperResp, setJumperResp] = React.useState(null);

    /**
           Jumper Report code for prod env
    */

    const getJumperSchedule = (file_name, jobId) => {
        setLoading(true);
        const jumperurl = `${BASE_URL}${HCR}/${DOWNLOAD_JS_REPORT}`;
        const params = {
            file_name: file_name,
            job_id: jobId
        }
        axios.patch(jumperurl, params,)
            .then((response) => {
                let url = response.data['presigned_url'];
                setJumperResp(url);
                setLoading(false);
            }).catch((error) => {
                setAlertOpen(true);
                console.error(error);
                setLoading(false);
                setSnackMsg('Something went wrong!.Please try again!');

            });
    }

    const onDownload = (file_name) => {
        setLoading(true);
        axios.get(jumperResp, { responseType: 'blob' }).then((response) => {
            const url = URL.createObjectURL(response.data) //<---- this should be data.data
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file_name);
            link.click();
            setLoading(false);
        }).catch((error) => {
            setAlertOpen(true);
            setLoading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
        });

    };


    const [excelResp, setExcelResp] = React.useState(null);
    const [excelLink, setExcelLink] = React.useState(false);

    const [csvResp, setCsvResp] = React.useState(null);
    const [csvLink, setCsvLink] = React.useState(false);
    const [fileName, setFileName] = useState("")
    /**
              Primary Report code for prod env
       */


    const getCsvReport = (isAllPcp) => {

        const filterColumn = ''
        const primarykey = ['True', 'csv'].join(",");
        const assignName = FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`;
        const searchKey = search.toUpperCase();
        setLoading(true);
        setOpen(true)
        let homeurl = `${BASE_URL}${HCR}/${WORKSTACK_HOME}?assignee_name=${assignName}&search_key=${searchKey}&filter_column=${filterColumn}&is_primary_report=${primarykey}`;
        let allurl = `${BASE_URL}${HCR}/${ALL_PCP}?search_key=${searchKey}&filter_column=${filterColumn}&is_primary_report=${primarykey}`


        const reporturl = isAllPcp ? allurl : homeurl
        const filename = isAllPcp ? "all_pcp_workstack.csv" : "unassigned_workstack.csv"

        axios.get(reporturl, { params: params }).then((response) => {
            let url = response.data['presigned_url'];
            setFileName(filename);
            setCsvResp(url);
            setCsvLink(true);
            setLoading(false);
            setExcelLink(false);

        }).catch((error) => {
            setAlertOpen(true);
            console.error(error);
            setLoading(false);
            setSnackMsg('Something went wrong!.Please try again!');


        });
    }



    const getExcelReport = (isAllPcp) => {
        const filterColumn = ''
        const primarykey = ['True', 'xlsx'].join(",");
        const assignName = FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`;
        const searchKey = search.toUpperCase();
        setLoading(true);
        setOpen(true);
        let homeurl = `${BASE_URL}${HCR}/${WORKSTACK_HOME}?assignee_name=${assignName}&search_key=${searchKey}&filter_column=${filterColumn}&is_primary_report=${primarykey}`;
        let allurl = `${BASE_URL}${HCR}/${ALL_PCP}?search_key=${searchKey}&filter_column=${filterColumn}&is_primary_report=${primarykey}`

        const reportsurl = isAllPcp ? allurl : homeurl
        const filename = isAllPcp ? "all_pcp_workstack.xlsx" : "unassigned_workstack.xlsx"

        axios.get(reportsurl, { params: params }).then((response) => {
            let url = response.data['presigned_url'];
            setFileName(filename);
            setExcelResp(url);
            setExcelLink(true);
            setLoading(false);
            setCsvLink(false)

        }).catch((error) => {
            setAlertOpen(true);
            console.error(error);
            setLoading(false);
            setSnackMsg('Something went wrong!.Please try again!');

        });
    }



    const getExcelAssignReport = () => {
        setLoading(true)
        setOpen(true)
        let params = {
            assignee_name: FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`,
            search_key: search.toUpperCase(),
            filter_column: '',
            is_primary_report: `True,'xlsx'`,
            logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
        }
        const reportassignurl = `${BASE_URL}${HCR}/${ASSIGNED_PCPS}`;
        axios.get(reportassignurl, { params: params }).then((res) => {
            let url = res.data['presigned_url'];
            setExcelResp(url);
            setExcelLink(true);
            setLoading(false);
            setCsvLink(false);

        }).catch((error) => {
            console.error(error);
            setLoading(false);
            setAlertOpen(true);

            setSnackMsg('Something went wrong!.Please try again!');

        });
    }


    const getCsvAssignReport = () => {
        let params = {
            assignee_name: FIRST_NAME + " " + LAST_NAME + `[${JSON.parse(localStorage.getItem("USER_EIN"))}]`,
            search_key: search.toUpperCase(),
            filter_column: '',
            is_primary_report: `True,'csv'`,
            logged_user: JSON.parse(localStorage.getItem("USER_EIN"))
        }
        setLoading(true);
        setOpen(true)
        const reportassignurl = `${BASE_URL}${HCR}/${ASSIGNED_PCPS}`;

        axios.get(reportassignurl, { params: params }).then((res) => {
            let url = res.data['presigned_url'];
            setCsvResp(url);
            setCsvLink(true);
            setLoading(false);
            setExcelLink(false);

        }).catch((error) => {
            setAlertOpen(true);

            console.error(error);
            setLoading(false);
            setSnackMsg('Something went wrong!.Please try again!');

        });
    }




    /** for passing to child component*/

    const clickEventFromChild = (type, fileName, jobId) => {

        if (type === 'popupClose') {
            getAssignedPCPs();
        } else if (type === 'jumper') {
            getJumperSchedule(fileName, jobId)
        }

    };






   

    const rowStatusAssigned = (headcell, row) => {
        if (!newHeaderAdded && row.status === "Assigned") {
            return <ViewPopup page={page} setPage={setPage} jumper={row[headcell.id]} workstackDialog={row[headcell.id]} jobId={row.job_id} getJumperSchedule={getJumperSchedule} onDownload={onDownload} row={row} getAssignedPCPs={getAssignedPCPs} clickEventFromChild={clickEventFromChild} />

        }
        else {
            return row.status
        }
    }

    const headcellWorkstack_select = (headcell, row) => {
        if (headcell.id !== 'workstack_select') {
            return <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{headcellfn(headcell, row, getJumperSchedule,onDownload)}</TableCell>
        }
        else {
            return <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 2, padding: "2px" }} align='center'>
                {rowStatus(row, isSelected, selectedCheckboxes, setSelectedCheckboxes)}
            </TableCell>
        }
    }


    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Box backgroundColor="#d1d1d1" style={{
                marginTop: "1px", fontSize: "23px",
                marginLeft: "-24px", width: "auto", height: "57px", paddingLeft: "22px",
                paddingTop: "8px", borderBottom: "1.5px solid whitesmoke"
            }}>
                Cu Recovery
            </Box>


            <Grid container item lg={12} >

                <Grid item lg={4} sm={4}>
                    <Card sx={{ width: "355px", height: "199px", background: "white", border: "3px solid whitesmoke", marginLeft: "-1px" }}>
                        <CardContent>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                                <Typography sx={{ fontSize: 23, color: 'black', marginTop: "-19px", marginLeft: "-153px" }}>
                                    Cu Recovery
                                </Typography>
                                <Typography sx={{ fontSize: 12, color: 'black', fontWeight: 500, marginTop: "-3px", marginLeft: "19px" }}>
                                    1.Select each of the PCPs you plan to complete by selecting the check box next to each PCP, then click the <strong>Assign to Me</strong> button.
                                </Typography>
                                <ReportProblemOutlinedIcon style={{ fontSize: 40, color: "orange", marginTop: "-8px", marginLeft: "-316px" }} />

                                <Typography sx={{ fontSize: 12, color: 'black', fontWeight: 500, marginTop: "-26px", marginLeft: "19px" }}>
                                    2.To view all the PCP/s assigned to you, click the <strong>View Assigned PCP/s</strong> button.
                                </Typography>
                                <Typography sx={{ fontSize: 12, color: 'black', fontWeight: 500, marginTop: "3px", marginLeft: "19px" }}>
                                    3.To mark the PCP as <strong>completed</strong> click on the view assigned PCP/s button and then click on the required button next to the PCP you want to complete.
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={4} sm={4}>
                    <Card sx={{ width: "446px", height: "199px", lineHeight: 1.05, background: "#d1d1d1", border: "1.5px solid whitesmoke", marginLeft: "24px" }}>
                        <CardContent>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignContent: 'center' }}>
                                <Typography sx={{ width: "446px", marginLeft: "-16px", paddingLeft: "15px", height: "26px", paddingTop: "3px", fontSize: 16, fontWeight: 700, color: 'white', marginTop: "-19px", background: "#142032" }}>
                                    Select Column
                                </Typography>

                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "0 15px",
                                    marginTop: "5px"
                                }}>
                                    {checkboxInfo?.map((value) => {
                                        return (
                                            <div
                                                key={value.id}

                                            >
                                                <input

                                                    id={value.id}
                                                    checked={value.checked}
                                                    onChange={(event)=>handleCheckboxChange(event,checkboxInfo,setCheckboxInfo)}
                                                    data-testid={value.id}
                                                    style={{ marginLeft: "-10px", marginTop: "0px" }}
                                                    type="checkbox"
                                                    name="name"
                                                />
                                                <label style={{ fontWeight: 700, fontSize: "11px", marginLeft: "-1px", marginTop: "4px" }} htmlFor={value.id}
                                                >
                                                    {value.label}</label>

                                            </div>

                                        );
                                    })}

                                </div>

                            </div>
                            <Button id="button_viewallselect" data-testid="button_viewallselect" sx={{ padding: "2px 8px", color: "white", border: "whitesmoke", background: "#142032", marginLeft: "158px", fontSize: "14px", marginTop: "2px", textTransform: "none" }} variant="contained" onClick={handleSelectButton} >Select</Button>

                        </CardContent>

                    </Card>
                </Grid>

                <Grid item lg={4} sm={4}>
                    <Card sx={{ width: 208, height: "199px", marginLeft: "143px", background: "white", border: "3px solid whitesmoke" }}>
                        {currentState === 'home' ?
                            <CardContent>
                                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                                    <Stack spacing={2}>
                                        <Button id="button_assigntome" data-testid="button_assigntome" sx={{ width: "206px", background: "darkgreen", border: "whitesmoke", fontSize: "14px" }} variant="contained" onClick={() => handleAssignCheckbox(setSelectedCheckboxes, selectedCheckboxes, FIRST_NAME, LAST_NAME, setLoading, setSnackMsg, setOpen, getWorkstackHomeData, ViewPcpFlag, setExcelLink, setCsvLink, setSnackbarOpen)} disabled={isViewButtonDisabled} style={{ background: 'darkgreen' }}>Assign to Me</Button> <Snackbar
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            open={snackbarOpen}
                                            onClose={() => setSnackbarOpen(false)}
                                            message="PCP assigned successfully."


                                            autoHideDuration={2000}
                                        />
                                        <Button id="button_viewassignedpcps" data-testid="button_viewassignedpcps" sx={{ width: "206px", color: "black", border: "whitesmoke", background: "lightgreen", fontSize: "14px" }} variant="contained" onClick={handleViewAssignedCheckbox}>View Assigned PCP/s</Button>
                                        <Button id="button_viewallpcps" data-testid="button_viewallpcps" sx={{ width: "206px", color: "black", border: "whitesmoke", background: "lightgrey", fontSize: "14px" }} variant="contained" onClick={viewAllPcpButtonClick}>View All PCPs</Button>
                                    </Stack>
                                </div>
                            </CardContent> :
                            <CardContent>
                                {
                                    currentState === 'viewAssigned' ? <><Button id="workstack_unassign" data-testid="workstack_unassign" sx={{ height: "26px", width: "81px", marginTop: "21px", background: "#142032", border: "whitesmoke", marginLeft: "-6px", fontSize: "15px", textTransform: "none" }} variant="contained" onClick={() => handleUnassign(setSelectedCheckboxes, setSnackbarsOpen, setExcelLink, setCsvLink, selectedCheckboxes, FIRST_NAME, LAST_NAME, getAssignedPCPs, setOpen, setLoading, setAlertOpen, setSnackMsg)} disabled={isViewButtonDisabled}>Unassign</Button><Snackbar
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        open={snackbarsOpen}
                                        onClose={() => setSnackbarsOpen(false)}
                                        message="PCP unassigned successfully."
                                        autoHideDuration={2000}
                                    /> <br>
                                        </br> <Button id="workstack_assigned_back" data-testid="workstack_assigned_back_unassign" sx={{ height: "26px", width: "141px", marginTop: "21px", background: "#142032", border: "whitesmoke", marginLeft: "-6px", fontSize: "15px", textTransform: "none" }} variant="contained" onClick={handleAssignBack}>Back to Home</Button></> :
                                        <Button id="workstack_back" data-testid="workstack_back" sx={{ height: "26px", width: "1px", marginTop: "21px", background: "#142032", border: "whitesmoke", marginLeft: "-6px", fontSize: "15px", textTransform: "none" }} variant="contained" onClick={handleBack}  >Back</Button>

                                }
                            </CardContent>}

                    </Card>
                </Grid>

            </Grid>

            <div >

                <FormControl size="small" sx={{ marginTop: "20px", minWidth: "130px", marginLeft: "290px" }}>
                    <InputLabel id="demo-select-small-label" sx={{ fontSize: 12, fontWeight: 'bold' }} >Primary Report</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="PrimaryReport"
                        sx={{ fontSize: 12 }}
                    >

                        <MenuItem id='csv_workstack' value='csv' style={{ fontSize: 12, textDecoration: 'none', color: 'black' }} onClick={handleReportCsv}>
                            CSV Report
                        </MenuItem>
                        <MenuItem id='excel_workstack' sx={{ fontSize: 12 }} value='excel' onClick={() => handleReportExcel(currentState, getExcelReport, ViewPcpFlag, getExcelAssignReport)}> Excel Report</MenuItem>
                    </Select>
                </FormControl>

            </div>

            {csvLink &&
                <div style={{ marginTop: '-24px' }} >
                    <Link
                        id="download-csv-Report"
                        variant="body2"
                        underline='hover'
                        onClick={() => {
                            if (currentState === "viewAssigned") {
                                onCsvAssignDownload(csvResp, setSnackMsg, setLoading, setOpen, setCsvLink, setAlertOpen);
                            } else {
                                onCsvDownload(csvResp, fileName, setSnackMsg, setLoading, setCsvLink, setAlertOpen);
                            }
                        }}
                        data-testid="download-link"
                        sx={{ fontSize: 14, color: 'Red', marginLeft: "450px", '&:hover': { cursor: 'pointer' } }}
                    >
                        Click here to download the CSV Report
                    </Link>
                </div>

            }
            {excelLink &&
                <div style={{ marginTop: '-24px' }} >
                    <Link
                        id="download-excel-Report"
                        variant="body2"
                        underline='hover'
                        onClick={() => {
                            if (currentState === "viewAssigned") {
                                onExcelAssignDownload(excelResp, setSnackMsg, setLoading, setOpen, setExcelLink, setAlertOpen);

                            } else {
                                onExcelDownload(excelResp, setSnackMsg, setLoading, setExcelLink, setAlertOpen, fileName);
                            }
                        }}
                        data-testid="download-excel-link"
                        sx={{ fontSize: 14, color: 'Red', marginLeft: "450px", '&:hover': { cursor: 'pointer' } }}
                    >
                        Click here to download the Excel Report
                    </Link>
                </div>

            }

            <div>
                <FormControl sx={{ m: 1, width: '25ch', marginTop: "-45px" }} variant="standard">
                    <InputLabel >Search</InputLabel>
                    <Input
                        id="search"
                        type='text'
                        size="small"
                        data-testid="input-search"
                        onChange={(searchVal) => { requestSearch(searchVal, setSearch, tabledataTemp, setTabledata); setPage(0) }}
                        value={search}
                        endAdornment={
                            <InputAdornment position="end">
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon />
                                </InputAdornment>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} >
                <DialogContent>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{
                                    "& th": {
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        backgroundColor: "#5488c7",
                                        color: "white",

                                    }
                                }}>
                                    <TableCell>Sl.No</TableCell>
                                    <TableCell>SUB JS ID&apos;S</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {responseData ? (
                                    responseData?.map((subJsId, index) => (
                                        <TableRow key={subJsId}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{subJsId}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2}>Loading...</TableCell>
                                    </TableRow>
                                )}
                                {responseData?.error && (
                                    <TableRow>
                                        <TableCell colSpan={2}>Error: {responseData.error}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>



            <TableContainer id="workstack-table-container" sx={{ marginTop: "4px", overflowX: "scroll" }} component={Paper}>
                <Table id="workstack-table" stickyHeader sx={{ width: "100%" }} size="small">
                    <TableHead>
                        <TableRow sx={{
                            "& th": {
                                fontSize: "12px",
                                fontWeight: "bold",
                                backgroundColor: "#5488c7",
                                color: "white",

                            }
                        }}>

                            {(tableHeader)?.map((headcell) => (
                                <TableCell key={headcell.id} sx={{ color: "white", minWidth: "80px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">
                                    <TableSortLabel
                                        active={orderBy === headcell.id}
                                        data-testid={`table-head-${headcell.id}`}
                                        check direction={orderBy === headcell.id ? order : 'asc'}
                                        onClick={handleSort(headcell.id,orderBy,order,setOrder,setOrderBy)}
                                    > {headcell.label}</TableSortLabel>

                                </TableCell>
                            ))}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (
                                tabledata?.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))?.map((row) => (


                                    <TableRow
                                        key={row.created_date}
                                    >

                                        {(newHeaderAdded ? tableHeader : headerTable)?.map((headcell) => (

                                            headcell.id === "status"
                                                ? (
                                                    <TableCell key={headcell.id}
                                                        sx={{
                                                            border: "1px solid #c2c2c2",
                                                            fontSize: "12px",
                                                            fontWeight: 700,
                                                            margin: 0,
                                                            padding: "2px",
                                                            color: handleColor(row),

                                                        }}
                                                        align="center"
                                                    >

                                                        {rowStatusAssigned(headcell, row)}
                                                    </TableCell>

                                                ) :
                                                headcelljs_id(headcell, row, headcellWorkstack_select, setIsDialogOpen, setLoading, setOpen, setResponseData, setAlertOpen, setSnackMsg)


                                        ))}
                                    </TableRow>
                                ))
                        }

                    </TableBody>
                </Table>

                <Tablepagination
                    setPage={setPage}
                    page={page}
                    count={tabledata?.length}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                />
            </TableContainer>


        </>
    );
}
