import React, { useEffect,useContext } from 'react'
import { Link, useLocation } from "react-router-dom";
import { BreadCrumbContext } from "./BreadCrumbContext";

/**
 *
 * Breadcrum for pages.
 * @return {*} 
 */
const Breadcrumb = () => {

    /**title case**/
    function toTitleCase(str) {
        return str.replace(/\b\w+/g, function (s) {
            return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
        })
    }
    /** location variable*/
    const location = useLocation();

    /** hold queryparams from path*/
    let search = location.search === '' ? null : location.search;

    /** hold pathname list splitted with /*/
    const pathnames = location.pathname.split("/").filter((x) => x);

    /** data from context */
    const { pathList, setPathList } = useContext(BreadCrumbContext);
    
    useEffect(() => {
        if (location.pathname !== "/home") {
            if (!(pathList.some(val => { return (val['pathname'] === pathnames[0]) }))) {
                if (pathnames[0] !== "myjobs" && pathnames[0] !== "admin_report") {
                    let pathObj = { 'pathname': pathnames[0], search: search };
                    setPathList([...pathList, pathObj])
                }
            }
        }
        // eslint-disable-next-line
    }, [pathnames])

    return (
        <div style={{ display: "flex", fontSize: 11 }}>
            {location.pathname === "" || location.pathname === "/myjobs" || location.pathname === "/admin_report" ? null : <Link id="breadcrumb-home" to="/home">Home</Link>}
            {pathList ? pathList.map((value) => {
                const splitedValue = value.pathname.split("/").filter((x) => x);
                const names = splitedValue[splitedValue.length - 1]
                const to = value.search ? `/${value.pathname}/${value.search}` : `/${value.pathname}`;
                 return (
                    <div key={value.pathname}>
                        {location.pathname !== "/myjobs" && location.pathname !== "/admin_report" ? <span key={to}>&nbsp;&gt;&nbsp;<Link id={names} data-testid={names} to={to}>{toTitleCase(names)}</Link></span> : null}
                    </div>
                );
            }) : null}
        </div >
    );
};

export default Breadcrumb;
