import { ADMIN } from "../../constant/userConfig";

export const handledisable = (disable, jobdetailsdisable, userData) => {

    if (disable) {
        return disable;
    }
    else if (userData?.ROLE === ADMIN) {
        return false
    }
    else if (jobdetailsdisable) {
        return jobdetailsdisable
    }
return false
}

export default handledisable