import React, { useState,useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { BreadCrumbHcrContext } from '../components/common/BreadCrumbContext';
import BreadcrumbHcr from '../components/common/BreadcrumbHcr';
 
const visibleBreadcumRoutePath = ['hcrplanning', 'planningcable','hcrpcp'];

function HcrLayout() {
    const pathData = JSON.parse(localStorage.getItem('hcrplanningBreadcrumb'));
    const pathData2 = pathData ? pathData : []
    const [pathList, setPathList] = useState(pathData2);
    const location = useLocation();

    const isVisible = () => {
        let isDisplay = false;
        visibleBreadcumRoutePath.forEach((item) => {
            if (location.pathname.includes(item)) {
                isDisplay = true;
            }
        });

        return isDisplay;
    };

    const visibility = isVisible();

    const updateBreadcrumb = (newPathList) => {
        setPathList(newPathList);
        localStorage.setItem('hcrplanningBreadcrumb', JSON.stringify(newPathList));
    };

    const contextValue = useMemo(()=>({
        pathList, setPathList: updateBreadcrumb
    }),[pathList, setPathList])
    

    return (
        
            <BreadCrumbHcrContext.Provider value={contextValue}>
                {visibility && <BreadcrumbHcr />}
                <Outlet />
            </BreadCrumbHcrContext.Provider>
       
    );
}

export default HcrLayout;