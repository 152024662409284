import { useRoutes } from "react-router-dom";
import React, { useContext } from 'react'
import mainRoutes from '../constant/mainRoutes';
 import { AllContext } from "../components/common/BreadCrumbContext";

function BaseRouter() {
    const {userData}=useContext(AllContext)
    const routing = useRoutes(
        mainRoutes(userData?.ROLE)
    );

    return <>{routing}</>;
}

export default BaseRouter;




