import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import DisplayInfo from './DisplayInfo';
import headCells from '../../constant/viewheadcell';
import '../../container/App.css'
import { Box, Alert, Backdrop, CircularProgress, Snackbar, Card, CardContent, Checkbox, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ACR, BASE_URL, CABINET_VIEW, CABLE_VIEW, PERMUTAION_VIEW } from '../../constant/urls';
import { BreadCrumbContext,AllContext } from '../common/BreadCrumbContext';
import { useMsal } from '@azure/msal-react';
import {handledisable} from './View.util'

const View = ({ sau, css, cable_id, result, exchange }) => {
    const { accounts } = useMsal()
     
    const USER_EIN =accounts[0]?.idTokenClaims.EIN
    const { userData } = useContext(AllContext)
    
    const params={
        logged_user: userData?.USER_EIN
    }
    const [selectedPcp, setSelectedPcp] = useState([]);
    const { jobdetails, viewjobid } = useContext(BreadCrumbContext);
    const job_owner = jobdetails?.job_owner !== null ? jobdetails?.job_owner : "";
    const job_id = jobdetails?.job_id !== null ? jobdetails?.job_id : "";

    useEffect(() => {
        setLocalStorage();
    }, []);

    const setLocalStorage = () => {
        if (localStorage.getItem('selectedPCP') !== null) {
            const storedPcp = JSON.parse(localStorage.getItem('selectedPCP')) || {};
            setSelectedPcp(storedPcp);
        }
    }
    const Rowsselection = (groupid, item) => {
        const newselectedRowMap = { ...selectedPcp }

        if (newselectedRowMap[groupid]?.id === item?.id && newselectedRowMap[groupid]?.Potential_Net === item?.Potential_Net) {
            delete newselectedRowMap[groupid];
        }
        else {
            newselectedRowMap[groupid] = item;
        }
        const sortednewdata = Object.keys(newselectedRowMap).sort((a, b) => {
            const numA = parseInt(a.replace(/\D/g, ''), 10);
            const numB = parseInt(b.replace(/\D/g, ''), 10);
            return numA - numB;
        }).reduce((acc, key) => {
            acc[key] = newselectedRowMap[key];
            return acc
        }, {})
        setSelectedPcp({ ...sortednewdata });
        localStorage.setItem('selectedPCP', JSON.stringify(sortednewdata));
    }

    // snackbar close
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [snackMsg, setSnackMsg] = React.useState('');
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setAlertOpen(false);
        setOpen(false)
    };

    // axios call for table data
    const url1 = `${BASE_URL}/${ACR}/${PERMUTAION_VIEW}?sau=${sau}&cable_id=${cable_id}&result=${result}&job_owner_ein=${job_owner}&job_id=${job_id}&logged_user_ein=${USER_EIN}`
    const [Tabledata, setTabledata] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios.get(url1, )
            .then((res) => {
                setTabledata(res.data.Data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setAlertOpen(true)
                setSnackMsg('Something went wrong!.Please try again!');
            })
        // eslint-disable-next-line
    }, []);

    // extracting pcp names form 
    const uniquePCP = [...new Set(Tabledata?.map((item) => item.pcp_id))];

    // filter based on search values
    const [search, setSearch] = useState('');
    const filteredPCP = (uniquePCP).filter((grp) => (
        grp.toLowerCase().includes(search.toLowerCase())
    ))

    // axios call for cable and cabinet info
    const [schedule, setSchedule] = useState()
    const [donor_cables, setDonor_cables] = useState()
    const [cableinfo, setCableinfo] = useState([]);
    const [cabinetinfo, setCabinetinfo] = useState([])
    const [actual_donor_cables, setactual_donor_cables] = useState([]);

    useEffect(() => {
        const mappedData = Object.keys(selectedPcp).map((key) => {
            const item = selectedPcp[key];
            return (item.pcp_id)
        })
        const actual_donor_cable = Object.keys(selectedPcp).map((key) => {
            const item = selectedPcp[key];
            return (item.actual_donor_cable)
        })
        setactual_donor_cables(actual_donor_cable);

        const schedule = Object.keys(selectedPcp).map((key) => {
            const item = selectedPcp[key];
            return (item.Potential_Net)
        })
        setSchedule(schedule);
        const donor_cables = Object.keys(selectedPcp).map((key) => {
            const item = selectedPcp[key];
            return (item.Host_cable)
        })
        setDonor_cables(donor_cables)
        let cable_view = `${BASE_URL}/${ACR}/${CABLE_VIEW}?sau=${sau}&pcp_id=${mappedData}&cable_id=${cable_id}`;
        let cabinet_view = `${BASE_URL}/${ACR}/${CABINET_VIEW}?sau=${sau}&pcp_id=${mappedData}&cable_id=${cable_id}`;

        axios.get(cable_view,{ params:params })
            .then((res) => {
                setCableinfo(res.data.Data);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setAlertOpen(true)
                setSnackMsg('Something went wrong!.Please try again!');
            })

        axios.get(cabinet_view,{ params:params })
            .then((res) => {
                setCabinetinfo(res.data.Data);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setAlertOpen(true)
                setSnackMsg('Something went wrong!.Please try again!');
            })
        // eslint-disable-next-line
    }, [selectedPcp]);

    const ishighlightopenjob = (is_highlighted) => {
        if (viewjobid === job_id) {
            const color = is_highlighted ? "rgb(222, 184, 135)" : null
            return color
        }
    }

    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Card variant="outlined" id="view-card" sx={{ minWidth: 305, marginTop: "10px" }}>
                <CardContent>
                    <h4 id="view-heading" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#1976d2", marginTop: 0 }}>{css}({exchange})</h4>
                    <TextField
                        id="input-with-icon-textfield"
                        label="Search"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        sx={{ marginBottom: "20px" }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <TableContainer id="view-table-container" component={Paper} sx={{ position: "relative", maxHeight: "350px" }}>
                        <Table id="view-table" >
                            <TableHead id="view-tablehead" sx={{ top: 0, position: "sticky", zIndex: 1 }}>
                                <TableRow id="view-table-row-name" sx={{ backgroundColor: "#5488c7", color: "white", padding: "2px", }} >
                                    {headCells.map((headcell) => (
                                        <TableCell id={"view-tablecell-" + headcell.label} key={headcell.label} align='center' sx={{ color: "white", minWidth: "50px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: headcell.padding }}>
                                            {headcell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody >

                                {
                                    filteredPCP?filteredPCP.map((group,index) =>
                                    (
                                        <>
                                            <TableRow id={"view-table-row-" + index} key={group}>
                                                <TableCell id={"view-table-row-" + group} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "10px", }} align='center'>{group}</TableCell>
                                            </TableRow>
                                            {
                                                Tabledata.filter((item) => (item.pcp_id === group))
                                                    .map((item, index) => (
                                                        <TableRow id={"view-table-row-" + item.pcp_id + "-" + index} key={group}>
                                                            <TableCell ></TableCell>
                                                            <TableCell  sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted), width: "20px", cursor: item.Working === 0 ? "no-drop" : "pointer" }} align='center'>
                                                                <Checkbox
                                                                    checked={selectedPcp[group] ? selectedPcp[group].Potential_Net === item.Potential_Net && selectedPcp[group].id === item.id : false}
                                                                    onChange={() => Rowsselection(group, item)}
                                                                    disabled={handledisable(item, viewjobid, job_id, jobdetails, USER_EIN)}
                                                                    id={"view-tablecell-" + item.pcp_id + "-" + index}
                                                                    data-testid={`view-tablecell-${item.pcp_id}-${index}`}
                                                                />
                                                            </TableCell>
                                                            <TableCell id={"view-tablecell-" + item.pcp_id + "-" + index + "-" + item.sau} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted) }} align='center'>{item.sau}</TableCell>
                                                            <TableCell id={"view-tablecell-" + item.pcp_id + "-" + index + "-" + item.Recovery_cable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted) }} align='center'>{item.Recovery_cable}</TableCell>
                                                            <TableCell id={"view-tablecell-" + item.pcp_id + "-" + index + "-" + item.E0_DP} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted) }} align='center'>{item.E0_DP}</TableCell>
                                                            <TableCell id={"view-tablecell-" + item.pcp_id + "-" + index + "-" + item.Host_cable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted) }} align='center'>{item.Host_cable}</TableCell>
                                                            <TableCell id={"view-tablecell-" + item.pcp_id + "-" + index + "-" + item.Working} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted) }} align='center'>{item.Working}</TableCell>
                                                            <TableCell id={"view-tablecell-" + item.pcp_id + "-" + index + "-" + item.Target} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted) }} align='center'>{item.Target}</TableCell>
                                                            <TableCell id={"view-tablecell-" + item.pcp_id + "-" + index + "-" + item.Available} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: 0, backgroundColor: ishighlightopenjob(item.is_highlighted) }} align='center'>{item.Available}</TableCell>
                                                        </TableRow>
                                                    ))}
                                        </>
                                    )): <Box sx={{ width: '100%' }}>
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={open}
                                        onClick={handleClose}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                </Box>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*  */}
                </CardContent>
            </Card>
            {(Object.keys(selectedPcp).length > 0 && cableinfo && cabinetinfo) ? <DisplayInfo cableinfo={cableinfo} cabinetinfo={cabinetinfo} selectedPcp={selectedPcp} sau={sau} schedule={schedule} donor_cables={donor_cables} cable_id={cable_id} actual_donor_cables={actual_donor_cables} /> : null}

        </>

    )
}

export default View