import { Link, Button, Card, CardContent, Snackbar, Alert, CardHeader, Box, Backdrop, } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import React, { useEffect, useState } from 'react'
import { ACR, BASE_URL, ADMIN_REPORT } from '../../constant/urls';
import axios from 'axios';
import CircularProgressWithLabel from '../common/CircularProgressWithLabel';
import { useMsal } from '@azure/msal-react';

const AdminReport = () => {
    const [snackopen, setSnackopen] = useState(false);
    const [snackmsg, setSnackmsg] = useState("");
    const [showLink, setShowLink] = React.useState(false);
    const [adminResp, setAdminResp] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { accounts } = useMsal()

    //to pass logged user params for apis
    const params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }

    //to close the snackbar
    const handlesnackClose = () => {
        setSnackopen(false);
    };

    /**
           Admin Report code for prod env to download the admin report
    */
    //for presigned url
    const handleGenerateReport = () => {
        const reporturl = `${BASE_URL}/${ACR}/${ADMIN_REPORT}`;
        setLoading(true);

        axios.get(reporturl, { params: params }).then((response) => {
            let url = response.data['presigned_url'];
            setAdminResp(url);
            setShowLink(true);
            setLoading(false);
        }).catch((error) => {
            setSnackopen(true)
            console.error(error);
            setLoading(false);
            setSnackmsg('Something went wrong!.Please try again!');

        });
    }

    //to download the report using presigned url
    const onDownload = () => {
        setLoading(true);
        axios.get(adminResp, { responseType: 'blob' }).then((response) => {
            const url = URL.createObjectURL(response.data) //<---- this should be data.data
            const link = document.createElement('a');
            link.href = url;
            const fileName = "In_Year_Tabular_Report.xlsx"
            link.setAttribute('download', fileName);
            link.click();
            setLoading(false);
        }).catch((error) => {
            setSnackopen(true)
            setLoading(false);
            console.error(error);
            setSnackmsg('Something went wrong!.Please try again!');
        });

    };

    const open = true;
    
    //useEffect for percentage downloading 
    const [progress, setProgress] = React.useState(10);
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 2000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', display: "flex", justifyContent: "center", alignItems: "center", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgressWithLabel value={progress} />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={snackopen} autoHideDuration={6000} onClose={handlesnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handlesnackClose} severity="error" sx={{ width: '100%' }}>
                    {snackmsg}
                </Alert>
            </Snackbar>

            <Card id="admin-card" variant="outlined" sx={{ minWidth: 275, marginTop: "10px", }}>
                <CardHeader id="admin-cardhead" sx={{ background: 'linear-gradient(to bottom,  #142032 ,#2e3a4c)', padding: 0.5 }}
                    titleTypographyProps={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}
                    title="IN YEAR TABULAR REPORT"
                />
                <CardContent id="admin-cardcontent" sx={{ marginTop: "-13px", display: "flex", height: "300px", justifyContent: "center", alignItems: "center" }}>
                    <Button id="admin-button-generate-report" data-testid="admin-button-generate-report" style={{ color: "white", backgroundColor: '#142032', width: "170px", height: "30px", marginLeft: "5px", fontSize: 12 }} onClick={handleGenerateReport}><DownloadIcon />Generate Report</Button>
                    {showLink &&
                        <div style={{ marginTop: '85px' }} >
                            <Link
                                id="download-admin-Report"
                                variant="body2"
                                underline='hover'
                                onClick={onDownload}
                                data-testid="download-admin-Report"
                                sx={{ fontSize: 18, color: 'Red', marginLeft: "-247px", '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the Admin report
                            </Link>
                        </div>

                    }
                </CardContent>

            </Card >
        </>
    )
}

export default AdminReport

