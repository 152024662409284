import { useMsal } from "@azure/msal-react";
import axios from "axios";

const AxiosInterceptors = () => {
  const { accounts, instance } = useMsal();
  // adding AuthorizationToken for headers to all api using axios interceptor 
  axios.interceptors.request.use(
    async (config) => {
      const account = accounts[0];

      if (account) {
         // not adding interceptor and checking the deploying environment
        const isS3Request = config.url.startsWith(process.env.REACT_APP_S3_REQUEST)
        if (!isS3Request) {
          try {
            const tokenres = await instance.acquireTokenSilent({
              scopes: ["User.Read",],
              account: account,
            })
            config.headers['AuthorizationToken'] = 'Bearer ' + tokenres.idToken
          }
          catch (error) {
            console.error('failed to acquire token')
          }
        }
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )
  return null;
}
export default AxiosInterceptors