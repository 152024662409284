export const USER_EIN = JSON.parse(localStorage.getItem("USER_EIN"))
export const FIRST_NAME = JSON.parse(localStorage.getItem("FIRST_NAME"))
export const LAST_NAME = JSON.parse(localStorage.getItem("LAST_NAME"))
export const EMAIL_ID = JSON.parse(localStorage.getItem("EMAIL_ID"))
export const ROLE = JSON.parse(localStorage.getItem("ROLE"))

export const ADMIN =  process.env.REACT_APP_ADMIN
export const PLANNER =  process.env.REACT_APP_PLANNER
export const ENGINEER =  process.env.REACT_APP_ENGINEER
export const ENGINEER_MANAGER =  process.env.REACT_APP_ENGINEER_MANAGER

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const TENANT_ID = process.env.REACT_APP_TENANT_ID
export const AUTHORITY = `https://login.microsoftonline.com/${TENANT_ID}`
export const REDIRECTURI = process.env.REACT_APP_REDIRECTURI

