import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ViewListIcon from '@mui/icons-material/ViewList';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { ENGINEER, ENGINEER_MANAGER } from './userConfig';

export default function SidebarListHcr (ROLE){
    
return [
    {
        'id': 1,
        'path': ROLE === ENGINEER || ROLE === ENGINEER_MANAGER ? "/home" : '/hcr',
        'name': 'Home',
        'icon': <HomeIcon />,
        'subMenu': []

    },
    {
        'id': 2,
        'path': '/hcrworkstack',
        'name': 'HCR Workstack',
        'icon': <LocalShippingIcon />,
        'subMenu': []
    },
    {
        'id': 3,
        'path': '/hcrassignunassign',
        'name': 'HCR Assign/Un-Assign',
        'icon': <PersonIcon />,
        'subMenu': []
    },
    {
        'id': 4,
        'path': 'hcrplanning',
        'name': 'Planning Workstack',
        'icon': <AssignmentIcon />,
        'subMenu': []
    },

    {
        'id': 5,
        'path': '/hcrreporting',
        'name': 'HCR Reporting',
        'icon': <ViewListIcon />,
        'subMenu': []
    }

]
}
 