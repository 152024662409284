import EastIcon from '@mui/icons-material/East';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

export const requestSearch = (event, tabledataCopy, setTabledata) => {
  let searchedVal = event?.target?.value;
  const filteredRows = tabledataCopy.filter((row) => {
    return (row.rd.toLowerCase().includes(searchedVal.toLowerCase()));
  });
  setTabledata(filteredRows);
  return []
};
export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

    /**
     *
     * for setting the wd_jumber arrow based on condition
     * @param {*} wd_jumper
     * @return {*} 
     */
export const setIcon = (wd_jumper) => {
  if (wd_jumper > 0) {
    return (<NorthIcon sx={{ fontSize: 'large', mr: 0.6, color: 'green' }}></NorthIcon>);
  } else if (wd_jumper < 0) {
    return (<SouthIcon sx={{ fontSize: 'large', mr: 0.6, color: 'red' }}></SouthIcon>);
  } else {
    return (<EastIcon sx={{ fontSize: 'large', mr: 0.6, color: 'orange' }}></EastIcon>);
  }
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { requestSearch, descendingComparator, setIcon, getComparator }