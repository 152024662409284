import React, { useEffect, useState, useContext } from "react";
import {
    TableSortLabel, Card, CardContent, Typography, MenuItem, FormControl, Select,
    Paper, InputLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Link, Grid, Stack, InputAdornment, Input, Snackbar, Alert, CircularProgress, Box, Backdrop
} from "@mui/material";
import "./HcrPlanning.css";
import { assigntoCheckboxList, table_header } from './HcrPlanningConst';
import { BASE_URL, CARD_URL, HOME, HCR } from '../../constant/urls';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { BreadCrumbHcrContext } from '../common/BreadCrumbContext';
import { useMsal } from "@azure/msal-react";
import {  getComparator, requestSearch } from "./HcrPlanning.util";
import Tablepagination from "../common/Tablepagination";

function HcrPlanning() {
    const { pathList = [], setPathList = jest.fn() } = useContext(BreadCrumbHcrContext);

    const { accounts } = useMsal()


    const params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    useEffect(() => {
        if (!pathList.some(item => item.pathname === 'hcrplanning')) {
            setPathList([...pathList, { pathname: 'hcrplanning' }]);
        }
    }, []);

    const handleReportCsv = () => {

        getCsvReport();
    }

    const handleReportExcel = () => {
        getExcelReport();
    }

    const [excelResp, setExcelResp] = React.useState(null);
    const [showExcelLink, setShowExcelLink] = React.useState(false);


    const [csvResp, setCsvResp] = React.useState(null);
    const [showCsvLink, setShowCsvLink] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    /**
          Primary Report code for prod env
   */
    const getCsvReport = () => {

        const filter_column = '';
        const is_primary_report = ['True', 'csv'].join(",");
        const search_key = search.toUpperCase();
        const reporturl = `${BASE_URL}${HCR}${HOME}?search_key=${search_key}&filter_column=${filter_column}&is_primary_report=${is_primary_report}`;
        setLoading(true);
        setOpen(true);
        axios.get(reporturl, { params: params }).then((response) => {
            if (response.data) {
                let url = response.data['presigned_url'];
                setCsvResp(url);
                setShowCsvLink(true);
                setLoading(false);
                setShowExcelLink(false);


            }
        }).catch((error) => {
            setAlertOpen(true);
            console.error(error);
            setLoading(false);
            setSnackMsg('Something went wrong!.Please try again!');


        });
    }


    const onCsvDownload = () => {
        setLoading(true);
        setOpen(true);
        axios.get(csvResp, { responseType: 'blob' }).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'cable_workstack.csv');
                link.click();
                setLoading(false);
            }
        }).catch((error) => {
            setAlertOpen(true);
            setLoading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
            setShowCsvLink(false);

        });

    };

    const getExcelReport = () => {

        const filter_column = '';
        const is_primary_report = ['True', 'xlsx'].join(",");
        const search_key = search.toUpperCase();
        setLoading(true);
        setOpen(true);
        let reportsurl = `${BASE_URL}${HCR}${HOME}?search_key=${search_key}&filter_column=${filter_column}&is_primary_report=${is_primary_report}`;

        axios.get(reportsurl, { params: params }).then((response) => {
            if (response.data) {
                let url = response.data['presigned_url'];
                setExcelResp(url);
                setShowExcelLink(true);
                setLoading(false);
                setShowCsvLink(false)


            }
        }).catch((error) => {
            setAlertOpen(true);
            console.error(error);
            setLoading(false);
            setSnackMsg('Something went wrong!.Please try again!');


        });
    }

    const onExcelDownload = () => {
        setLoading(true);
        setOpen(true);
        axios.get(excelResp, { responseType: 'blob' }).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'cable_workstack.xlsx');
                link.click();
                setLoading(false);
            }
        }).catch((error) => {
            setAlertOpen(true);
            setLoading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
            setShowExcelLink(false);

        });

    };



    /** Routing- Navgation */
    const navigate = useNavigate();

    /** Card variables */
    const [cardList, setCardList] = React.useState({});

    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const handleClose = () => {
        setAlertOpen(false);
    };
    const [open, setOpen] = React.useState(true);

    /** Table data */
    const [tabledata, setTabledata] = React.useState([]);
    const [tabledataCopy, setTabledataCopy] = React.useState([]);

    /** Pagination */
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    /** Search */
    const [search, setSearch] = useState('');


    /** Table Sorting */
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('exch_sau');
    const handleSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)

    }

    

    /** Checkbox */
    const [checkboxInfo, setCheckboxInfo] = useState(assigntoCheckboxList);
    const handleCheckBox = (item) => {
        const isChecked = item.target?.checked;
        const value = item.target?.value;
        let filteredArr = checkboxInfo?.map((data) => {
            if (data?.id === value) {
                data.checked = isChecked;
            }
            return data;
        });
        setCheckboxInfo(filteredArr)
    }

    /** @type {*} */
    const [tableHeader, setTableHeader] = useState(table_header);

    const setHeader = () => {
        const anyCheckboxChecked = checkboxInfo.some((checkId) => checkId.checked);

        const filteredHeader = anyCheckboxChecked
            ? table_header.filter((headerId) => {
                return checkboxInfo.some((checkId) => checkId.id === headerId.id && checkId.checked);
            })
            : table_header; // Return all headers if no checkbox is checked

        setTableHeader(filteredHeader);
    };
    useEffect(() => {
        if (tableHeader.length > 0) {
            setLoading(true);
            setOpen(true)
            getHomeDetails();

        }
    }, [tableHeader]);


    /**
     *
     *  get Card details
     */
    const getCardDetails = () => {
        axios.get(`${BASE_URL}${HCR}${CARD_URL}`, { params: params }).then((response) => {
            setOpen(true);
            setPage(0);
            if (response?.data?.results) {
                setCardList(response?.data?.results);
                setLoading(false);
                setOpen(false);
            }
        }).catch((error) => {
            setLoading(false);
            setOpen(false);
            setAlertOpen(true);
            setSnackMsg('Something went wrong!.Please try again!');

        });
    }

    /**
     *  get home details
     *
     */
    const filteredHeadersAndColumns = () => {

        const anyCheckboxChecked = checkboxInfo.some((checkId) => checkId.checked);

        const filteredHeader = anyCheckboxChecked
            ? tableHeader.filter((headerId) => {

                return checkboxInfo.some((checkId) => {
                    return checkId.id === headerId.id && checkId.checked;
                });
            })
            : tableHeader; // Return all headers if no checkbox is checked

        const headerColumns = [...filteredHeader.map((item) => item.id), "job_id"]; // Extract IDs and add "job_id"

        return headerColumns;
    };

    const getHomeDetails = () => {


        const headerColumns = filteredHeadersAndColumns();
        let params = {
            search_key: '',
            filter_column: headerColumns.join(','),

            is_primary_report: `False,`
        }
        setLoading(true)

        axios.get(`${BASE_URL}${HCR}${HOME}`, { params: params }).then((response) => {
            setOpen(true);
            setPage(0);

            if (response?.data?.results) {
                const initialdata = response.data?.results
                const modifiedData = initialdata.map(obj => {
                    if (obj.per_completed) {
                        obj.per_completed = parseFloat(obj.per_completed).toFixed(2);
                    }
                    return obj;
                })

                const data = modifiedData;
                const sorted = [...data].sort((a, b) => new Date(b.created) - new Date(a.created));
                setTabledata(sorted);
                setTabledataCopy(sorted)
                setOpen(false);
            }
        }).catch((error) => {
            setLoading(false);
            setOpen(false);
            setAlertOpen(true);
            setSnackMsg('Something went wrong!.Please try again!');

        });
    }

    useEffect(() => {
        setHeader();
        getCardDetails();
    }, [])
    return (
        <div>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Grid container item lg={12} sx={{ ml: '-1.5%', width: '900px' }} spacing={2}>
                <Grid item lg={6} sm={6}>
                    <Stack spacing={1}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: '4%', gap: 4, width: 400 }}>
                            <Card sx={{ width: 195, height: 50, background: 'linear-gradient(to bottom, #142032, #2e3a4c)' }}>
                                <CardContent style={{ marginTop: '-6%' }}>
                                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                                            No. of PCPs
                                        </Typography>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white' }}>
                                            {cardList?.['total_no_pcps']}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card sx={{ width: 195, height: 50, background: 'linear-gradient(to bottom, #5488c7, #75a5e0)' }}>
                                <CardContent style={{ marginTop: '-6%' }}>
                                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white', }}>
                                            Total Jumpers
                                        </Typography>
                                        <Typography sx={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', color: 'white' }}>
                                            {cardList?.['no_jumpers']}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ display: 'flex', gap: 6 }}>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom, #3a537a,#3a537a)' }}> <span className="circleText"> Completed PCPs <br />
                                {cardList?.['no_of_complete_pcps']}
                            </span></div>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom, #43B072, #52be81)' }}> <span className="circleText"> Completed Jumpers <br />
                                {cardList?.['no_of_complete_jumpers']}</span></div>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom,#818387, #b8bbc0 )' }}> <span className="circleText">PCPs Percentage <br />
                                {cardList?.['pcps_percentage']}</span></div>
                            <div className='circle' style={{ backgroundImage: 'linear-gradient(to bottom, #d0d0d0,#d1d1d1)' }}> <span className="circleText"> Jumpers Percentage <br />
                                {cardList?.['jumpers_percentage']}</span></div>
                        </div>
                    </Stack>
                </Grid>
                <Grid item lg={6} sm={6}>
                    <Card sx={{ width: "600px", height: "190px", lineHeight: 1.05, background: "#d1d1d1", border: "1.5px solid whitesmoke", marginLeft: "19px", marginTop: '4%' }}>
                        <CardContent>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignContent: 'center' }}>
                                <Typography sx={{ width: "600px", marginLeft: "-16px", paddingLeft: "15px", height: "26px", paddingTop: "3px", fontSize: 16, fontWeight: 700, color: 'white', marginTop: "-19px", background: "#142032" }}>
                                    Select Column
                                </Typography>

                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "0 15px",
                                    marginTop: "5px"
                                }}>
                                    {checkboxInfo?.map((value) => {
                                        return (
                                            <div key={value.id} >
                                                <input
                                                    onChange={handleCheckBox}
                                                    data-testid={`checkbox-${value.id}`}
                                                    value={value['id']}
                                                    style={{ marginLeft: "-10px", marginTop: "0px" }}
                                                    checked={value.checked}
                                                    type="checkbox"
                                                    name="name"
                                                />
                                                <label style={{ fontWeight: 700, fontSize: "11px", marginLeft: "-1px", marginTop: "4px" }} htmlFor="name">{value.name}</label>

                                            </div>

                                        );
                                    })}

                                </div>

                            </div>
                            <Button id="button_viewallselect" sx={{ padding: "2px 8px", color: "white", border: "whitesmoke", background: "#142032", marginLeft: "222px", marginTop: "21px", fontSize: "14px", textTransform: "none" }} variant="contained" onClick={() => setHeader()}>Select</Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <div>
                    <FormControl sx={{ m: 1, width: '25ch', marginTop: "27px" }} variant="standard">
                        <InputLabel >Search</InputLabel>
                        <Input
                            id="search"
                            type='text'
                            size="small"
                            data-testid="input-search"
                            onChange={(searchVal) => { requestSearch(searchVal, setSearch, tabledataCopy, setTabledata); setPage(0) }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                </div>
                <div style={{ marginTop: "23px", marginLeft: "-1000px" }}>
                    <FormControl size="small" sx={{ marginTop: "20px", minWidth: "130px", marginLeft: "290px" }}>
                        <InputLabel id="demo-select-small-label" sx={{ fontSize: 12, fontWeight: 'bold' }} >Primary Report</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            label="PrimaryReport"
                            sx={{ fontSize: 12 }}
                        >

                            <MenuItem id='csv-planning' value='csv' style={{ fontSize: 12, textDecoration: 'none', color: 'black' }} onClick={handleReportCsv}>
                                CSV Report
                            </MenuItem>
                            <MenuItem id='excel-planning' sx={{ fontSize: 12 }} value='excel' onClick={handleReportExcel}> Excel Report</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    {showExcelLink &&
                        <div style={{ marginTop: '53px' }} >
                            <Link
                                id="download-csv-Report"
                                variant="body2"
                                underline='hover'
                                onClick={onExcelDownload}
                                data-testid="download-link"
                                sx={{ fontSize: 14, color: 'red', marginLeft: "-680px", '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the Excel Report
                            </Link>
                        </div>

                    }
                    {showCsvLink &&
                        <div style={{ marginTop: '53px' }} >
                            <Link
                                id="download-csv-Report"
                                variant="body2"
                                underline='hover'
                                onClick={onCsvDownload}
                                data-testid="download-link"
                                sx={{ fontSize: 14, color: 'red', marginLeft: "-680px", '&:hover': { cursor: 'pointer' } }}
                            >
                                Click here to download the CSV Report
                            </Link>
                        </div>

                    }
                </div>

            </div>

            <TableContainer sx={{ marginTop: "28px", overflowX: "scroll" }} component={Paper}>
                <Table stickyHeader sx={{ width: "100%" }} size="small">
                    <TableHead>
                        <TableRow sx={{
                            "& th": {
                                fontSize: "12px",
                                fontWeight: "bold",
                                backgroundColor: "#5488c7",
                                color: "white",
                                padding: "0px"
                            }
                        }}>
                            {(tableHeader)?.map((headcell) => (
                                <TableCell key={headcell.id} sx={{ color: "white", minWidth: "80px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">
                                    <TableSortLabel
                                        active={orderBy === headcell.id}
                                        data-testid={`sort-${headcell.id}`}
                                        direction={orderBy === headcell.id ? order : 'asc'}
                                        onClick={handleSort(headcell.id)}
                                    > {headcell.label}</TableSortLabel>

                                </TableCell>


                            ))}

                            <TableCell key="cable_view" sx={{ color: "white", minWidth: "80px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">
                                View Cable
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>


                        {
                            (tabledata?.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))?.map((row, index) => (


                                <TableRow
                                    key={row.cable_id + row.exchange_name}
                                >
                                    {(tableHeader)?.map((headcell) => (


                                        headcell.id !== 'fn' ? <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row[headcell.id]}</TableCell> :
                                            <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="right"> <Link
                                                component="button"

                                                variant="body2"
                                                onClick={() => {

                                                }}
                                            >
                                                View Cable
                                            </Link></TableCell>
                                    ))}

                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="right"> <Link
                                        component="button"
                                        id={"view-cable-" + row.exchange_name + "-" + row.cable_id}
                                        variant="body2"
                                        onClick={() => {
                                            navigate({
                                                pathname: '/planningcable',
                                                search: `?job_id=${row.job_id}&planner_name=${row.created_by}&cable_id=${row.cable_id}&exchange_name=${row.exchange_name}&offloaded_pcps=${row.no_pcps}`,
                                            });
                                        }}
                                    >
                                        View Cable
                                    </Link></TableCell>

                                </TableRow>
                            ))



                        }


                    </TableBody>
                </Table>
                
                  <Tablepagination
                        setPage={setPage}
                        page={page}
                        count={tabledata?.length}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPage={rowsPerPage}
                    />
            </TableContainer>
        </div >

    )
}

export default HcrPlanning
