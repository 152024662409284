import React, { useMemo, useState } from 'react'
import { Outlet } from "react-router-dom";
import { BreadCrumbContext } from '../components/common/BreadCrumbContext';
import Breadcrumb from '../components/common/BreadCrumb';
 
function AcrLayout() {
    const [pathList, setPathList] = useState([]);
    const [selectedrow, setSelectedrow] = useState([]);
    const [selectedviewrow, setSelectedviewrow] = useState([]);
    const [checkedfilter, setCheckedfilter] = useState([]);
    const [jobdetailstable, setJobdetailstable] = useState([])
    const [jobid, setjob_id] = useState('');
    const [jobdetails, setjobdetails] = useState('');
    const [exchsau, setExchsau] = useState('');
    const [exchname, setExchname] = useState('');
    const [viewjobid, setViewjobid] = useState('');
    const [exchrecall, setExchrecall] = useState(false);
    const [jobdetailsdisable, setJobdetailsdisable] = useState(false);
    const [userData, setUserData] = useState({
        "USER_EIN": null,
        "FIRST_NAME": null,
        "LAST_NAME": null,
        "EMAIL_ID": null,
        "ROLE": null
    })
    const contextValue = useMemo(()=>({
        userData, setUserData, jobdetails, setjobdetails, jobdetailsdisable, setJobdetailsdisable, exchrecall, setExchrecall, pathList, setPathList, exchsau, setExchsau, viewjobid, setViewjobid, exchname, setExchname, jobid, setjob_id, jobdetailstable, setJobdetailstable, selectedrow, setSelectedrow, checkedfilter, setCheckedfilter, selectedviewrow, setSelectedviewrow
    }),[userData, setUserData, jobdetails, setjobdetails, jobdetailsdisable, setJobdetailsdisable, exchrecall, setExchrecall, pathList, setPathList, exchsau, setExchsau, viewjobid, setViewjobid, exchname, setExchname, jobid, setjob_id, jobdetailstable, setJobdetailstable, selectedrow, setSelectedrow, checkedfilter, setCheckedfilter, selectedviewrow, setSelectedviewrow])
    return (
         
            <BreadCrumbContext.Provider value={contextValue}>
                <Breadcrumb />
                <Outlet />
             </BreadCrumbContext.Provider>
        
    )
}

export default AcrLayout
