import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { BreadCrumbContext } from '../common/BreadCrumbContext';

export default function AssignedDialog({ assignopen, sau, setAssignopen, exchange, pick, css }) {
  const { setExchrecall } = useContext(BreadCrumbContext);
  const navigate = useNavigate()

  // if job is not created through pick a job it will navigate to cable view 
  const handleNavigate = () => {
    const recovery_cables = localStorage.getItem('selectedrow') ? JSON.parse(localStorage.getItem('selectedrow'))[0] : null;
    if (pick) {
      setAssignopen(false);
      setExchrecall(true);
    }
    else {
      navigate({
        pathname: '/cable',
        search: `?css=${css}&sau=${sau}&RecoveryCable=${recovery_cables}&exchange=${exchange}&result=${"TRUE"}`,
      })
      localStorage.setItem('selectedPCP', null);
      localStorage.setItem('cablepage', 0);
      localStorage.setItem('cabinetpage', 0);
    }
  }
  return (
    <div>
      <Dialog
        open={assignopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ marginTop: "-400px" }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The selection has been assigned in a new job
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "50px", height: "30px", float: "right", fontSize: "12px" }}
            onClick={handleNavigate}
            data-testid="job-assigned-button"
            autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
} 