export const extractDigits = (first_name) => {
  const name = first_name?.split("[")[1];
  const digits = name.split("]")[0];
  return digits;
};

export const handlesecondEngineerChange = (choose,setFirstEnginner) => {
  const selectedValue = choose.value;
  setFirstEnginner(selectedValue);
  return selectedValue
};
// eslint-disable-next-line import/no-anonymous-default-export
export default { extractDigits, handlesecondEngineerChange }