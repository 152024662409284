import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadCrumbContext } from '../common/BreadCrumbContext';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { ACR, BASE_URL, HOST_CABLES, VIEW_JOB } from '../../constant/urls';
import { useMsal } from '@azure/msal-react';
 
export default function ViewDialog({ sau, css, RecoveryCable, exchange, job_id, job_owner, cable_id }) {
  const [open, setOpen] = useState(false);
  const [marked_as_onhold, setMarked_as_onhold] = useState(false);
  const [marked_as_unviable, setMarked_as_unviable] = useState(false);
  const [hostpopup, setHostpopup] = useState(false);
  const [hostmessage, setHostmessage] = useState("");
  const FIRST_NAME =JSON.parse(localStorage.getItem("FIRST_NAME"))
  const LAST_NAME =JSON.parse(localStorage.getItem("LAST_NAME"))
  const { setSelectedrow, setjobdetails, setJobdetailsdisable, setJobdetailstable, setjob_id } = useContext(BreadCrumbContext);
  const navigate = useNavigate();
  const { accounts } = useMsal()

   
   
  const params={
      logged_user: accounts[0]?.idTokenClaims.EIN
  }

  const handleClickOpen = () => {
    setOpen(true);
    const rows = [RecoveryCable, sau]
    setSelectedrow(rows);
    localStorage.setItem('selectedrow', JSON.stringify(rows));
    setjob_id(job_id);
    const jobdetails = {
      "job_id": job_id,
      "job_owner": job_owner,
    }
    setjobdetails(jobdetails)

    const hostcablesurl = `${BASE_URL}/${ACR}/${HOST_CABLES}?sau=${sau}&exchange=${exchange}&cable=${cable_id}`;
    axios.get(hostcablesurl,{params:params })
      .then((res) => {
        if (res.data.Status_code === 200 && res.data.status === "fail") {
           setHostpopup(false)
        }
        else if (res.data.Status_code === 200 && res.data.status === "Success") {
          setHostpopup(true)
          setHostmessage(res.data.MESSAGE)
        }
      })

    const url = `${BASE_URL}/${ACR}/${VIEW_JOB}?job_id=${job_id}`
    axios.get(url,{params:params })
      .then((res) => {
        setOpen(true);
        const username = (FIRST_NAME + "_" + LAST_NAME).toLowerCase();
        const name = res.data.Data.planner_name.toLowerCase();
        setJobdetailstable(res.data.Data);
        setMarked_as_onhold(res.data.Data.marked_as_onhold)
        setMarked_as_unviable(res.data.Data.marked_as_unviable)
        if (username === name) {
          setJobdetailsdisable(false)
        }
        else {
          setJobdetailsdisable(true)

        }
      })
    setOpen(false);
    localStorage.setItem('job_id', '')

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleviewjob = () => {
    const url = `${BASE_URL}/${ACR}/${VIEW_JOB}?job_id=${job_id}`
    axios.get(url,{params:params })
      .then((res) => {
        const username = (FIRST_NAME + "_" + LAST_NAME).toLowerCase();
        const name = res.data.Data.planner_name.toLowerCase();
        setJobdetailstable(res.data.Data);
        setMarked_as_onhold(res.data.Data.marked_as_onhold)
        setMarked_as_unviable(res.data.Data.marked_as_unviable)
        if (username === name) {
          setJobdetailsdisable(false)
        }
        else {
          setJobdetailsdisable(true)

        }
      })
    setOpen(false);
    localStorage.setItem('job_id', '')
  }

  const handleNavigate = () => {
    navigate({
      pathname: '/cable',
      search: `?css=${css}&sau=${sau}&RecoveryCable=${RecoveryCable}&exchange=${exchange}&result=${"TRUE"}`,
    })
    localStorage.setItem('selectedPCP', null);
    localStorage.setItem('cablepage', 0);
    localStorage.setItem('cabinetpage', 0);
  }

  const display = () => {
    if (marked_as_onhold === true) {
      return (
        <>
          {hostpopup ? <Dialog
            id="alert-dialog"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ marginTop: "-290px", width: "400px", marginLeft: "450px" }}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{ fontSize: "12px", }}>
                {hostmessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button id="alert-dialog-button-View-Job" data-testid="alert-dialog-button-View-Job" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "-20px 10px 9px", width: "80px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleviewjob}>View Job</Button>
            </DialogActions>
          </Dialog> :

            <Dialog
              id="alert-dialog"
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{ marginTop: "-290px", width: "400px", marginLeft: "450px" }}
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ fontSize: "12px", }}>
                  The cable have been assigned to a new job and has been marked as OnHold The offloading permutations for the cable will be enabled only after the status changes from OnHold to Resume.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button id="alert-dialog-button-View-Job" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "-20px 10px 9px", width: "80px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleviewjob}>View Job</Button>
              </DialogActions>
            </Dialog>}
        </>)

    }
    else if (marked_as_unviable === true) {
      return (<>
        {hostpopup ? <Dialog
          id="alert-dialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ marginTop: "-290px", width: "400px", marginLeft: "450px" }}
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ fontSize: "12px", }}>
              {hostmessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="alert-dialog-button-View-Job" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "-20px 10px 9px", width: "80px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleviewjob}>View Job</Button>
          </DialogActions>
        </Dialog> : <Dialog
          id="alert-dialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ marginTop: "-290px", width: "400px", marginLeft: "450px" }}
        >

          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ fontSize: "12px", }}>
              The cable have been assigned to a new job and has been marked as unviable. The offloading permutations for the cable will be enabled only after the status changes from unviable to viable            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="alert-dialog-button-View-Job" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "-20px 10px 9px", width: "80px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleviewjob}>View Job</Button>
          </DialogActions>
        </Dialog>}
      </>
      )
    }
    else if (marked_as_onhold === false && marked_as_unviable === false) {
      return (<>{
        hostpopup ? <Dialog
          id="alert-dialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ marginTop: "-300px", marginLeft: "450px", width: "500px" }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ marginBottom: "-20px" }}>
              <div id="message" dangerouslySetInnerHTML={{ __html: hostmessage }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button id="alert-dialog-button-View-Job" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleviewjob}>View Job</Button>
            <Button id="alert-dialog-button-Dismiss" data-testid="alert-dialog-button-Dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleClose}>Dismiss</Button>

          </DialogActions>
        </Dialog> : <Dialog
          id="alert-dialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ marginTop: "-400px" }}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Cable has already been assigned in a job?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="alert-dialog-button-proceed" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "190px", height: "30px", float: "right", fontSize: "12px" }}
              onClick={handleNavigate}
              autoFocus>
              Proceed To View Cable
            </Button>
            <Button id="alert-dialog-button-View-Job" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px" }} onClick={handleviewjob}>View Job</Button>
          </DialogActions>
        </Dialog>}</>)
    }

  }

  return (
    <div>
      <p style={{ textDecoration: "underline", color: "blue", padding: "0 1px", margin: 0, cursor: "pointer" }}
      data-testid="view-cable" id={`view-cable-${cable_id}`}  onClick={handleClickOpen}>View cable</p>
      {display()}
    </div>
  );
}
