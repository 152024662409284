import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Switch from '@mui/material/Switch';
import { HCRPATH } from '../../constant/urls';

/**
 *
 *
 * @param {*} props
 * @return {*} 
 */
function ToggleSwitch(props) {
    const [checked, setChecked] = React.useState(true);
    const handleChange = (event) => {
        setChecked(event.target.checked);
        updateSideBar(event.target.checked);
    };

    const updateSideBar = (upadtedVal) => {
        props.switchDataTransfer(upadtedVal)
    }
    /** location variable*/
    const location = useLocation();

    /** hold pathname list splitted with /*/
    const pathnames = location.pathname.split("/").filter((x) => x);

    useEffect(() => {
        // eslint--next-line react-hooks/exhaustive-deps
        if ((pathnames.some(val => { return (HCRPATH.includes(val)) }))) {

            setChecked(false);
            props.setAcrValue(false);
        }
        else if (pathnames.includes("hcr")) {
            setChecked(true);
            props.setAcrValue(true);
        }
        // eslint-disable-next-line
    }, [pathnames])

    return (
        <Switch
            inputProps={{ "data-testid": `toggle-switch`, "id": `toggle-switch` }}
            checked={checked}
            onChange={handleChange}
        />
    );
}

export default ToggleSwitch


