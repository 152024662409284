import AcrLayout from '../layouts/AcrLayout';
import HcrLayout from '../layouts/HcrLayout'
import Home from '../components/Home/Home'
import ExchangeView from '../components/Exchange-View/ExchangeView';
import HcrHome from '../components/HCR-Home/HcrHome';
import HcrWorkstack from '../components/HCR-Workstack/HcrWorkstack';
import HcrReport from '../components/HCR-Report/HcrReport';
import HcrAssignUnassign from '../components/HCR-AssignUnassign/HcrAssignUnassign';
import HcrPlanning from '../components/HCR-Planning/HcrPlanning';
import Viewcable from '../components/View-Cable/Viewcable';
import JumperScheduleWrapper from '../components/Jumper-Schedule/JumperScheduleWrapper';
import MyJobModal from '../components/Data-Freeze/MyJobModal';
import AdminReport from '../components/Admin-Report/AdminReport';
import HcrPlanningCable from '../components/HCR-Planning/HcrPlanningCable'
import HcrPcpDetails from '../components/HCR-Planning/HcrPcpDetails'
import { ENGINEER, ENGINEER_MANAGER, PLANNER } from './userConfig';
import NoAccess from '../components/common/NoAccess';
 
export const mainRoutes = (ROLE)=>{
    
   return [{
        element: ROLE === ENGINEER || ROLE === ENGINEER_MANAGER ? null : <AcrLayout />,
        children: [
            {
                path: "/home",
                element: <Home />
            },
            {
                path: '/myjobs',
                element: <MyJobModal />
            },
            {
                path: "/exchange",
                element: <ExchangeView />
            },
            {
                path: "/cable",
                element: <Viewcable />,
            },
            {
                path: "/jumper_schedule",
                element: <JumperScheduleWrapper />,
            },
            {
                path: "/admin_report",
                element: <AdminReport />,
            },
        ]
    },
    {
        element: <HcrLayout />,
        children: [
            {
                index: true,
                path: ROLE === ENGINEER || ROLE === ENGINEER_MANAGER ? "/home" : "/hcr",
                element: <HcrHome />
            },
            {
                path: "/hcrworkstack",
                element: ROLE === PLANNER ? <NoAccess /> : <HcrWorkstack />
            },
            {
                path: "/hcrreporting",
                element: ROLE === ENGINEER || ROLE === PLANNER ? <NoAccess /> : <HcrReport />
            },
            {
                path: "/hcrassignunassign",
                element: ROLE === ENGINEER || ROLE === PLANNER ? <NoAccess /> : <HcrAssignUnassign />
            },
            {
                path: "/hcrplanning",
                element: ROLE === ENGINEER  ? <NoAccess /> : <HcrPlanning />
            },
            {
                path: "/planningcable",
                element: ROLE === ENGINEER  ? <NoAccess /> : <HcrPlanningCable />
            },
            {
                path: "/hcrpcp",
                element: ROLE === ENGINEER ?<NoAccess />:<HcrPcpDetails />
            }
        ]
    }]
};

export default mainRoutes;

