import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AppsIcon from '@mui/icons-material/Apps';
import WorkIcon from '@mui/icons-material/Work';

const SidebarList = [
    {
        'id': 1,
        'name': 'Openreach',
        'icon': '',
        'subMenu': []

    },
    {
        'id': 2,
        'name': 'Admin',
        'icon': <PersonIcon />,
        'subMenu': []
    },
    {
        'id': 3,
        'name': 'Dashboard',
        'icon': <DashboardIcon />,
        'subMenu': []
    },
    {
        'id': 4,
        'name': 'Apps',
        'icon': <AppsIcon />,
        'subMenu': []
    },
    {
        'id': 5,
        'name': 'Overview',
        'icon': <SummarizeIcon />,
        'subMenu': [{
            'id': 5_01,
            'name': 'Senario Overview',
            'icon': <EventNoteIcon />,
        },
        {
            'id': 5_02,
            'name': 'Hazards Overview',
            'icon': <WarningAmberIcon />,
        },
        ]
    },

    {
        'id': 6,
        'path': '/myjobs',
        'name': 'My Jobs',
        'icon': <WorkIcon />,
        'subMenu': []
    }

]

export default SidebarList
