import React, { useState, useMemo } from 'react'
import { AllContext, } from '../components/common/BreadCrumbContext';
import { useMsal } from "@azure/msal-react";
import { graphAPI } from '../layouts/AppContext.util';
import { useLocation } from 'react-router-dom';

function AppContext(props) {
    const { instance, accounts } = useMsal();
    const [isAcr, setIsAcr] = React.useState(true);
    const [sidebar, setSidebar] = useState([]);
    const [userData, setUserData] = useState({
        "USER_EIN": null,
        "FIRST_NAME": null,
        "LAST_NAME": null,
        "EMAIL_ID": null,
        "ROLE": null,
        "ID_TOKEN": null
    })
    const location = useLocation();
    const callgraphAPI = async () => {
      await graphAPI(instance,accounts,setUserData, setSidebar, setIsAcr, location)
    }



    const contextValue = useMemo(() => ({
        userData, setUserData, sidebar, setSidebar, isAcr, setIsAcr, callgraphAPI
    }), [userData, setUserData, sidebar, setSidebar, isAcr, setIsAcr, callgraphAPI])
    return (
        <AllContext.Provider value={contextValue} data-testid="context">
            {props.children}
        </AllContext.Provider>
    )
}

export default AppContext
