import SidebarList from "../constant/acr-sidebar";
import { ADMIN, ENGINEER, ENGINEER_MANAGER, PLANNER } from "../constant/userConfig";
import SidebarListHcr from '../constant/hcr-sidebar';

export const rolesValidation = (role, setSidebar, setIsAcr, location) => {
    if (role === ENGINEER || role === ENGINEER_MANAGER) {
        setSidebar(SidebarListHcr(role));
        setIsAcr(false)
    }

    if (role === PLANNER) {
        setSidebar(SidebarList);
        setIsAcr(true)
    }

    if (role === ADMIN) {
        setSidebar(SidebarList);
        setIsAcr(true)
    }

    if (location.pathname === "/hcr" || location.pathname === "/planningcable" || location.pathname === "/hcrplanning" || location.pathname === "/hcrreporting" || location.pathname === "/hcrassignunassign" || location.pathname === "/hcrworkstack" || location.pathname === "/hcrpcp") {
        setSidebar(SidebarListHcr(role));
        setIsAcr(false)
    }
    if (location.pathname === "/home" && (role === PLANNER || role === ADMIN)) {
        setSidebar(SidebarList)
    }
    return "APP21744_NOS_IntTest_Planner"
}

export const accesstoken = (res, setUserData, setSidebar, setIsAcr, location) => {
    if (res.accessToken) {
        localStorage.setItem("USER_EIN", JSON.stringify(res.account.idTokenClaims.EIN));
        localStorage.setItem("FIRST_NAME", JSON.stringify(res.account.idTokenClaims.given_name))
        localStorage.setItem("LAST_NAME", JSON.stringify(res.account.idTokenClaims.family_name))
        localStorage.setItem("EMAIL_ID", JSON.stringify(res.account.idTokenClaims.email))
        localStorage.setItem("ROLE", JSON.stringify(res.account.idTokenClaims.groups[0]))
        const account = res.account.idTokenClaims
        setUserData({
            "USER_EIN": account.EIN,
            "FIRST_NAME": account.given_name,
            "LAST_NAME": account.family_name,
            "EMAIL_ID": account.email,
            "ROLE": account.groups[0],
        })
        const role = res.account.idTokenClaims.groups[0];
        rolesValidation(role, setSidebar, setIsAcr, location)

    }
    return "APP21744_NOS_IntTest_Engineer"
}

export const graphAPI = async (instance, accounts, setUserData, setSidebar, setIsAcr, location) => {
    try {
        const res = await instance.acquireTokenSilent({
            account: accounts[0],
        })
        accesstoken(res, setUserData, setSidebar, setIsAcr, location)
    }
    catch (error) {
        console.error('error calling msgraph api', error)
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { rolesValidation, accesstoken, graphAPI }