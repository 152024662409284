export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
export const requestSearch = (event, tabledataCopy, setTabledata) => {
  let searchedVal = event?.target?.value;
  const filteredRows = tabledataCopy.filter((row) => {
    return (row.ouc.toLowerCase().includes(searchedVal.toLowerCase()));
  });
  setTabledata(filteredRows);
  return null

};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { descendingComparator, requestSearch, getComparator }