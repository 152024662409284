import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useNavigate, useLocation, Link } from "react-router-dom";
import ToggleSwitch from '../common/ToggleSwitch'

import { Button, Modal, Table, TableBody, TableCell, TableContainer, Paper, TableRow, TableHead, Backdrop, CircularProgress } from '@mui/material';
import ScenarioImage from './images/img1.png'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ADMIN, PLANNER } from '../../constant/userConfig';
import Signout from '../Authentication/Signout';
import { AllContext } from '../common/BreadCrumbContext';
import {
    switchDataTransfer, handleclickopenreach, handleClick,
    handlebackdropClose, setAcrValue, handleDrawerOpen,
    handleDrawerClose, handleClose,handleSelectedItemColor,
    acr
} from './sidebar.util.js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "1082px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    margin: "2px",
    backgroundImage: `url(${ScenarioImage})`,
    height: "auto",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginTop: 2
};

const styleHazard = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: "450px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    margin: "2px",
};

const drawerWidth = 216;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal()
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const location = useLocation()
    const [selectedItem, setSelectedItem] = useState(null);
    /**
     *  Toggle switch 
     */
    const { userData, sidebar, setSidebar, isAcr, setIsAcr, loading, callgraphAPI } = React.useContext(AllContext);


    const [backdropopen, setbackdropOpen] = useState(true);

    useEffect(() => {
        if (accounts.length) {
            callgraphAPI();
        }
    }, [])

    /** For communication with parent component to pass  open or close status of drawer */
    const updateParent = (status) => {
        props.sendData(status)
    }

    const [openSub, setOpenSub] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [scenario, setScenario] = useState(false);

    const columns = ['Hazard_Grade', 'Hazard_Code', 'Hazard_Type', 'Hazard_Description']
    const data = [
        { id: 1, Hazard_Grade: '1', Hazard_Code: 'H1', Hazard_Type: 'PCP_To_Cable', Hazard_Description: 'Conflict in data Investigation required' },
        { id: 2, Hazard_Grade: '2', Hazard_Code: 'H2', Hazard_Type: 'PCP_To_Cable', Hazard_Description: 'Hybrid Cable' },
        { id: 3, Hazard_Grade: '3', Hazard_Code: 'H3', Hazard_Type: 'PCP_To_Cable', Hazard_Description: 'PCP Linked but no CSS Working terms' },
        { id: 4, Hazard_Grade: '3', Hazard_Code: 'H4', Hazard_Type: 'PCP_To_Cable', Hazard_Description: 'PCP Linked via NEJ but no CSS terms' },
        { id: 5, Hazard_Grade: '1', Hazard_Code: 'H5', Hazard_Type: 'NEJ_To_PCP', Hazard_Description: 'NEJ plant not connected to CSS_PCP' },
        { id: 6, Hazard_Grade: '3', Hazard_Code: 'H6', Hazard_Type: 'PCP', Hazard_Description: 'NEJ Linked via CSS but no cable recorded' },
        { id: 7, Hazard_Grade: '1', Hazard_Code: 'H7', Hazard_Type: 'Splice_Node', Hazard_Description: 'Missing_Splice_Point_Cable_Incomplete' },
        { id: 8, Hazard_Grade: '3', Hazard_Code: 'H8', Hazard_Type: 'DP_To_Cable', Hazard_Description: "DP CSS terms can't link to NEJ cable" },
        { id: 9, Hazard_Grade: '3', Hazard_Code: 'H8', Hazard_Type: 'PCP_To_Cable', Hazard_Description: "PCP CSS terms can't link to NEJ cable" },

    ];

    return (
        <Box sx={{ display: 'flex' }}>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdropopen}
                        onClick={() => handlebackdropClose(setbackdropOpen)}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{ display: 'flex', background: "#142032", ml: isAuthenticated ? null : -28 }}>
                    <IconButton
                        data-testid="drawer-open"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={()=>handleDrawerOpen(setOpen, updateParent)}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography sx={{ fontSize: "17px" }} noWrap component="div">
                        Network Optimization Services (NOS)
                    </Typography>
                    <Box sx={{ width: '68%', ml: '21px' }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                        >
                            {isAuthenticated && (userData.ROLE === ADMIN || userData.ROLE === PLANNER) ?
                                <Grid item>
                                    < Stack direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={0}>
                                        <div id="ToggleSwitch_hcr" style={{ fontSize: "17px" }}>HCR</div>
                                        <ToggleSwitch switchDataTransfer={(data) => switchDataTransfer(data, setSelectedItem, setIsAcr, navigate, setSidebar)} setAcrValue={(data) => setAcrValue(data, setIsAcr)} />
                                        <div id="ToggleSwitch_acr" style={{ fontSize: "17px" }}>ACR</div>
                                    </Stack>
                                </Grid> : null}

                            {isAuthenticated ? <Grid item alignItems="center">
                                <Typography sx={{ fontSize: "22px", ml: 2, mb: '4%' }} noWrap component="div">
                                    openreach
                                </Typography>
                            </Grid> : null}

                            {isAuthenticated ? <Grid item alignItems="center">
                                <Signout onsignout={props.onsignout} />
                            </Grid> : null}
                        </Grid>

                    </Box>
                </Toolbar>
            </AppBar>
            {
                isAuthenticated ? <> <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader sx={{ backgroundColor: "#142032" }}>
                        <IconButton onClick={() => { handleDrawerClose(setOpen, updateParent) }} sx={{ color: "white" }}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />

                    <List sx={{
                        height: "100%", width: '100%', maxWidth: 360, color: "white", backgroundColor: "#142032"
                    }}>
                        {sidebar.map((value) => (
                            (value.subMenu?.length > 0 ? (<>
                                <ListItem key={value.id} disablePadding>
                                    <ListItemButton onClick={() => handleClick(value.name, setOpenSub)} data-testid={`list-item-${value.name}`}>
                                        <ListItemIcon sx={{ color: "white" }}>
                                            <SummarizeIcon />
                                        </ListItemIcon>
                                        <ListItemText id={value.name + "-button"} sx={{ fontSize: 9 }}>
                                            <Typography sx={{ fontSize: "14px" }}>
                                                {value.name}
                                            </Typography>
                                        </ListItemText>
                                        {openSub[value.name] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </ListItem>
                                <Collapse in={openSub[value.name]} timeout="auto" unmountOnExit>
                                    <List>
                                        {value.subMenu.map((subvalue) => (
                                            <ListItem key={subvalue.id} disablePadding>

                                                <ListItemButton sx={{ pl: 3 }} onClick={() => handleclickopenreach(subvalue.name, setSelectedItem, navigate, userData, setShowPopup, setScenario, location)} style={{ backgroundColor: handleSelectedItemColor(subvalue,selectedItem) }}>
                                                    <ListItemIcon sx={{ color: "white" }}>
                                                        {subvalue.icon}
                                                    </ListItemIcon>
                                                    <ListItemText id={subvalue.name + "-button-"} sx={{ fontSize: 9 }}>
                                                        <Typography sx={{ fontSize: "11px" }}>
                                                            {subvalue.name}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>)
                                        )}
                                    </List>
                                </Collapse>
                            </>) : acr(value,isAcr,setSelectedItem, navigate, userData, setShowPopup, setScenario, location,Link,selectedItem))
                        ))}
                    </List>
                    <Divider />
                </Drawer>

                    <Modal
                        id="view-user-modal"
                        open={showPopup}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ marginLeft: "80px", marginTop: "0px" }}
                    >
                        {scenario ? (
                            <Box sx={style} >
                                <div style={{ display: "flex", flexDirection: 'row-reverse', marginTop: "12px" }}>
                                    <Button id="scenario-modal-dismiss" style={{ color: "white", backgroundColor: "#142032", marginTop: "475px", marginRight: "20px", width: "100px", height: "30px", float: "right" }} onClick={()=>handleClose(setShowPopup)}>Dismiss</Button>
                                </div>
                            </Box>) : (
                            <Box sx={styleHazard} >
                                <p id="hazards_overview" style={{ margin: 0, fontSize: "20px", textAlign: "center", fontWeight: "bold" }}>Copper Recovery - Hazards Overview</p>
                                <TableContainer id="hazard-TableContainer" component={Paper} sx={{ position: "relative", marginTop: "15px" }}>
                                    <Table id="hazard-modal-Table">
                                        <TableHead id="hazard-modal-TableHead" sx={{ top: 0, zIndex: 1 }}>
                                            <TableRow id="hazard-modal-TableRow-head" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                                                {columns.map((headcell) => (
                                                    <TableCell id="hazard-modal-TableCell" key={headcell} align='center' sx={{ color: "white", margin: 0, padding: "5px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px", fontWeight: "800px" }}>
                                                        {headcell}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {data.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "6px" }} >
                                                        {row.Hazard_Grade}
                                                    </TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "6px" }} >
                                                        {row.Hazard_Code}
                                                    </TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "6px" }}>
                                                        {row.Hazard_Type}
                                                    </TableCell>
                                                    <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "6px" }}>
                                                        {row.Hazard_Description}
                                                    </TableCell>

                                                </TableRow>
                                            ))}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div style={{ display: "flex", flexDirection: 'row-reverse', marginTop: "12px" }}>
                                    <Button id="hazard-modal-dismiss" style={{ color: "white", backgroundColor: "#142032", marginTop: "11px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} onClick={()=>handleClose(setShowPopup)}>Dismiss</Button>
                                </div>
                            </Box>)}
                    </Modal>
                </> : null
            }

        </Box >
    );

}