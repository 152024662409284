import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

export default function ViewDialog({ jumper, getJumperSchedule, jobId ,onDownload}) {
   
  /** Dialog box implementation  */

  const [open, setOpen] = useState(false);
  
  const handleClickOpen = () => {
    getJumperSchedule(jumper, jobId);

    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <p style={{ textDecoration: "underline", color: "blue", padding: "0 1px", margin: 0, cursor: "pointer" }} data-testid="download-jumper"
        onClick={handleClickOpen}>{jumper}</p>
      <Dialog
        id="alert-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ marginTop: "-400px" }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Download the Jumper Schedule file here
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="alert-dialog-button-proceed" style={{ color: "white", backgroundColor: "#142032", margin: "0px 10px 9px", width: "190px", height: "30px", float: "right", fontSize: "12px" }} data-testid="jumper-download"
              onClick={() => {
                onDownload(jumper, jobId);
                setOpen(false);
              }}
            autoFocus>
            Download
          </Button>
          <Button id="alert-dialog-button-dismiss" style={{ color: "white", backgroundColor: "#142032", margin: "0px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px" }} data-testid="alert-dialog-button-dismiss" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}