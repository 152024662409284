export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
/** Search */
export const requestSearch = (event, setSearch, tabledataCopy, setTabledata) => {
  let searchedVal = event?.target?.value;
  setSearch(searchedVal);
  const filteredRows = tabledataCopy.filter((row) => {
    return (row.exch_sau.toLowerCase().includes(searchedVal.toLowerCase()) || row.exchange.toLowerCase().includes(searchedVal.toLowerCase()));
  });
  setTabledata(filteredRows);
};

export const handlecolor = (row, exchsau) => {
  if (row.exchange === exchsau) {
    return "rgb(222, 184, 135)"
  }
  else {
    return null
  }
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { descendingComparator, requestSearch,getComparator }