export const handleEditData = (editedData, dataforSelectedtab, selectedrow, tableData, selectedIndex, tabNames, setTableData) => {
    const currentTabdata = dataforSelectedtab;
    const updatedData = currentTabdata.map((item) => {
        if (item.Circuit === selectedrow?.Circuit && item.From_MDF_No === selectedrow?.From_MDF_No && item?.To_MDF_Pair === selectedrow?.To_MDF_Pair) {
            return { ...item, ...editedData }
        }
        else if (item?.To_MDF_Pair === parseInt(editedData?.To_MDF_Pair) && item?.To_MDF_Bar === editedData?.To_MDF_Bar) {
            return { ...item, To_MDF_Pair: '____', New_E_Side: '____', To_Status: '____' }
        }
        else if (item?.D_side === parseInt(editedData?.D_side)) {
            return { ...item, D_side: '____' }
        }
        else if (item?.Recovery_e_side === parseInt(editedData?.Recovery_e_side)) {
            return { ...item, Recovery_e_side: '____' }
        }
        return item
    })
    const updatedTabData = [...tableData];
    updatedTabData[selectedIndex][tabNames[selectedIndex]] = updatedData;
    setTableData(updatedTabData);
    return null
}
 
// eslint-disable-next-line import/no-anonymous-default-export
export default { handleEditData }