// handleing the wfmt button disable functionality
export const handleWFMTdisable = (WFMTData) => {

    const status = WFMTData ? WFMTData[0]?.status : null;
    if (status === "Success") {
        return true
    }
    else if(status===null){
        return false;
    }
    if (status === "Fail") {
        return false
    }
}

export default handleWFMTdisable