import React, { useState } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Sidebar from '../components/Sidebar/Sidebar';
import BaseRouter from '../routes/BaseRouter';
import './App.css';
import { useIsAuthenticated, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import SignIn from '../components/Authentication/SignIn';
import AppContext from '../layouts/AppContext';
import AxiosInterceptors from '../components/Authentication/axiosInterceptors';
import IdleTimer from 'react-idle-timer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
 

const drawerWidth = 200;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);
function App() {
  const { instance } = useMsal();
  const [open, setData] = useState(true);
const [dialogopen, setDialogopen] = useState(false)
  const sendData = (data) => {
    setData(data)
  }

  const isAuthenticated = useIsAuthenticated();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
    setDialogopen(false);
    localStorage.clear()
  }
  
  const handleIdle =()=>{
     setDialogopen(true)
    
  }
  const handleClose = () => {
    handleLogout()
  };
  
  return (
    <>
      <Router>
        <AppContext>
          <Box sx={{ display: 'flex', mt: 5 }}>
            {isAuthenticated ?
              <AuthenticatedTemplate>
                <IdleTimer onIdle={handleIdle} timeout={1000 * 60 * 30} />
                <AxiosInterceptors/>
                <Sidebar sendData={sendData} onsignout={handleLogout} />
                <Main open={open}>
                  <BaseRouter />
                </Main>
              </AuthenticatedTemplate> :
              <UnauthenticatedTemplate>
                <Sidebar sendData={sendData} onsignout={handleLogout} />
                <SignIn />
              </UnauthenticatedTemplate>}
          </Box>
        </AppContext>
      </Router>
      <Dialog
        open={dialogopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Your session has expired. Please login again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default App;