import React, { useEffect } from "react";
import {
    Alert, Snackbar, Link, Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button, CircularProgress, Backdrop
} from "@mui/material";
import "./HcrPlanning.css";
import { view_pcp } from './HcrPlanningConst';
import { BASE_URL, DETAILS, HCR, DOWNLOAD_JS_REPORT } from '../../constant/urls';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { useMsal } from "@azure/msal-react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function HcrPcpDetails() {
    const { accounts } = useMsal()

    /** assigining searchparams */
    const [searchParams] = useSearchParams();
    const job_id = (searchParams.get('job_id'));
    const pcp = (searchParams.get('pcp'));
    const planner_name = (searchParams.get('planner_name'))
    const post_code = (searchParams.get('post_code'))
    const fn = (searchParams.get('fn'))
    const [showLink, setShowLink] = React.useState(false);
     
     

    /** Table data */
    const [tabledata, setTabledata] = React.useState([]);
    const tableHeader = view_pcp;

    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
    const handleClose = () => {
        setAlertOpen(false);
    };
    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true)
    const [jumperResp, setJumperResp] = React.useState(null);
    useEffect(() => {
        getPcpDetails();
    }, [])

    const getPcpDetails = () => {
        let params = {
            "job_id": job_id,
            "pcp": pcp,
            is_download: 'False',
            logged_user: accounts[0]?.idTokenClaims.EIN
        }
        setLoading(true);
        setOpen(true);
        axios.get(`${BASE_URL}${HCR}/${DETAILS}`, {params: params  }).then((response) => {
            if (response?.data?.results) {
                const resposeResults = response?.data?.results.map(item => ({
                    ...item,
                    post_code,
                    planner_name
                }))
                setTabledata(resposeResults);
                setLoading(false);
                setOpen(false);
            }
        }).catch((error) => {
            setLoading(false);
            setOpen(false);
            setSnackMsg('Something went wrong!.Please try again!');
            setAlertOpen(true);
        });
    }

    /**
            pcp details code for prod env
    */

    const getJumperSchedule = (filename) => {

        const jumperurl = `${BASE_URL}${HCR}/${DOWNLOAD_JS_REPORT}`;
        const params = {
            file_name: fn,
            job_id: job_id,
            logged_user: accounts[0]?.idTokenClaims.EIN
        }
        setLoading(true);
        axios.patch(jumperurl, params, )
            .then((response) => {
                if (response.data) {
                    let url = response.data['presigned_url'];
                    setJumperResp(url);
                    setShowLink(true);
                    setLoading(false);
                }
            }).catch((error) => {
                setAlertOpen(true);
                console.error(error);
                setLoading(false);
                setSnackMsg('Something went wrong!.Please try again!');

            });
    }

    const onDownload = (filename) => {
        setLoading(true);
        axios.get(jumperResp, { responseType: 'blob' }).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fn);
                link.click();
                setLoading(false);
            }
        }).catch((error) => {
            setAlertOpen(true);
            setLoading(false);
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
        });

    };

    const handleDownload = () => {
        getJumperSchedule()
    };


    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>

            <Box sx={{ mt: 5 }}>


                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            {
                                tableHeader?.map((row) => (
                                    <StyledTableRow key={row['label']} className="pcpTable">
                                        <StyledTableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "4px", paddingLeft: "14px", width: "300px" }} align="left">{row['label']}</StyledTableCell >
                                        <StyledTableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "4px", paddingLeft: "14px" }} align="left">{row['label'] === "Length" ? tabledata[0]?.[row['id']] + "Kb" : tabledata[0]?.[row['id']]}</StyledTableCell >
                                    </StyledTableRow >
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>


                <Button id="alert-dialog-button-proceed" style={{ color: "white", backgroundColor: "#142032", margin: "13px 10px 9px", width: "190px", height: "30px", float: "right", fontSize: "12px" }}
                    onClick={handleDownload}>Download</Button>

                {showLink &&
                    <div style={{ marginTop: '16px' }} >
                        <Link
                            id="download-pcp-Report"
                            variant="body2"
                            underline='hover'
                            onClick={onDownload}
                            data-testid="download-pcp-Report"
                            sx={{ fontSize: 16, color: 'red', marginLeft: "521px", '&:hover': { cursor: 'pointer' } }}
                        >
                            Click here to download the Jumper Schedule
                        </Link>
                    </div>

                }

            </Box>



        </>
    )
}

export default HcrPcpDetails
