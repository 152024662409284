import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import SidebarList from '../../constant/acr-sidebar'
import SidebarListHcr from '../../constant/hcr-sidebar';
import { ADMIN } from '../../constant/userConfig';
import {  Link } from "react-router-dom";

export const switchDataTransfer = (data, setSelectedItem, setIsAcr, navigate, setSidebar) => {
    setSelectedItem(null)
    setIsAcr(data);
    if (data) {
        navigate("/home");
        setSidebar(SidebarList)
    } else {
        navigate("/hcr");
        setSidebar(SidebarListHcr);
    }
    return data
}

export const handleclickopenreach = (value, setSelectedItem, navigate, userData, setShowPopup, setScenario, location) => {

    const filterSidebar = value === "Openreach" ? null : value

    setSelectedItem(filterSidebar);
    if (value === "My Jobs") {
        navigate("/myjobs");
    }
    else if (value === "Admin" && (userData?.ROLE) === ADMIN) {
        navigate("/admin_report");
    }
    else if (value === "Senario Overview") {
        setShowPopup(true);
        setScenario(true)
    }
    else if (value === "Hazards Overview") {
        setShowPopup(true);
        setScenario(false)
    }
    else if (value === "Openreach" && location.pathname === "/myjobs") {
        navigate(-1);
    }
    else if (value === "Openreach" && location.pathname === "/admin_report") {
        navigate(-1);
    }
    return "Openreach"
}

export const handleClick = (item, setOpenSub) => {
    setOpenSub(prevState => (
        { [item]: !prevState[item] }
    ))
    return "Overview"
};

export const handlebackdropClose = (setbackdropOpen) => {
    setbackdropOpen(false);
    return false
}

export const setAcrValue = (data, setIsAcr) => {
    setIsAcr(data);
    return true
}

export const handleDrawerOpen = (setOpen, updateParent) => {
    setOpen(true);
    updateParent(true);
    return true
};
export const handleDrawerClose = (setOpen, updateParent) => {
    setOpen(false);
    updateParent(false);
    return false
};

export const handleClose = (setShowPopup) => {
    setShowPopup(false);
    return false
};

export const handleSelectedItemColor = (subvalue, selectedItem,) => {
    if (selectedItem === subvalue.name) { return '#8e9bad' } else { return '#142032' }
}

// to list item based on hcr and acr
export const acr = (value,isAcr,setSelectedItem, navigate, userData, setShowPopup, setScenario, location,selectedItem) => {
    if (isAcr) {
        return <ListItem key={value.id} disablePadding>
            <ListItemButton onClick={() => handleclickopenreach(value.name, setSelectedItem, navigate, userData, setShowPopup, setScenario, location)} style={{ backgroundColor: handleSelectedItemColor(value,selectedItem) }} id={`list-button-${value.name}`} data-testid={`list-button-${value.name}`}>
                <ListItemIcon sx={{ color: "white" }}>
                    {value.icon}
                </ListItemIcon>
                <ListItemText sx={{ fontSize: 9, color: "white" }}> <Typography sx={{ color: "white", fontSize: "14px" }} >
                    {value.name}
                </Typography></ListItemText>
            </ListItemButton>
        </ListItem>
    }
    else {
        return <ListItem key={value.id} component={Link} to={value.path} disablePadding>
            <ListItemButton onClick={() => handleclickopenreach(value.name, setSelectedItem, navigate, userData, setShowPopup, setScenario, location)} style={{ backgroundColor: handleSelectedItemColor(value,selectedItem) }} id={`list-button-${value.name}`}>
                <ListItemIcon sx={{ color: "white" }}>
                    {value.icon}
                </ListItemIcon>
                <ListItemText sx={{ fontSize: 9, color: "white" }}> <Typography sx={{ color: "white", fontSize: "14px" }}>
                    {value.name}
                </Typography></ListItemText>
            </ListItemButton>
        </ListItem>
    }
}


 // eslint-disable-next-line import/no-anonymous-default-export
 export default  {
    switchDataTransfer, handleclickopenreach, handleClick,
    handlebackdropClose, setAcrValue, handleDrawerOpen,
    handleDrawerClose,handleClose,handleSelectedItemColor, acr
}