// column sort based on the column header
export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  export const getComparator = (order, orderBy) => {
      return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy)
  }
  // eslint-disable-next-line import/no-anonymous-default-export
  export default {descendingComparator,getComparator}