import { useState,useEffect } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { Box, Button, Modal, Link, Paper, TextField, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { ACR, BASE_URL, EXCHANGE_JOB_OWNERS } from '../../constant/urls';
import { useMsal } from '@azure/msal-react';
import Tablepagination from '../common/Tablepagination';
import { count } from './ViewUser.util';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    margin: "2px",
};

export default function ViewUser({ sau, exchange }) {
    const columns = ['Planner EIN', 'First Name', 'Last Name', 'Email Id']
    const [userTable, setUserTable] = useState([]);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filteredRows, setFilteredRows] = useState([]);
    const { accounts } = useMsal()
     
    // to pass the logger user params for api
    const params={
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    // filtering the table based on the search value
    useEffect(() => {
        const filteredRow = userTable.filter((row) => {
            return (
                `${row.ein}${row.first_name}${row.last_name}${row.email_id}`.toLowerCase().includes(search.toLowerCase())
            )
        })
        setFilteredRows(filteredRow)
    }, [userTable, search]);

    // axios call to get selected exchange cable job owner
    const handleOpen = () => {
        setOpen(true);
        const userurl = `${BASE_URL}/${ACR}/${EXCHANGE_JOB_OWNERS}?sau=${sau}&exchange=${exchange}`
        axios.get(userurl,{params:params })
            .then((res) => {
                setUserTable(res.data.Data)
            })
            .catch((error) => {
                console.error(error);
            })
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <div>
            <Link sx={{ fontSize: "14px", padding: "2px",cursor:"pointer" }} onClick={handleOpen}
                data-testid="userDetails-link"
                variant="body2" id={`view-user-${sau}-${exchange}`}>View User Details</Link>
            <Modal
                id="view-user-modal"
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ marginLeft: "80px", marginTop: "20px" }}
            >
                <Box sx={style} >
                    <p id="hazards" style={{ margin: 0, fontSize: "20px", textAlign: "center", fontWeight: "bold" }}>User Details</p>
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row', marginBottom: "10px", marginTop: "-8px" }}>
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <span id="warnings-rowsperpage" style={{ fontSize: "14px" }}>Rows per Page</span>
                            <select style={{ margin: "0 9px", fontSize: "14px" }} value={rowsPerPage} data-testid="warnings-rowsperpage" onChange={handleChangeRowsPerPage}>
                                {[5, 10, 25, 50, 100].map((option) => (
                                    <option id={"warnings-rowsperpage" + option} key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                        <TextField
                            id="input-with-icon-textfield"
                            label="Search"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            value={search}
                            onChange={(e) => { setSearch(e.target.value); setPage(0); }}
                        />

                    </div>
                    <TableContainer id="view-user-modal-TableContainer" component={Paper} sx={{ position: "relative", maxHeight: "260px" }}>
                        <Table id="view-user-modal-Table">
                            <TableHead id="view-user-modal-TableHead" sx={{ top: 0, position: "sticky", zIndex: 1 }}>
                                <TableRow id="view-user-modal-TableRow-head" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                                    {columns.map((headcell) => (
                                        <TableCell id="view-user-modal-TableCell" key={headcell} align='center' sx={{ color: "white", margin: 0, padding: "4px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px" }}>
                                            {headcell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    !filteredRows ? (userTable) : (filteredRows).map((row) => (
                                        <TableRow key={row.ein} data-testid="table-row">
                                            <TableCell id={"view-user-modal-tablecell"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px", width: "200px" }} align='center'>{row.ein}</TableCell>
                                            <TableCell id={"view-user-modal-tablecell"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.first_name}</TableCell>
                                            <TableCell id={"view-user-modal-tablecell"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.last_name}</TableCell>
                                            <TableCell id={"view-user-modal-tablecell"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.email_id}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                     <Tablepagination
                        setPage={setPage}
                        page={page}
                        count={count(filteredRows, userTable)}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        rowsPerPage={rowsPerPage}
                    />
                    <div style={{ display: "flex", flexDirection: 'row-reverse', marginTop: "12px" }}>
                        <Button id="view-user-modal-dismiss" data-testid="view-user-modal-dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "10px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} onClick={handleClose}>Dismiss</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
} 