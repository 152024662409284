import React from 'react';
import background from "./Loginimage.png";
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "./authConfig";
import { useNavigate } from 'react-router-dom';

const SignIn = ({ loginrequest, onloginsucess }) => {
    const { instance } = useMsal();
    const navigate = useNavigate()

    // onclick signin 
    const handleSignIn = async () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });
        navigate("/home")

    }

    // adding style for signin button
    const myStyle = {
        backgroundImage: `url(${background})`,
        height: "93.3vh",
        width: "204vh",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundColor: "grey",
        backgroundRepeat: "no-repeat",
        marginLeft: 8
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={myStyle}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleSignIn}
                    data-testid="signIn-Icon"
                    color="inherit"
                    sx={{ float: "right", position: "relative", top: 85, right: 75, zIndex: 1, margin: "8px 12px" }}
                >
                    <AccountCircle sx={{ width: "3em", height: "3em", color: "white", border: "4px solid #c3c3c3", borderRadius: "50px" }} />
                </IconButton>
            </div>
        </div>
    )
}

export default SignIn
