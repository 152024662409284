const headCells = [
    {
        id: 'Circuit',
        label: 'Circuit',
        padding: 0,
        width: "auto"
    },
    {
        id: 'Status',
        label: 'Status',
        padding: 0,
        width: "auto"


    },
    {
        id: 'Tie',
        label: 'Tie',
        padding: 0,
        width: "auto"
    },
    {
        id: 'ADSL_TAM_LLU',
        label: 'ADSL TAM LLU',
        padding: 0,
        width: "auto"
    },
    {
        id: 'LLU',
        label: 'LLU',
        padding: 0,
        width: "auto"
    },
    {
        id: 'Tie1',
        label: 'Tie1',
        padding: 0,
        width: "auto"
    },
    {
        id: 'EET',
        label: 'EET',
        padding: 0,
        width: "auto"


    },
    {
        id: 'Unit',
        label: 'Unit',
        padding: 0,
        width: "auto"
    },
    {
        id: 'From_MDF_No',
        label: 'From MDF No',
        padding: 0,
        width: "auto"
    },
    {
        id: 'From_MDF_Bar',
        label: 'From MDF Bar',
        padding: 0,
        width: "auto"
    },
    {
        id: 'From_MDF_Pair',
        label: 'From MDF Pair',
        padding: 0,
        width: "auto"
    },
    {
        id: 'Amendments',
        label: 'Amendments',
        padding: 0,
        width: "auto"


    },
    {
        id: 'Pcp',
        label: 'Pcp',
        padding: 0,
        width: "auto"
    },
    {
        id: 'Recovery_e_side',
        label: 'Recovery E Side',
        padding: 0,
        width: "auto"
    },
    {
        id: 'D_Side',
        label: 'D Side',
        padding: 0,
        width: "auto"
    },
    {
        id: 'To_MDF_No',
        label: 'To MDF No',
        padding: 0,
        width: "auto"
    },
    {
        id: 'To_MDF_Bar',
        label: 'To MDF Bar',
        padding: 0,
        width: "auto"
    },
    {
        id: 'To_MDF_Pair',
        label: 'To MDF Pair',
        padding: 0,
        width: "auto"
    },
    {
        id: 'New_E_Side',
        label: 'New E Side',
        padding: 0,
        width: "auto"
    },
    {
        id: 'To_Status',
        label: 'To Status',
        padding: 0,
        width: "auto"
    }
];
export default headCells;