export const csv_header = [
    { key: "md", label: 'MD' },
    { key: 'rd', label: 'RD' },
    { key: 'remaining_pcps', label: 'Remaining PCPs' },
    { key: 'completed_pcps', label: 'Completed PCPs' },
    { key: 'issued_pcps', label: 'Issued PCPs' },
    { key: 'total_pcps', label: 'Total PCPs' },
    { key: 'wd_1_coms', label: 'WD 1 Coms' },
    { key: 'todays_coms', label: 'Todays Coms' },
    { key: 'Assigned', label: 'Assigned' },
    { key: '%assigned', label: '% Assigned' },
    { key: 'remaining_jumpers', label: 'Remaining Jumpers' },
    { key: 'completed_jumpers', label: 'Completed Jumpers' },
    { key: 'issued_jumpers', label: 'Issued Jumpers' },
    { key: 'total_jumpers', label: 'Total Jumpers' },
    { key: '%hopper', label: '% Of Hopper' },
];

export const excel_report_header = [
    ['MD',
        'RD',
        'Remaining PCPs',
        'Completed PCPs',
        'Issued PCPs',
        'Total PCPs',
        'WD 1 Coms',
        'Todays Coms',
        'Assigned',
        '% Assigned',
        'Remaining Jumpers',
        'Completed Jumpers',
        'Issued Jumpers',
        'Total Jumpers',
        '% Of Hopper'
    ]
];

export const report_table_header = [
    {
        id: 'md',
        numeric: false,
        disablePadding: true,
        label: 'MD',
        width: '10%'
    },
    {
        id: 'rd',
        numeric: false,
        disablePadding: true,
        label: 'RD',
        width: '9%'
    },
    {
        id: 'remaining_pcps',
        numeric: false,
        disablePadding: true,
        label: 'Remaining PCPs',
        width: 95
    },
    {
        id: 'completed_pcps',
        numeric: false,
        disablePadding: true,
        label: 'Completed PCPs',
        width: '100'
    },
    {
        id: 'issued_pcps',
        numeric: false,
        disablePadding: true,
        label: 'Issued PCPs',
        width: '100'
    },
    {
        id: 'total_pcps',
        numeric: false,
        disablePadding: true,
        label: 'Total PCPs',
        width: 90
    },
    {
        id: 'wd_1_coms',
        numeric: false,
        disablePadding: true,
        label: 'WD 1 Coms',
        width: 90
    },
    {
        id: 'todays_coms',
        numeric: false,
        disablePadding: true,
        label: 'Todays Coms',
        width: 90
    },
    {
        id: 'Assigned',
        numeric: false,
        disablePadding: true,
        label: 'Assigned',
        width: '11%'
    },
    {
        id: '%assigned',
        numeric: false,
        disablePadding: true,
        label: '% Assigned',
        width: '11%'
    }, {
        id: 'remaining_jumpers',
        numeric: false,
        disablePadding: true,
        label: 'Remaining Jumpers',
        width: '11%'
    },
    {
        id: 'completed_jumpers',
        numeric: false,
        disablePadding: true,
        label: 'Completed Jumpers',
        width: '11%'
    },
    {
        id: 'issued_jumpers',
        numeric: false,
        disablePadding: true,
        label: 'Issued Jumpers',
        width: '100'
    },
    {
        id: 'total_jumpers',
        numeric: false,
        disablePadding: true,
        label: 'Total Jumpers',
        width: '11%'
    },
    {
        id: '%hopper',
        numeric: false,
        disablePadding: true,
        label: '% Of Hopper',
        width: '11%'
    }]

export const rd_table_header = [{
    id: 'rd',
    numeric: false,
    disablePadding: true,
    label: 'RD',
    width: '10%'
},
{
    id: 'ouc',
    numeric: false,
    disablePadding: true,
    label: 'CST OUC',
    width: '10%'
},
{
    id: 'hcr_completed',
    numeric: false,
    disablePadding: true,
    label: 'HCR Completed',
    width: '10%'
},
{
    id: 'hcr_est_fte',
    numeric: false,
    disablePadding: true,
    label: 'HCR EST FTE',
},
{
    id: 'hcr_required_fte',
    numeric: false,
    disablePadding: true,
    label: 'HCR Required FTE',
}
];

export const csv_rd_header = [
    { key: 'rd', label: 'RD' },
    { key: 'ouc', label: 'CST OUC' },
    { key: 'hcr_completed', label: 'HCR Completed' },
    { key: 'hcr_est_fte', label: 'HCR EST FTE' },
    { key: 'hcr_required_fte', label: 'HCR Required FTE' }
   
];

export const excel_rd_header = [
    [
        'RD',
        'CST OUC',
        'HCR Completed',
        'HCR EST FTE',
        'HCR Required FTE'
       
    ]
];

export const fileName = 'HCR Reporting';