
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import React, { useState } from 'react';

import headCells from '../../constant/jumpertableheadcells';
import { getComparator } from '../HCR-Report/HcrRd.util';


const JumperTable = ({ data, tabName, onRowclick, selectedrow }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('column');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowSelection = (row) => {
    onRowclick(row)
  }


  /** Table Sorting */
  const handleSort = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)

  }
 


return (
    
      <TableContainer id={"jumperschedule-table-container" + tabName} component={Paper} sx={{ position: "relative", marginLeft: 1, mt: 2, maxHeight: '600px', maxWidth: "1350px" }}>
        <Table >
          <TableHead id={"jumperschedule-table-head-" + tabName} sx={{ top: 0, position: "sticky", zIndex: 1 }}>
            <TableRow id={"jumperschedule-table-row-" + tabName} sx={{ backgroundColor: "#5488c7", color: "white", padding: "20px" }} >
              {headCells.map((headcell) => (
                <TableCell id={"jumperschedule-tablecell" + tabName} align="center" key={headcell.id} sx={{ color: "white", minWidth: "50px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: headcell.padding, overflow: 'hidden', flex: 1 }}>
                  <TableSortLabel
                    id={"jumperschedule-table-" + headcell.label + "-" + tabName}
                    active={orderBy === headcell.id}
                    direction={orderBy === headcell.id ? order : 'asc'}
                    onClick={handleSort(headcell.id)}
                  >
                    {headcell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data ? (data.sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
              .map((row, index) => (
                <TableRow
                  key={row.id}
                  id={"jumperschedule-tablerow-" + index + "-" + tabName}
                  data-testid={"jumperschedule-tablerow-" + index + "-" + tabName}
                  onClick={() => handleRowSelection(row)}
                  style={{ backgroundColor: selectedrow === row ? "rgba(13, 110, 253, 0.9)" : "inherit" }}
                >
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Circuit}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Status}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Tie}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.ADSL_TAM_LLU}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.LLU}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Tie1}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.EET}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Unit}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.From_MDF_No}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.From_MDF_Bar}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.From_MDF_Pair}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Amendments}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Pcp}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.Recovery_e_side}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.D_side}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.To_MDF_No}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.To_MDF_Bar}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.To_MDF_Pair}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.New_E_Side}</TableCell>
                  <TableCell id={"jumperschedule-table-" + row.Circuit + index + "-" + tabName} align="right" sx={{ border: "1px solid #c2c2c2", fontSize: "12px", textAlign: "center", margin: 0, padding: "9px" }} >{row.To_Status}</TableCell>
                </TableRow>
              )) : null}
          </TableBody>
        </Table>
        <TablePagination
          id={"jumperschedule-table-pagination"}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={typeof data != "undefined" &&
          data != null &&
          data.length != null &&
          data.length > 0 ? data.length : null}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          data-testid="table-pagination"
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={data.length}
          showLastButton={data.length}
        />
      </TableContainer>
     
  )
}
export default JumperTable