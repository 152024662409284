  // if user don't have access
import React from 'react'
import LockPersonIcon from '@mui/icons-material/LockPerson';
const NoAccess = () => {
  return (
     <div style={{display:"flex",justifyContent:"center",alignItems:"center",alignContent:"center",flexDirection:"column",border:"2px solid black", width:"1000px",height:"440px", margin:"auto",marginTop:"23px",background:"#cdcdcf",}}>
      <LockPersonIcon  sx={{ width: "50px", height: "50px"}}/>
      <h3>Access to this page is restricted</h3>
    </div>
   )
}

export default NoAccess