import { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { BreadCrumbContext } from '../common/BreadCrumbContext';

import { Box, Button, Modal, Link, Paper, Popover, Stepper, Step, Table, TextField, MenuItem, Snackbar, Alert, StepLabel, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { ACR, BASE_URL, GET_REMARKS, GET_WFMT_PROJECT_DETAILS, POST_API_DATA_PROCESSING, STAGES, VIEW_JOB_DETAILS, WFMT_POST_REQUEST } from '../../constant/urls';
import { useMsal } from '@azure/msal-react';
import handleWFMTdisable from './ViewJobModal.util';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    margin: "2px",
};

export default function ViewJobModal({ job_id, exch_sau, sau, selected_cable }) {
    const { accounts } = useMsal()
    const { setPathList, setExchsau, setViewjobid } = useContext(BreadCrumbContext);
    const columns = ['Job Id', 'Planner Name', 'Modified On', 'Recovery Cable', 'Total PCPs to Offload', 'Offload PCPs', 'Marked as Unviable', 'Marked as Onhold', 'Overall Status', 'Remarks', 'WFMT Details']
    const remarkscolumn = ["EIN", "Created At", "Remarks"]
    const wfmtscolumn = ["WFMT Id", "WFMT Trigger Date", "Status", "Message", "Completed status", "Completed message", "Completed date", "Reason"]
    const navigate = useNavigate();
     
       //to pass logged user params for apis
    const params={
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    const [snackMsg, setSnackMsg] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertsucessOpen, setAlertsucessOpen] = useState(false);
    
    const [jobList, setJobList] = useState([]);
    const [open, setOpen] = useState(false);

    const [steps, setSteps] = useState([]);

    const steplabel = {
        fontSize: '11px',
        '& .MuiStepLabel-label': {
            fontSize: '11px !important',
            fontWeight: 700
        }
    }
    const [activeStep, setActiveStep] = useState();
    const [remarksData, setRemarksData] = useState([]);
    const [WFMTData, setWFMTData] = useState([]);

    // axios call to get stages name and to view selected job details
    const handleOpen =async () => {
        setOpen(true);

        const stepsurl = `${BASE_URL}/${ACR}/${STAGES}`
       await axios.get(stepsurl,{ params:params })
            .then((res) => {
                const step = res.data.Data
                step.push({ id: 8, stage: "UNVIABLE" })
                setSteps(step);
            })
            .catch((error) => {
                console.error(error);
                setSnackMsg("Something went wrong.Try Again!!");
                setAlertOpen(true);
            })
        axios.get(`${BASE_URL}/${ACR}/${VIEW_JOB_DETAILS}?job_id=${job_id}`,{ params:params })
            .then((res) => {
                const job_list = res.data.Data;
                setJobList([job_list]);
                const overall_status = steps.find((item) => item.stage === job_list.overall_status);
                if (job_list.marked_as_unviable === true) {
                    setActiveStep(8)
                }
                else {
                    setActiveStep(overall_status ? overall_status.id : 0)
                }
            })
            .catch((error) => {
                console.error(error);
                setSnackMsg("Something went wrong.Try Again!!");
                setAlertOpen(true);
            })

       
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (job_id) => (event) => {
        setAnchorEl(event.currentTarget);
        handleRemarks(job_id);
    };

    const handleClosem = () => {
        setAnchorEl(null);
    };

    const openr = Boolean(anchorEl);
    const id = open ? 'remarks-popover' : undefined;

    // using axios api calls api call for remarks table
    const handleRemarks = (job_id) => {
        const remarksurl = `${BASE_URL}/${ACR}/${GET_REMARKS}?job_id=${job_id}`
        
        axios.get(remarksurl,{ params:params })
            .then((res) => {
                setRemarksData(res.data.Data)
            })
            .catch((error) => {
                console.error(error);
                setSnackMsg("Something went wrong.Try Again!!");
                setAlertOpen(true);
            })
    };

    // on click open jon navigate to home 
    const handleOpenJob = () => {
        navigate('/home');
        setPathList([]);
        localStorage.setItem('page', 0)
        setViewjobid(job_id)
        setExchsau(exch_sau)
    }

    // hanke wfmt button disable 
    const [jobtypeopen, setJobtypeOpen] = useState(false);
    const [jobtype, setJobtype] = useState("E-Side");

    // wfmt function
    const handleWfmt = () => {
        setJobtypeOpen(!jobtypeopen)
        setShowLink(false);
    }

    // axios post to triggerr the wfmt
    const handleTriggerWfmt = () => {
        const wfmtposturl = `${BASE_URL}/${ACR}/${WFMT_POST_REQUEST}`;
        const data = jobList ? jobList[0] : null
        const wfmtdata = {
            "jobid": data?.job_id,
            "exchange": exch_sau,
            "sau": sau,
            "cable_id": data?.recoverable_cable,
            "job_type": jobtype,
            "planner_ein": accounts[0]?.idTokenClaims.EIN
        }
        axios.post(wfmtposturl, wfmtdata, )
            .then((res) => {
                setJobtypeOpen(!jobtypeopen)
                setJobtype("");
                if(res.data.status === "Fail"){
                    setSnackMsg(res.data.MESSAGE);
                    setAlertOpen(true);
                }
                else if(res.data.status === "Success"){
                    setSnackMsg(res.data.MESSAGE);
                    setAlertsucessOpen(true);
                    handleOpen()
                }
            })
            .catch((error) => {
                setSnackMsg("Something went wrong.Try Again!!");
                setAlertOpen(true);
            })
    }

    // to set the job type
    const handlejobtypeChange = (event) => {
        setJobtype(event.target.value);
    };

    const [anchorElwfmt, setAnchorElwfmt] = useState(null);

    const handleClickwfmt = (job_id) => (event) => {
        setAnchorElwfmt(event.currentTarget);
        handleWfmtdetails(job_id);
    };

    // to get the wfmt details for table
    const handleWfmtdetails =(jobid)=>{
        const wfmtgeturl = `${BASE_URL}/${ACR}/${GET_WFMT_PROJECT_DETAILS}`
        const params = {
            "jobid": jobid,
            "exchange": exch_sau,
            "sau": sau,
            "cable_id": selected_cable,
            "logged_user": accounts[0]?.idTokenClaims.EIN
        }
        axios.post(wfmtgeturl,params, )
            .then((res) => {
                setWFMTData(res.data.Data)
            })
            .catch((error) => {
                console.error(error);
                setSnackMsg("Something went wrong.Try Again!!");
                setAlertOpen(true);
            })
    }
    const handleClosemwfmt = () => {
        setAnchorElwfmt(null);
    };

    const handlealertClose = () => {
        setAlertOpen(false);
        setAlertsucessOpen(false)
    };

    const openrwfmt = Boolean(anchorElwfmt);
    const idwfmt = openrwfmt ? 'wfmt-popover' : undefined;

     
    //  Record Plus data
    const [recordplusdata, setRecordplusdata] = useState(null);
    const [showLink, setShowLink] = useState(false)

    // disable the record plus button if there is no offloaded pcps
    const handlerecorddisable = () => {
        return jobList[0]?.offloaded_pcps === 0
    }

    // to get the presigned url of post api data processing(record plus data)
    const handleRecordPlusdata = () => {
        setJobtypeOpen(false)
        const recordplusurl = `${BASE_URL}/${ACR}/${POST_API_DATA_PROCESSING}?uuid=${job_id}`
        axios.get(recordplusurl,{params:params })
            .then((response) => {
                if (response.data.status === "Fail") {
                    setSnackMsg("Something went wrong.Try Again!!");
                    setAlertOpen(true);
                    setShowLink(false);
                }
                else if (response.data) {
                    let url = response.data['presigned_url'];
                    setRecordplusdata(url);
                    setShowLink(true);
                }
            }).catch((error) => {
                console.error(error);
                setSnackMsg("Something went wrong.Try Again!!");
                setAlertOpen(true);

            });
    }

    // downloading record plus data usimg presigned url
    const onDownload = () => {
        axios.get(recordplusdata, { responseType: 'blob' }).then((response) => {
            if (response.data) {
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                const link = document.createElement('a');
                link.href = url;
                const fileName = "post_api_data_processing.xlsx"
                link.setAttribute('download', fileName);
                link.click();
            }
        }).catch((error) => {
            console.error(error);
            setSnackMsg("Something went wrong.Try Again!!");
            setAlertOpen(true);
        });

    };
    useEffect(()=>{
        const overall_status = steps.find((item) => item.stage === jobList[0]?.overall_status);
        if (jobList[0]?.marked_as_unviable === true) {
            setActiveStep(8)
        }
        else {
            setActiveStep(overall_status ? overall_status.id : 0)
        }
    },[steps,jobList])
    return (
        <div>
            <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handlealertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handlealertClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={alertsucessOpen} autoHideDuration={3000} onClose={handlealertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handlealertClose} severity="success" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <p style={{ textDecoration: "underline", color: "blue", padding: "0 1px", margin: 0, cursor: "pointer", fontSize: "12px" }}
                data-testid="view-cable" onClick={handleOpen}>View cable</p>
            <Modal
                id="view-job-modal"
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <p style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px", padding: 0, margin: 0, color: "rgba(0, 0, 0, 0.58)" }}>JOB DETAILS</p>
                    <div style={{ marginBottom: "40px", marginTop: "20px" }}>
                        <Stepper id="view-job-modal-stepper" activeStep={activeStep} alternativeLabel >
                            {steps.map((label) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step id="view-job-modal-step" className='step' key={label.stage} {...stepProps}>
                                        <StepLabel id={"view-job-modal-step-" + label} sx={steplabel}  {...labelProps}>{label.stage}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>

                    <TableContainer id="view-job-modal-TableContainer" component={Paper} sx={{ position: "relative", maxHeight: "260px" }}>
                        <Table id="view-job-modal-Table">
                            <TableHead id="view-job-modal-TableHead" sx={{ top: 0, position: "sticky", zIndex: 1 }}>
                                <TableRow id="view-job-modal-TableRow-head" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                                    {columns.map((headcell) => (
                                        <TableCell id="view-job-modal-TableCell" key={headcell} align='center' sx={{ color: "white", minWidth: "50px", margin: 0, padding: "4px", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "13px" }}>
                                            {headcell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    jobList ? jobList.map((row) => (
                                        <TableRow key={row.job_id}>
                                            <TableCell id={"view-job-tablecell-job_id-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px", width: "200px" }} align='center'>{row.job_id}</TableCell>
                                            <TableCell id={"view-job-tablecell-planner_name-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.planner_name}</TableCell>
                                            <TableCell id={"view-job-tablecell-modified_on-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.modified_on}</TableCell>
                                            <TableCell id={"view-job-tablecell-recoverable_cable-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.recoverable_cable}</TableCell>
                                            <TableCell id={"view-job-tablecell-total_pcps_to_offload-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.total_pcps_to_offload}</TableCell>
                                            <TableCell id={"view-job-tablecell-offloaded_pcps-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.offloaded_pcps}</TableCell>
                                            <TableCell id={"view-job-tablecell-marked_as_unviable-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.marked_as_unviable.toString()}</TableCell>
                                            <TableCell id={"view-job-tablecell-marked_as_onhold-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.marked_as_onhold.toString()}</TableCell>
                                            <TableCell id={"view-job-tablecell-marked_as_unviable-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.marked_as_unviable === true ? "UNVIABLE" : row.overall_status}</TableCell>
                                            <TableCell id={"view-job-tablecell-Remarks-"+row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", cursor: "pointer" }} align='center'>
                                                <Link aria-describedby={id} variant="contained" onClick={handleClick(row.job_id)} data-testid="remarks-link">Remarks</Link>
                                                <Popover
                                                    id={id}
                                                    open={openr}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClosem}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    style={{ position: "absolute", top: "20px" }}
                                                >
                                                    <TableContainer sx={{ position: "relative", maxHeight: "160px" }}>
                                                        <Table>
                                                            <TableHead id="view-job-modal-tablehead" sx={{ top: 0, position: "sticky", zIndex: 1 }}>
                                                                <TableRow id="view-job-modal-tablerow" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                                                                    {remarkscolumn.map((headcell) => (
                                                                        <TableCell id={"view-job-modal-" + headcell} align='center' key={headcell} sx={{ color: "white", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: "2px" }}>
                                                                            {headcell}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {remarksData ? remarksData.map((row ) => (
                                                                    <TableRow key={row?.ein}>
                                                                        <TableCell id={"view-job-modal-tablecell-ein" + row.ein} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row.ein} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-created_at" + row.ein} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row.created_at} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-remarks" + row.ein} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row.remarks.length > 20 ? row.remarks.slice(0, 20) + "..." : row.remarks} </TableCell>
                                                                    </TableRow>)) : null}
                                                            </TableBody>
                                                        </Table>
                                                        {remarksData.length === 0 ? <p style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>No remarks</p> : null}
                                                    </TableContainer>
                                                </Popover>
                                            </TableCell>
                                            <TableCell id={"view-job-modal-tablecell-viewcable"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px", cursor: "pointer" }} align='center'>
                                                <Link aria-describedby={idwfmt} variant="contained" data-testid="wfmt-details-link" onClick={handleClickwfmt(row.job_id)}  >Details</Link>
                                                <Popover
                                                    id={idwfmt}
                                                    open={openrwfmt}
                                                    anchorEl={anchorElwfmt}
                                                    onClose={handleClosemwfmt}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    style={{ position: "absolute", top: "20px" }}
                                                >
                                                    <TableContainer sx={{ position: "relative", maxHeight: "160px" }}>
                                                        <Table>
                                                            <TableHead id="view-job-modal-tablehead" sx={{ top: 0, position: "sticky", zIndex: 1 }}>
                                                                <TableRow id="view-job-modal-tablerow" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                                                                    {wfmtscolumn.map((headcell) => (
                                                                        <TableCell id={"view-job-modal-" + headcell} align='center' key={headcell} sx={{ color: "white", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: "2px" }}>
                                                                            {headcell}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {WFMTData ? WFMTData?.map((row) => (
                                                                    <TableRow key={row?.wfmt_id}>
                                                                        <TableCell id={"view-job-modal-tablecell-wfmt_id" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align='center'>{row?.wfmt_id} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-wfmt_trigger_date" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.wfmt_trigger_date} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-status" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.status} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-message" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.message} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-completed_status" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.completed_status} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-completed_message" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.completed_message} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-completed_date" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.completed_date} </TableCell>
                                                                        <TableCell id={"view-job-modal-tablecell-error_reason" + row.wfmt_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px" }} align='center'>{row?.error_reason} </TableCell>
                                                                    </TableRow>)) : <TableRow>cc</TableRow>}
                                                            </TableBody>
                                                        </Table>
                                                        {WFMTData?.length===0  ? <p style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>No details</p> : null}
                                                    </TableContainer>
                                                </Popover>
                                            </TableCell> 
                                        </TableRow>
                                    )) : null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <div style={{ display: "flex", flexDirection: 'row-reverse', marginTop: "25px" }}>

                        <Button id="view-job-modal-dismiss" data-testid="view-job-modal-dismiss" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "10px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} onClick={handleClose}>Dismiss</Button>
                        <Button id="view-job-modal-recordplusdata" data-testid="view-job-modal-recordplusdata" style={{ color: "white", backgroundColor: handlerecorddisable() ? "rgb(127 127 164)" : "rgb(2, 2, 81)", marginTop: "10px", marginRight: "10px", width: "200px", height: "30px", float: "right" }} onClick={handleRecordPlusdata} disabled={handlerecorddisable()}>Record Plus Data</Button>
                        <Button id="view-job-modal-openjob" data-testid="view-job-modal-openjob" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", marginTop: "10px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} onClick={handleOpenJob}>Open Job</Button>
                        <Button id="view-job-modal-wfmt" data-testid="view-job-modal-wfmt" style={{ color: "white", backgroundColor: handleWFMTdisable(WFMTData) ? "rgb(127 127 164)" : "rgb(2, 2, 81)", marginTop: "10px", marginRight: "10px", width: "100px", height: "30px", float: "right" }} disabled={handleWFMTdisable(WFMTData)} onClick={handleWfmt} >WFMT</Button>
                        {jobtypeopen ? <div style={{ display: "flex" }}>
                            <TextField
                                id="jobtype"
                                size="small"
                                select
                                variant="standard"
                                label="Job type"
                                name="jobtype"
                                value={jobtype}
                                sx={{ width: 180, fontSize: "10px", color: "rgb(2, 2, 81)", marginTop: "-6px", marginRight: "10px" }}
                                onChange={handlejobtypeChange}
                            >
                                <MenuItem id={"jobtype-"}  value={"E-Side"} sx={{ fontSize: "13px" }}>E-Side </MenuItem>
                                <MenuItem id={"jobtype-"}  data-testid="jobtype-MUCJ" value={"MUCJ"} sx={{ fontSize: "13px" }}>MUCJ</MenuItem>
                                <MenuItem id={"jobtype-"} value={"ICE"} sx={{ fontSize: "13px" }}>ICE</MenuItem>
                            </TextField>
                            <Button id="jobtype" data-testid="jobtype" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", width: "150px", height: "30px", margin: "10px 10px 0 10px" }} onClick={handleTriggerWfmt} >trigger wfmt </Button>

                        </div> : null}

                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 5px 0 0" }}>
                        {showLink &&
                            <div  >
                                <Link
                                    id="record-plus-data-download"
                                    data-testid="record-plus-data-download"
                                    variant="body2"
                                    underline='hover'
                                    onClick={onDownload}

                                    sx={{ fontSize: 14, color: 'red', '&:hover': { cursor: 'pointer' } }}
                                >
                                    Click here to download the Record plus data
                                </Link>
                            </div>

                        }
                    </div>
                </Box>
            </Modal>
        </div>
);
}