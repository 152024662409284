import axios from "axios"
import { ACR, BASE_URL, SAVE_JUMPER, UPDATE_JUMPER } from "../../constant/urls"

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}
//to save the data post api calling
export const handleSave = (tabName, index, pcpNames, cable_ids, job_id, donor_cables, job_owner, USER_EIN, tableData, setsnackOpenfail, setSnackMsg, setsnackopensucess, setAlertOpen) => {
    const saveurl = `${BASE_URL}/${ACR}/${SAVE_JUMPER}`;
    const host_cableindex = pcpNames.indexOf(tabName);
    const details = {
        "cable_id": cable_ids,
        "job_id": job_id || "",
        "host_cable": donor_cables.split(",")[host_cableindex],
        "pcp_id": tabName,
        "job_owner": job_owner || "",
        "logged_user": USER_EIN,
    }
    const jumper_data = tableData[index][tabName];
    const saveData = { ...details, jumper_data };

    axios.post(saveurl, saveData)
        .then((res) => {
            if ((res.data.status).toLowerCase() === "fail") {
                setsnackOpenfail(true);
                setSnackMsg(res.data.Message)
            }
            else if ((res.data.status).toLowerCase() === "success") {
                setsnackopensucess(true);
                setSnackMsg(res.data.Message)
            }
        })
        .catch((error) => {
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
            setsnackOpenfail(false)
            setAlertOpen(true);
        })
    return null
}
// to reset the table
export const handleReset = (tabName, index, scheduleNames, hostNames, sau, tableData, selectedtabname, selectedIndex, setTableData, setShowLink, handlecount, setsnackopensucess, setSnackMsg, cable_ids, job_owner, USER_EIN, job_id, setsnackOpenfail, setAlertOpen) => {
    const key = "reset"
    const schedule = scheduleNames[index]
    const PCPs = tabName
    const donor_cables = hostNames[index]
    const resettableurl = `${BASE_URL}/${ACR}/${UPDATE_JUMPER}?sau=${sau}&schedule=${schedule}&pcp_ids=${PCPs}&donor_cables=${donor_cables}&cable=${cable_ids}&job_owner=${job_owner}&logged_user=${USER_EIN}&job_id=${job_id}&key=${key}`

    axios.get(resettableurl,)
        .then((res) => {
            const oritabledata = res.data.result;
            const updatedtabledata = [...tableData];
            updatedtabledata[selectedIndex][selectedtabname] = oritabledata[0][selectedtabname];
            setTableData(updatedtabledata);
            setShowLink(false);
            handlecount();
            setsnackopensucess(true);
            setSnackMsg('Reset sucessfully')

        })
        .catch((error) => {
            console.error(error);
            setSnackMsg('Something went wrong!.Please try again!');
            setsnackOpenfail(false)
            setAlertOpen(true);
        })
    return null
}


//functionality to handle back button in edit modal
export const  Back = (dataforSelectedtab, selectedrow, currentindex, tableData, setCurrentindex, setSelectedrow, setEditedData) => {
    const currentTabdata = dataforSelectedtab() || [];
    const selectedIndex = currentTabdata.findIndex((item) => item.id === selectedrow.id);
    const nextIndex = currentindex === 0 || currentindex === tableData.length ? (selectedIndex - 1) % currentTabdata.length : (currentindex - 1) % currentTabdata.length
    const removenegnextindex = Math.abs(nextIndex)
    if (nextIndex === -1) {
        const next = currentTabdata.length - 1
        const neg = Math.abs(next)
        setCurrentindex(neg)
        const nextrow = currentTabdata[neg];
        setSelectedrow(nextrow)
        setEditedData(nextrow);
    }
    else {
        setCurrentindex(removenegnextindex)
        const nextrow = currentTabdata[removenegnextindex];
        setSelectedrow(nextrow)
        setEditedData(nextrow);
    }
    return null
}

export  const handlesnackCloseSuccess = (setsnackopensucess) => {
    setsnackopensucess(false);
    return false
};

export  const getComparator = (order, orderBy) => {
    return order === 'asc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export const jumperdata = (jumper) => {
    if (jumper[0] === null) { return [] }
    else { return jumper }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { descendingComparator, handleSave, handleReset,jumperdata,  Back,handlesnackCloseSuccess ,getComparator}