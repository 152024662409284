import React from 'react';
import axios from "axios";
import { BASE_URL, HCR, RD } from '../../constant/urls';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import * as XLSX from 'xlsx-js-style';
import { CSVLink } from "react-csv";
import {
    Box, CircularProgress, Backdrop, MenuItem, FormControl, Select,
    Paper, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
     Snackbar, Alert, Grid, InputAdornment, Input
} from '@mui/material';

import { csv_rd_header, excel_rd_header, rd_table_header, fileName } from './HcrReportConstant';
import { useMsal } from '@azure/msal-react';
import { getComparator, requestSearch } from './HcrRd.util';
import Tablepagination from '../common/Tablepagination';

function HcrRd() {


    /** Search */
    const { accounts } = useMsal()


    /** Snackbar */
    const [snackMsg, setSnackMsg] = React.useState('');

    const [alertOpen, setAlertOpen] = React.useState(false);
    const handleClose = () => {
        setAlertOpen(false);
    }

    /** Loader Implementation */
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    /** Download report select */
    const [downloadReport, setDownloadReport] = React.useState('');
    const handleReport = (event) => {
        setDownloadReport(event.target.value);
        if (event.target?.value === "excel") {
            exportToExcel(tabledata, fileName);
        }
    };

    const params = {
        logged_user: accounts[0]?.idTokenClaims.EIN
    }
    /** CSV Headers  */
    const headers = csv_rd_header;

    /** Excel Headers */
    const heading = excel_rd_header;

    /** File Extension */
    const fileExtension = '.xlsx';

    /**
     *
     * Excel Report
     * @param {*} excelData
     * @param {*} fileName
     */
    const exportToExcel = (excelData, fileName) => {

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, heading);

        for (let i = 0; i < heading[0].length; i++) {
            const cell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];
            if (!cell.s) { cell.s = {}; }
            if (!cell.s.font) { cell.s.font = {}; }
            cell.s.font.bold = true;
        }

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName + fileExtension);

    }

    /** Table Sorting */
    const order = 'asc';
    const orderBy = '';

    /** Table Header cells*/
    const headCells = rd_table_header;

    /** Table data */
    const [tabledata, setTabledata] = React.useState([]);
    const [tabledataCopy, setTabledataCopy] = React.useState([]);

    /** Pagination */
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /**
    * axios call for report details
    *
    */
    const getReportDetails = () => {
        setLoading(true)
        axios.get(`${BASE_URL}${HCR}${RD}`, { params: params }).then((tableResponse) => {
            setOpen(true);
            if (tableResponse?.data?.results) {
                setTabledata(tableResponse?.data?.results);
                setTabledataCopy(tableResponse?.data?.results);
                setLoading(false);
                setOpen(false);

            }
        }).catch((error) => {
            console.error(error);
            setLoading(false);
            setOpen(false);
            setAlertOpen(true);

            setSnackMsg('Something went wrong!.Please try again!');
        });

    };


    React.useEffect(() => {
        getReportDetails();
    }, []);

    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >

                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} data-testid="close-icon" onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>

            <div style={{ display: "flex", justifyContent: 'space-between', marginTop: "-23px" }}>

                <div>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                        <InputLabel >Search</InputLabel>
                        <Input
                            id="search"
                            type='text'
                            size="small"
                            data-testid="input-search"
                            onChange={(searchVal) => requestSearch(searchVal, tabledataCopy, setTabledata)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon />
                                    </InputAdornment>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div style={{ marginTop: 5, marginRight: "700px" }}>
                    <FormControl sx={{ mt: 2, minWidth: 135, height: 10 }} size="small">
                        <InputLabel id="demo-select-small-label" sx={{ fontSize: 12, fontWeight: 'bold' }} >Primary Report</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            sx={{ fontSize: 12 }}
                            value={downloadReport}
                            label="PrimaryReport"
                            onChange={handleReport}
                        >
                            <MenuItem value='csv'><CSVLink style={{ fontSize: 12, textDecoration: 'none', color: 'black' }} data={tabledata} headers={headers} filename={fileName}>
                                CSV Report
                            </CSVLink></MenuItem>
                            <MenuItem sx={{ fontSize: 12 }} value='excel'> Excel Report</MenuItem>
                        </Select>
                    </FormControl>
                </div>


            </div>

            <Grid container >
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow sx={{
                                    "& th": {
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        backgroundColor: "#5488c7",
                                        color: "white",
                                    }
                                }}>
                                    {headCells?.map((headcell) => (
                                        <TableCell key={headcell.id} sx={{ color: "white", minWidth: "80px", maxWidth: headcell.width, border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold" }} align="center">

                                            {headcell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(tabledata?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(getComparator(order, orderBy)))?.map((row) => (
                                    <TableRow

                                        key={row.id}
                                    >
                                        <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.rd}</TableCell>
                                        <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.ouc}</TableCell>
                                        <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.hcr_completed}</TableCell>
                                        <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{row.total_days}</TableCell>
                                        <TableCell sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "2px" }} align="center">{'-'}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <Tablepagination
                            setPage={setPage}
                            page={page}
                            count={tabledata?.length}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowsPerPage={rowsPerPage}
                        />
                    </TableContainer>

                </Grid>
            </Grid>

        </ >
    )
}

export default HcrRd
