import * as React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography, IconButton, Button, Modal, Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import './HcrHome.css';
import axios from 'axios';
import { HOME_HCR, BASE_URL, HCR } from "../../constant/urls";
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';
import {  EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ADMIN } from '../../constant/userConfig';
import { useMsal } from '@azure/msal-react';
import { handleChange, handleClose, handleOpen, handleSave, handlesnackClose } from './Hcrhome.util';

/**
 *
 *
 * @param {*} props
 * @return {*} 
 */

/** creating tab panels */
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function HcrHome() {
    /**  Home Tabs */
    const [tabData, setTabData] = useState([])
    const [value, setValue] = useState(0);
    const [editedContent, setEditedContent] = useState(EditorState.createEmpty());
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState("");
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(true);
    const [snackMsg, setSnackMsg] = React.useState('');
    const [alertOpen, setAlertOpen] = React.useState(false);
     
    const { accounts } = useMsal()

   
    const customToolbar = {
        options: ['inline', 'list', 'textAlign', 'colorPicker', 'fontSize', 'fontFamily'],
        inline: {
            options: ['bold', 'italic', 'underline']
        }
    }

    const tabkeys = ['general', 'engineer', 'lead', 'planner']

    const getApiData = () => {

        const tabKey = tabkeys[value];
        
        axios.get(`${BASE_URL}${HCR}/${HOME_HCR}`, {
            params: { home_key: tabKey,logged_user: accounts[0]?.idTokenClaims.EIN },
        })
            .then((res) => {
                setTabData(res.data.results)
                setState(JSON.parse(localStorage.getItem("ROLE")))
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setOpen(false);
                setSnackMsg('Something went wrong!.Please try again!');
                setAlertOpen(true);

            })
    }

    useEffect(() => {
        getApiData();
    }, [value])

    const content = draftToHtml(convertToRaw(editedContent.getCurrentContent()))


     

    return (
        <>
            {loading &&
                <Box sx={{ width: '100%' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                        // data-testid="backdrop-close"
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
             <Snackbar open={alertOpen} autoHideDuration={6000} onClose={()=>handlesnackClose(setAlertOpen)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} data-testid="snack">
                <Alert onClose={()=>handlesnackClose(setAlertOpen)} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={(event,newValue)=>handleChange(newValue,setValue)} aria-label="basic tabs example">
                        <Tab label="General" data-testid="general-tab"  {...a11yProps(0)} />
                        <Tab label="Engineer" data-testid="engineer-tab" {...a11yProps(1)} />
                        <Tab label="Lead" data-testid="lead-tab" {...a11yProps(2)} />
                        <Tab label="Planner" data-testid="planner-tab"{...a11yProps(3)} />
                    </Tabs>
                </Box>

                {tabkeys.map((tabKey, index) => (
                    <Box key={tabKey} bgcolor="#f5eacb">
                        <CustomTabPanel value={value} index={index}>
                            <div dangerouslySetInnerHTML={{ __html: tabData }}></div>
                            {state === ADMIN ? <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: 120,
                                    right: 25
                                }}
                                onClick={() => handleOpen(tabData,setEditedContent,setIsOpen)}>
                                <ModeTwoToneIcon />
                            </IconButton> : null}
                        </CustomTabPanel>
                    </Box>
                ))}

                <Modal
                    open={isOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    width={1000}
                    onClose={handleClose}
                    data-testid="modal-close"
                    >

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '55%',
                            width:  "800px",
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            padding: "25px"
                        }}
                    >
                        <Editor
                            editorState={editedContent}
                            onEditorStateChange={(newState) => setEditedContent(newState)}
                            editorStyle={{ minHeight: "300px", maxHeight: "300px", overflow: "scroll" }}
                            toolbarClassName="toolbar-custom"
                            wrapperClassName="wrapper-custom"
                            editorClassName="editor-custom"
                            toolbar={customToolbar}
                        />

                        <Button onClick={()=>handleSave(tabkeys,value,content,accounts,getApiData,setSnackMsg,setAlertOpen,setIsOpen)} data-testid="save-button" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", margin: "0px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px", marginTop: "10px" }}
                        >
                            Save
                        </Button>
                        <Button onClick={()=>handleClose(setIsOpen)} data-testid="cancel-button" style={{ color: "white", backgroundColor: "#000", margin: "10px 10px 9px", width: "100px", height: "30px", float: "right", fontSize: "12px" }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Modal>

            </Box>
        </>
    )
}