import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react'
import { handleSolutionchange } from './Exchangecabledetails.util';

const ExchangecabledetailsFilter = ({setPotentialNetchecked,potentialNetchecked,setPage,eodpschecked,setEodpschecked,setTonnechecked,tonnechecked,selectedSolution,noSolution,setSelectedSolution}) => {
    return (
        <Card id="exchange-filter-card" variant="outlined" sx={{ minWidth: 275, marginTop: "10px" }}>
            <CardHeader id="exchange-filter-cardhead" sx={{ background: 'linear-gradient(to bottom,  #142032 ,#2e3a4c)', padding: 0.5 }}
                titleTypographyProps={{ fontSize: 16, fontWeight: 'bold', color: 'white' }}
                title="FILTERS"
            />
            <CardContent id="exchange-filter-cardcontent" sx={{ marginTop: "-13px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Checkbox sx={{ margin: 0 }} id="exchange-checkbox-potentialNetchecked" data-testid="exchange-checkbox-potentialNetchecked" checked={potentialNetchecked} onChange={() => { setPotentialNetchecked(!potentialNetchecked); setPage(0); }} />
                            <Typography sx={{ paddingTop: "18px", fontSize: "12px", fontWeight: "700" }}>Exclude net Potential &lt; £1k</Typography>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}> <Checkbox sx={{ margin: 0 }} id="exchange-checkbox-eodpschecked" checked={eodpschecked} onChange={() => { setEodpschecked(!eodpschecked); setPage(0); }} />
                            <Typography sx={{ paddingTop: "18px", fontSize: "12px", fontWeight: "700" }}>Exclude EODPs</Typography>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                            <Typography id="exclude_solution_label" sx={{ padding: "18px", fontSize: "12px", fontWeight: "700" }}>Exclude Solution</Typography>
                            {Array.from({ length: 5 }, (x, index) => (
                                <FormControlLabel
                                    key={x}
                                    id={`exchange-Solution-${index + 1}`}
                                    control={<Checkbox data-testid={`exchange-checkbox-noSolution-${index + 1}`} id={`exchange-checkbox-Solution-${index + 1}`} />}
                                    checked={selectedSolution?.includes(index + 1) === noSolution?.toString()?.includes((index + 1).toString())}
                                    onChange={() => handleSolutionchange(index + 1,selectedSolution,setSelectedSolution,setPage)}
                                    label={index + 1}
                                    disabled={!noSolution?.toString().includes((index + 1).toString())}
                                />
                            ))}
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Checkbox sx={{ margin: 0 }} id="exchange-checkbox-tonnechecked" data-testid="exchange-checkbox-tonnechecked" checked={tonnechecked} onChange={() => { setTonnechecked(!tonnechecked); setPage(0); }} />
                            <Typography sx={{ paddingTop: "18px", fontSize: "12px", fontWeight: "700" }}>Exclude tonnes &lt; 1</Typography>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card >
    )
}

export default ExchangecabledetailsFilter