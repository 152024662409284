// add data for the table functionality
export const handleAddData = (newdata, data, tabNames, selectedIndex, setCount, count, setIsModalOpen, tableData, setTableData) => {
    const currentTabdata = data;
    const maxid = Math.max(0, ...currentTabdata.map((item) => item.id));
    const newId = maxid + 1;
    const newdatawithid = { id: newId, ...newdata };
    const checkdata = currentTabdata.map((item) => {
        if (newdatawithid.D_side !== "") {
            if (item.D_side === (newdatawithid.D_side)) {
                return { ...item, D_side: '____' } //to check whether D_side is added in the table 
            }
        }
        if (newdatawithid.Recovery_e_side !== "") {
            if (item.Recovery_e_side === parseInt(newdatawithid.Recovery_e_side)) {
                return { ...item, Recovery_e_side: '____' } //to check whether Recovery_e_side is added in the table 
            }
        }
        return item
    })
    checkdata.push(newdatawithid);
    const updatedData = [...tableData];
    updatedData[selectedIndex][tabNames[selectedIndex]] = checkdata;
    setCount(count - 1)
    setIsModalOpen(false);
    setTableData(updatedData);
}
export const submitstatus = (formdata) => {
    if (!(formdata?.Status === "T" || formdata?.Status === "SOGEA_D2")) {
        return !formdata?.Circuit || !formdata?.From_MDF_No || !formdata?.From_MDF_Bar || !formdata?.From_MDF_Pair || !formdata?.To_MDF_No || !formdata?.To_MDF_Bar || !formdata?.To_MDF_Pair || isNaN(formdata?.From_MDF_No) || !/^[a-zA-Z]+$/.test(formdata?.From_MDF_Bar) || isNaN(formdata?.From_MDF_Pair)
    }
    else {
        return !formdata?.Circuit || !formdata?.From_MDF_No || !formdata?.From_MDF_Bar || !formdata?.From_MDF_Pair || isNaN(formdata?.From_MDF_No) || !/^[a-zA-Z]+$/.test(formdata?.From_MDF_Bar) || isNaN(formdata?.From_MDF_Pair)
    }
}

// to check Circuit id is is empty or not
export const circuitStatus = (formdata) => {
    if (formdata?.Circuit) { return "" } else { return "Circuit id cannot be empty" }
}

// to check From_MDF_No is is empty or not
export const from_MDF_No_Status = (formdata) => {
    if (formdata?.From_MDF_No) { return "" } else { return "From_MDF_No cannot be empty" }
}

// to check From_MDF_Bar is is empty or not
export const From_MDF_Bar_Status = (formdata) => {
    if (formdata?.From_MDF_Bar) { return "" } else { return "From_MDF_Bar id cannot be empty" }
}

// to check From_MDF_Pair is is empty or not
export const From_MDF_Pair_Status = (formdata) => {
    if (formdata?.From_MDF_Pair) { return "" } else { return "From_MDF_Pair cannot be empty" }
}

// to check TO_MDF_No is is empty or not
export const To_MDF_No_status = (formdata) => {
    if (formdata?.To_MDF_No) { return "" } else { return "TO_MDF_No cannot be empty" }
}

// to check TO_MDF_Bar is is empty or not
export const To_MDF_Bar_Status = (formdata) => {
    if (formdata?.To_MDF_Bar) { return "" } else { return "TO_MDF_Bar id cannot be empty" }
}

// to check To_MDF_Pair is is empty or not
export const To_MDF_Pair_Status = (formdata) => {
    if (formdata?.To_MDF_Pair) { return "" } else { return "To_MDF_Pair cannot be empty" }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    handleAddData, submitstatus, circuitStatus, from_MDF_No_Status,
    From_MDF_Bar_Status, From_MDF_Pair_Status, To_MDF_No_status,
    To_MDF_Bar_Status, To_MDF_Pair_Status
}