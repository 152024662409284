import { useEffect, useState, useMemo } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Alert, Backdrop, Box, Checkbox, CircularProgress, TableSortLabel, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import ViewJobModal from './ViewJobModal';
import { ACR, BASE_URL, JOB_LIST } from '../../constant/urls';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { getComparator } from './MyJobModal.util';
import Tablepagination from '../common/Tablepagination';

const headCells = [
    {
        id: 'job_Id',
        label: 'Job Id',
        padding: "0 10px",
    },
    {
        id: 'sau',
        label: 'SAU',
        padding: "0 13px",

    },
    {
        id: 'exch_name',
        label: 'Exch Name',
        padding: "0 10px",
    },
    {
        id: 'selected_cable',
        label: 'Selected Cable',
        padding: "0 13px",
    },
    {
        id: 'picked_on',
        label: 'Picked On',
        padding: "0 13px",
    },
    {
        id: 'overall_status',
        label: 'Overall Status',
        padding: "0 12px",
    },
    {
        id: 'view',
        label: 'View',
        padding: "0 15px",

    }
];
export default function MyJobModal() {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [snackMsg, setSnackMsg] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const handleClose = () => setAlertOpen(false);
    const [jobList, setJobList] = useState([]);
    const { accounts } = useMsal()

    // axios call for table data
    useEffect(() => {
        handleMyjob()
    }, [])

    // axios  call for job details
    const handleMyjob = (item) => {
        setOpen(true)
        const joblisturl = `${BASE_URL}/${ACR}/${JOB_LIST}?ein=${accounts[0]?.idTokenClaims?.EIN}`
        axios.get(joblisturl,)
            .then((res) => {
                setJobList(res.data.Data);
                setLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setSnackMsg('Something went wrong!.Please try again!');
                setAlertOpen(true);
            })

    };

    // pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
 

    // to sort table column
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('column');

    // to sort the table
    const handleSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }




    // search and filter 
    const [excludeUnviable, setExcludeUnviable] = useState(false)
    const [showviable, setShowviable] = useState(false)

    const filteredRows = useMemo(() => {
        return jobList ? jobList.filter((row) => {
            return (
                (`${row.job_id}${row.sau}${row.selected_cable}`.toLowerCase()?.includes(search.toLowerCase())) &&
                (!excludeUnviable || !row.marked_as_unviable) &&
                (!showviable || row.marked_as_unviable)
            )
        }) : null
    }, [jobList, search, excludeUnviable, showviable]);

    const tableData = !filteredRows ? jobList : filteredRows;

    // handlechange ExcludeUnviable
    const handleExcludeUnviable = () => {
        setExcludeUnviable(!excludeUnviable);
        setPage(0);
    }

    // handlechange Showviable
    const handleShowviable = () => {
        setShowviable(!showviable);
        setPage(0);
    }

    const sortedarray = [...tableData].sort(getComparator(order, orderBy))
    const Tabledata = sortedarray
    return (
        <div>
            {loading &&

                <Box sx={{ width: '100%' }}>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            }
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Checkbox sx={{ marginRight: -1 }} id="myjob-checkbox-exclude-unviable" checked={excludeUnviable} onChange={handleExcludeUnviable} data-testid="myjob-checkbox-exclude-unviable" disabled={showviable} />
                <Typography sx={{ paddingTop: "10px", fontSize: "12px", fontWeight: "700", marginRight: 2 }} >Exclude Unviable cables</Typography>

                <Checkbox sx={{ marginRight: -1 }} id="myjob-checkbox-show-unviable" data-testid="myjob-checkbox-show-unviable" checked={showviable} onChange={handleShowviable} disabled={excludeUnviable} />
                <Typography sx={{ paddingTop: "10px", fontSize: "12px", fontWeight: "700" }}>Show only Unviable cables</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row', marginBottom: "10px", marginTop: "-10px" }}>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <span id="myjob-rowsperpage" style={{ fontSize: "14px" }}>Rows per Page</span>
                    <select style={{ margin: "0 9px", fontSize: "14px" }} data-testid="myjob-rowsperpage" value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                        {[5, 10, 25, 50, 100].map((option) => (
                            <option id={"myjob-rowsperpage" + option} key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <TextField
                    id="input-with-icon-textfield"
                    label="Search"
                    placeholder="Search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value); setPage(0); }}
                />

            </div>

            <TableContainer id="myjob-TableContainer" component={Paper} sx={{ position: "relative", maxHeight: "350px" }}>
                <Table id="myjob-Table" >
                    <TableHead id="myjob-TableHead" sx={{ top: 0, position: "sticky", zIndex: 1 }}>
                        <TableRow id="myjob-TableRow-head" sx={{ backgroundColor: "#5488c7", color: "white" }}>
                            {headCells.map((headcell) => (
                                <TableCell id={"myjob-" + headcell.id} key={headcell.id} sx={{ color: "white", border: "1px solid rgba(224, 224, 224, 1)", fontSize: "12px", fontWeight: "bold", padding: headcell.padding, overflow: 'hidden', flex: 1 }}>
                                    <TableSortLabel
                                        active={orderBy === headcell.id}
                                        direction={orderBy === headcell.id ? order : 'asc'}
                                        onClick={handleSort(headcell.id)}
                                    >
                                        {headcell.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {
                            Tabledata ? Tabledata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow id="myjob-tablerow" key={row.job_id}>
                                    <TableCell id={"myjob-tablecell-" + row.job_id} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px", width: "200px" }} align='center'>{row.job_id}</TableCell>
                                    <TableCell id={"myjob-tablecell-" + row.job_id + "-" + row.sau} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.sau}</TableCell>
                                    <TableCell id={"myjob-tablecell-" + row.job_id + "-" + row.exch_name} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.exch_name}</TableCell>
                                    <TableCell id={"myjob-tablecell-" + row.job_id + "-" + row.selected_cable} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.selected_cable}</TableCell>
                                    <TableCell id={"myjob-tablecell-" + row.job_id + "-picked_on"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.picked_on}</TableCell>
                                    <TableCell id={"myjob-tablecell-" + row.job_id + "-marked_as_unviable"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'>{row.marked_as_unviable === true ? "UNVIABLE" : row.overall_status}</TableCell>
                                    <TableCell id={"myjob-tablecell-" + row.job_id + "-ViewJobModal"} sx={{ border: "1px solid #c2c2c2", fontSize: "12px", margin: 0, padding: "1px 2px" }} align='center'><ViewJobModal job_id={row.job_id} exch_sau={row.exch_name} sau={row.sau} selected_cable={row.selected_cable} /></TableCell>
                                </TableRow>
                            )) : null
                        }
                    </TableBody>
                </Table>
                {tableData?.length === 0 ? <p style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>No data available in table</p> : null}

            </TableContainer>

            
            <Tablepagination
                setPage={setPage}
                page={page}
                count={tableData?.length}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
            />
        </div>
    );
} 