import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import CableInfo from './CableInfo';
import CabinetInfo from './CabinetInfo';

import { Button, Card, CardContent } from '@mui/material';
import { BreadCrumbContext } from '../common/BreadCrumbContext';

const DisplayInfo = ({ cableinfo, cabinetinfo, selectedPcp, sau, schedule, donor_cables, cable_id,actual_donor_cables }) => {
    const USER_EIN =JSON.parse(localStorage.getItem("USER_EIN"))
    const navigate = useNavigate();
    const { jobdetails } = useContext(BreadCrumbContext);
    const job_owner = jobdetails?.job_owner !== null ? jobdetails?.job_owner : "";
    const job_id = jobdetails?.job_id !== null ? jobdetails?.job_id : "";
    
    // selected pcps
    const PCPs = Object.keys(selectedPcp || {});

    const handleNavigate = () => {
        navigate({
            pathname: '/jumper_schedule',
            search: `?sau=${sau}&schedule=${schedule}&pcp_ids=${PCPs}&donor_cables=${donor_cables}&actual_donor_cables=${actual_donor_cables}&cable=${cable_id}&job_owner_ein=${job_owner}&job_id=${job_id}&logged_user_ein=${USER_EIN}`,
        });
    }
    return (
        <Card id="DisplayInfo-card" variant="outlined" sx={{ minWidth: 275, marginTop: "10px" }}>
            <CardContent id="DisplayInfo-card">

                {cableinfo && <CableInfo cableinfo={cableinfo} />}
                {cabinetinfo && <CabinetInfo cabinetinfo={cabinetinfo} />}

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button id="button-update-jumper-schedule" data-testid="button-update-jumper-schedule" style={{ color: "white", backgroundColor: "rgb(2, 2, 81)", width: "250px", height: "30px" }}
                        onClick={handleNavigate}>
                        Update Jumper Schedule</Button>
                </div>

            </CardContent>
        </Card >
    )
}

export default DisplayInfo