import axios from "axios";
import { ACR, BASE_URL, GET_REMARKS, VIEW_JOB } from "../../constant/urls";

export const handlesnackCloseFail = (setsnackOpenfail) => {
    setsnackOpenfail(false);
    return false
};
export const handlesnackCloseSuccess = (setsnackopensucess) => {
    setsnackopensucess(false);
    return false

};
export const handleexchsau = (viewjobid, exchsau, exchange, params, setJobdetailstable) => {
    if (viewjobid) {
        if (exchsau === exchange ? viewjobid : null) {
            const url = `${BASE_URL}/${ACR}/${VIEW_JOB}?job_id=${viewjobid}`
            axios.get(url, { params: params })
                .then((res) => {
                    setJobdetailstable(res.data.Data);
                })
        }
    }
    return viewjobid
}
// using axios api calls api call for remarks table
export const handleRemarks = (job_id, params, setRemarksData, handleTable, setsnackOpenfail, setSnackmsg) => {
    const remarksurl = `${BASE_URL}/${ACR}/${GET_REMARKS}?job_id=${job_id}`
    axios.get(remarksurl, { params: params })
        .then((res) => {
            setRemarksData(res.data.Data);
            handleTable()
        })
        .catch((error) => {
            console.error(error);
            setsnackOpenfail(true);
            setSnackmsg("Something went wrong!.Please try again!")
        })
    return null
};

// open job
export const handleOpenJob = (setExchsau, setViewjobid, exchange, jobid) => {
    setExchsau(exchange);
    setViewjobid(jobid)
    return jobid
}

// functionality to disable on hold
export const holddisable = (jobdetailsdisable,jobdetailstable,tabledata) => {
    if (jobdetailsdisable) {
        return jobdetailsdisable
    }
    else if (jobdetailstable?.length === 0) {
        return true;
    }
    else if (jobdetailstable?.marked_as_onhold === false && jobdetailstable.marked_as_unviable === false) {
        return false
    }
    else {
        return tabledata[0]?.marked_as_unviable
    }
};
 // functionality to disable on viable
export const viabledisable = (jobdetailsdisable,jobdetailstable,tabledata) => {
    if (jobdetailsdisable) {
        return jobdetailsdisable
    }
    else if (jobdetailstable?.length === 0) {
        return true;
    }
    else if (jobdetailstable?.marked_as_onhold === false && jobdetailstable.marked_as_unviable === false) {
        return false
    }
    else {
        return tabledata[0]?.marked_as_onhold
    }
};

// functionality to disable on rolback
export const handleRollbackdisable = (jobdetailsdisable,disable,activeStep,viable,rollbackdisable) => {
    if (jobdetailsdisable) {
        return jobdetailsdisable
    }
    else if (disable) {
        return disable;
    }
    else if (activeStep > 3 && viable === false) {
        return true
    }
    else if (viable === true) {
        return false
    }
    else if (rollbackdisable) {
        return rollbackdisable
    }
}

// functionality to disable on move next
export const handleMoveNextdisable = (jobdetailsdisable,disable,viable,activeStep) => {
    if (jobdetailsdisable) {
        return jobdetailsdisable
    }
    else if (disable) {
        return disable;
    }
    else if (viable === true) {
        return true
    }
    else if (activeStep === 7) {
        return true
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default { handlesnackCloseFail, handlesnackCloseSuccess, handleexchsau, handleOpenJob, holddisable,viabledisable,handleRollbackdisable }