
const headCells = [
    {
        id: 'PCP',
        label: 'PCP',
        padding: "2px",
        width: 80
    },
    {
        id: 'Select',
        label: 'Select',
        padding: "2px",
        width: 120
    },
    {
        id: 'SAU',
        label: 'SAU',
        padding: "2px",
        width: 84
    },
    {
        id: 'Recovery Cable',
        label: 'Recovery Cable',
        padding: "2px",
        width: 76
    },
    {
        id: 'EODP',
        label: 'EODP',
        padding: "2px",
        width: 84
    },
    {
        id: 'Host Cable',
        label: 'Host Cable',
        padding: "12px",
        width: 82
    },
    {
        id: 'Working',
        label: 'Working',
        padding: "2px",
        width: 104
    },
    {
        id: 'Target',
        label: 'Target',
        padding: "2px",
        width: 85
    },
    {
        id: 'Available',
        label: 'Available',
        padding: "2px",
        width: 80
    }
];

export default headCells;