import axios from 'axios';
import { ContentState, EditorState } from 'draft-js';

import htmlToDraft from 'html-to-draftjs';
import { BASE_URL, HCR, UPDATE_HOME } from '../../constant/urls';
export const handlesnackClose = (setAlertOpen) => {
    setAlertOpen(false)
    return false
}
export const handleChange = (newValue, setValue) => {
    setValue(newValue);
    return newValue
};

export const handleOpen = (content, setEditedContent, setIsOpen) => {
    if (content && typeof content === "string") {
        const contentstate = ContentState.createFromBlockArray(htmlToDraft(content))
        setEditedContent(EditorState.createWithContent(contentstate))
    }
    else {
        setEditedContent(EditorState.createEmpty())
    }
    setIsOpen(true)
    return content
}

export const handleClose = (setIsOpen) => {
    setIsOpen(false)
    return false
}

export const handleSave = (tabkeys, value, content, accounts, getApiData, setSnackMsg, setAlertOpen, setIsOpen) => {

    const tabKey = tabkeys[value];


    const postData = {
        "home_key": tabKey,
        "data": content,
        "logged_user": accounts[0]?.idTokenClaims.EIN
    }

    axios.put(`${BASE_URL}${HCR}/${UPDATE_HOME}`, postData)
        .then((res) => {
            getApiData()
        })
        .catch((error) => {
            console.error(error)

            setSnackMsg('Something went wrong!.Please try again!');
            setAlertOpen(true);
        })
    setIsOpen(false)
    return false

}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    handlesnackClose, handleChange, handleOpen, handleClose, handleSave
}