/* eslint-disable complexity */
import { Button, Modal, Box, TextField, MenuItem, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    handleAddData, submitstatus, circuitStatus, from_MDF_No_Status, From_MDF_Bar_Status,
    From_MDF_Pair_Status, To_MDF_No_status, To_MDF_Bar_Status, To_MDF_Pair_Status
} from './JumperAdd.util';

export default function JumperAdd({ isOpen, closeModal, data, dropdownMdf, dropdownCircuit, tableData, selectedIndex, tabNames, count, setCount, setIsModalOpen, setTableData, pcpName }) {

    const [formdata, setFormdata] = useState(
        {
            "ADSL_TAM_LLU": null,
            "Amendments": null,
            "Circuit": null,
            "D_side": null,
            "EET": null,
            "From_MDF_Bar": null,
            "From_MDF_No": null,
            "From_MDF_Pair": null,
            "LLU": null,
            "New_E_Side": null,
            "Pcp": null,
            "Recovery_e_side": null,
            "Status": null,
            "Tie": null,
            "Tie1": null,
            "To_MDF_Bar": null,
            "To_MDF_No": null,
            "To_MDF_Pair": null,
            "To_Status": null,
            "Unit": null,
            "Host_Cable": null
        }
    );

    const [errors, setErrors] = useState(
        {
            "Circuit": "",
            "From_MDF_Bar": "",
            "From_MDF_No": "",
            "From_MDF_Pair": '',
            "To_MDF_Bar": "",
            "To_MDF_No": "",
            "To_MDF_Pair": '',
            "validate_From_MDF_Bar": "",
            "validate_From_MDF_No": "",
            "validate_From_MDF_Pair": '',
        });

    const [validation, setValidation] = useState(
        {
            "validate_From_MDF_Bar": "",
            "validate_From_MDF_No": "",
            "validate_From_MDF_Pair": '',
        });

    const [tomdfbar, setTomdfbar] = useState([])
    const [tomdfpair, setTomdfpair] = useState([])
    const [eside, setEside] = useState('');
    const [status, setStatus] = useState('');
    const [hostcable, setHostcable] = useState('');


    const handleChange = (event) => {
        let { name, value } = event.target;
        if ((name === "Status" && value === "T") || (name === "Status" && value === "SOGEA_D2")) {
            const data = { ...formdata, "Status": value, "To_Status": null, "New_E_Side": null, "To_MDF_No": null, "To_MDF_Bar": null, "To_MDF_Pair": null, "Host_Cable": null };
            setFormdata(data);
            setEside(null);
            setStatus(null)
        }
        else if (name === 'From_MDF_No' || name === "From_MDF_Pair" || name === "D_side" || name === "Recovery_e_side") {
            const intvalues = name === 'From_MDF_No' || name === "From_MDF_Pair" || name === "D_side" || name === "Recovery_e_side" ? parseInt(value, 10) : value;
            const values = isNaN(intvalues) ? "" : parseInt(value, 10)
            setFormdata({
                ...formdata, [name]: values
            })
        }
        else {
            setFormdata({
                ...formdata, [name]: value
            })
        }
        if (name === "To_MDF_Bar") {
            setTomdfpair([]);
            setEside('');
            setStatus('');
            setHostcable('')
        }
    }
    // eslint-disable-next-line

    // dropdown values for to mdf bar 
    useEffect(() => {
        if (formdata.To_MDF_No) {
            const mdfs = []
            const mdfbar = dropdownMdf?.to_mdf_bar[formdata?.To_MDF_No];
            mdfbar.forEach(element => {
                const comibinedata = data?.filter((item) => {
                    const combine = (item?.To_MDF_Bar?.includes(formdata?.To_MDF_Bar))
                    return combine
                });
                const datapair = comibinedata.map((item) => item?.To_MDF_Pair)
                const pairs = dropdownMdf?.to_mdf_pair[formdata?.To_MDF_No]?.[element] ? Object.keys(dropdownMdf?.to_mdf_pair[formdata?.To_MDF_No][element]) : [];
                const pair = pairs?.map((str) => parseInt(str, 10))
                const pairfilter = pair?.filter((item) => !datapair.includes(item));
                const assignpair = []
                assignpair[element] = pairfilter
                if (assignpair[element].length > 0) {
                    mdfs.push(element)
                }
                setTomdfbar(mdfs)
            }
            )
        }
        // eslint-disable-next-line
    }, [formdata.To_MDF_No])


    // dropdown values for to mdf pair 
    useEffect(() => {
        if (formdata?.To_MDF_Bar) {
            const comibinedata = data?.filter((item) => {
                const combine = (item?.To_MDF_Bar?.includes(formdata?.To_MDF_Bar))
                return combine
            });
            const datapair = comibinedata?.map((item) => item?.To_MDF_Pair)
            const pairs = (Object.keys(dropdownMdf?.to_mdf_pair[formdata?.To_MDF_No][formdata?.To_MDF_Bar]))
            const pair = pairs?.map((str) => parseInt(str, 10))
            const final = pair?.filter((item) => !datapair?.includes(item));
            setTomdfpair(final);
            const datac = { ...formdata, "To_MDF_Pair": "" }
            setFormdata(datac)
        }
        // eslint-disable-next-line
    }, [formdata.To_MDF_Bar])


    //  values for eside, status and host cable based on to mdf no,bar and pair 
    useEffect(() => {
        if (formdata?.To_MDF_Pair) {
            const data = (dropdownMdf?.to_mdf_pair[formdata?.To_MDF_No][formdata?.To_MDF_Bar][formdata?.To_MDF_Pair])
            setEside(data.eside);
            setStatus(data.status);
            setHostcable(data.host_cable)
        }
        if (formdata?.To_MDF_Pair === "") {
            setEside('');
            setStatus('');
            setHostcable('')
        }
        // eslint-disable-next-line
    }, [formdata.To_MDF_Pair]);

    const [disableApply, setDisableApply] = useState(false);
    const isdisabled = formdata.Status === "T" || formdata.Status === "SOGEA_D2";

    // on submit add button
    const handleSubmit = (event) => {
        event.preventDefault();
        const condition = submitstatus(formdata)
        if (condition) {
            setErrors({
                Circuit: circuitStatus(),
                From_MDF_No: from_MDF_No_Status(),
                From_MDF_Bar: From_MDF_Bar_Status(),
                From_MDF_Pair: From_MDF_Pair_Status(),
                To_MDF_No: To_MDF_No_status(),
                To_MDF_Bar: To_MDF_Bar_Status(),
                To_MDF_Pair: To_MDF_Pair_Status(),
                validate_From_MDF_No: isNaN(formdata.From_MDF_No) ? "MDF No must be a number" : '',
                validate_From_MDF_Bar: !/^[a-zA-Z]+$/.test(formdata.From_MDF_Bar) ? "MDF Bar must be a character" : "",
                validate_From_MDF_Pair: isNaN(formdata.From_MDF_Pair) ? "MDF Pair must be a number" : ''
            })
            setValidation({
                validate_From_MDF_No: isNaN(formdata.From_MDF_No) ? "MDF No must be a number" : '',
                validate_From_MDF_Bar: !/^[a-zA-Z]+$/.test(formdata.From_MDF_Bar) ? "MDF Bar must be a character" : "",
                validate_From_MDF_Pair: isNaN(formdata.From_MDF_Pair) ? "MDF Pair must be a number" : ''
            })

        }

        else {
            const newdata = { ...formdata, "To_Status": status, "New_E_Side": eside, "Pcp": pcpName, "Host_Cable": hostcable };
            setDisableApply(false)
            handleAddData(newdata, data, tabNames, selectedIndex, setCount, count, setIsModalOpen, tableData, setTableData);
            setFormdata({
                "ADSL_TAM_LLU": null,
                "Amendments": null,
                "Circuit": null,
                "D_side": null,
                "EET": null,
                "From_MDF_Bar": null,
                "From_MDF_No": null,
                "From_MDF_Pair": null,
                "LLU": null,
                "New_E_Side": null,
                "Pcp": null,
                "Recovery_e_side": null,
                "Status": null,
                "Tie": null,
                "Tie1": null,
                "To_MDF_Bar": null,
                "To_MDF_No": null,
                "To_MDF_Pair": null,
                "To_Status": null,
                "Unit": null,
                "Host_Cable": null
            });
            setErrors(
                {
                    "Circuit": "",
                    "From_MDF_Bar": "",
                    "From_MDF_No": "",
                    "From_MDF_Pair": '',
                });
            setValidation(
                {
                    "validate_From_MDF_Bar": "",
                    "validate_From_MDF_No": "",
                    "validate_From_MDF_Pair": '',
                });
            setEside('');
            setTomdfbar('');
            setTomdfpair('')
            setStatus('')
            setHostcable('')
            closeModal()
        }
    }

    // on click Dismiss
    const handleClose = () => {
        setFormdata({
            "ADSL_TAM_LLU": '',
            "Amendments": '',
            "Circuit": "",
            "D_side": '',
            "EET": '',
            "From_MDF_Bar": "",
            "From_MDF_No": "",
            "From_MDF_Pair": '',
            "LLU": '',
            "New_E_Side": '',
            "Pcp": '',
            "Recovery_e_side": '',
            "Status": "",
            "Tie": '',
            "Tie1": '',
            "To_MDF_Bar": "",
            "To_MDF_No": '',
            "To_MDF_Pair": '',
            "To_Status": "",
            "Unit": '',
            "Host_Cable": ''
        })
        setErrors(
            {
                "Circuit": "",
                "From_MDF_Bar": "",
                "From_MDF_No": "",
                "From_MDF_Pair": '',
            });
        setValidation(
            {
                "validate_From_MDF_Bar": "",
                "validate_From_MDF_No": "",
                "validate_From_MDF_Pair": '',
            });
        setEside('');
        setTomdfbar('');
        setStatus('');
        setHostcable('')
        closeModal()
    }

    return (
        <Modal
            id={pcpName + "-Modal"}
            open={isOpen}
            sx={{ marginTop: "30px", overflowY: "scroll" }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{ flexGrow: 1, maxWidth: "1000px", height: "auto", display: "flex", padding: "20px", alignItems: "center", flexDirection: 'column', margin: 'auto' }} component={Paper}>
                <Box id={pcpName + "-Add"} sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }} noValidate autoComplete="off"  >
                    <form onSubmit={handleSubmit}>
                        <div>
                            <TextField
                                size="small"
                                id={pcpName + "-Circuit"}
                                label="Add Circuit"
                                name="Circuit"
                                data-testid="add-circuit"
                                autoComplete="current-Circuit"
                                value={formdata.Circuit || ""}
                                onChange={handleChange}
                                error={errors.Circuit}
                                helperText={errors.Circuit}
                            />

                            <TextField
                                size="small"
                                select
                                label="Add Status"
                                name="Status"
                                inputProps={{ id: pcpName + "-Status" }}
                                value={formdata.Status || ""}
                                onChange={handleChange}

                            >
                                {dropdownCircuit.map((option) => (
                                    <MenuItem key={option} value={option.toUpperCase()}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                size="small"
                                id={pcpName + "-Tie"}
                                label="Add Tie"
                                name="Tie"
                                autoComplete="current-Tie"
                                value={formdata.Tie || ""}
                                onChange={handleChange}
                            />

                            <TextField
                                size="small"
                                id={pcpName + "-ADSL_TAM_LLU"}
                                label="Add ADSL/TAM/LLU"
                                name="ADSL_TAM_LLU"
                                value={formdata.ADSL_TAM_LLU || ""}
                                autoComplete="current-ADSL_TAM_LLU"
                                onChange={handleChange}
                            />

                            <TextField
                                size="small"
                                id={pcpName + "-LLU"}
                                label="Add LLU"
                                name="LLU"
                                autoComplete="current-LLU"
                                value={formdata.LLU || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                id={pcpName + "-Tie1"}
                                label="Add Tie1"
                                name="Tie1"
                                autoComplete="current-Tie1"
                                value={formdata.Tie1 || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                id={pcpName + "-EET"}
                                label="Add EET"
                                name="EET"
                                autoComplete="current-EET"
                                value={formdata.EET || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                id={pcpName + "-Unit"}
                                label="Add Unit"
                                name="Unit"
                                autoComplete="current-Unit"
                                value={formdata.Unit || ""}
                                onChange={handleChange}
                            />

                            <TextField
                                size="small"
                                id={pcpName + "-From_MDF_No"}
                                label="Add From MDF No"
                                name="From_MDF_No"
                                value={parseInt(formdata.From_MDF_No) || ""}
                                autoComplete="current-From_MDF_No"
                                onChange={handleChange}
                                error={errors.From_MDF_No}
                                helperText={errors.From_MDF_No}
                            />

                            <TextField
                                size="small"
                                id={pcpName + "-From_MDF_Bar"}
                                label="Add From MDF Bar"
                                name="From_MDF_Bar"
                                value={formdata.From_MDF_Bar || ""} autoComplete="current-add_from_mdf_bar"
                                onChange={handleChange}
                                error={errors.From_MDF_Bar}
                                helperText={errors.From_MDF_Bar}
                            />
                            <TextField
                                size="small"
                                id={pcpName + "-From_MDF_Pair"}
                                label="Add From MDF Pair"
                                name="From_MDF_Pair"
                                value={formdata.From_MDF_Pair || ""}
                                autoComplete="current-add_from_mdf_pair"
                                onChange={handleChange}
                                error={errors.From_MDF_Pair}
                                helperText={errors.From_MDF_Pair}
                            />

                            <TextField
                                size="small"
                                id={pcpName + "-Amendments"}
                                label="Add Amendments"
                                name="Amendments"
                                autoComplete="current-Amendments"
                                value={formdata.Amendments || ""}
                                onChange={handleChange}
                            />

                            <TextField
                                size="small"
                                id={pcpName + "-Pcp"}
                                name="Pcp"
                                value={pcpName}
                                inputProps={{ readOnly: true }}
                                autoComplete="current-Pcp"
                                onChange={handleChange}
                            />

                            <TextField
                                size="small"
                                id={pcpName + "-Recovery_e_side"}
                                label="Add Recovery E Side"
                                name="Recovery_e_side"
                                autoComplete="current-Recovery_e_side"
                                value={formdata.Recovery_e_side || ""}
                                onChange={handleChange}
                            />
                            <TextField
                                size="small"
                                id={pcpName + "-D_side"}
                                label="Add D Side"
                                name="D_side"
                                autoComplete="current-D_side"
                                value={formdata.D_side || ""}
                                onChange={handleChange}
                            />

                            <TextField
                                size="small"
                                select
                                label="Add To MDF No"
                                name="To_MDF_No"
                                inputProps={{ id: pcpName + "-To_MDF_No" }}

                                data-testid="To-MDF-No"
                                value={formdata.To_MDF_No || ""}
                                onChange={handleChange}
                                disabled={isdisabled}
                                error={!isdisabled && errors.To_MDF_No}
                                helperText={!isdisabled && errors.To_MDF_No}
                            >
                                {
                                    dropdownMdf.to_mdf_no?.map((option) => (
                                        <MenuItem key={option} data-testid={`toMdf-${option}`} value={option}>{option} </MenuItem>
                                    ))
                                }

                            </TextField>

                            <TextField
                                size="small"
                                select
                                label="Add To MDF Bar"
                                data-testid="Add To MDF Bar"
                                name="To_MDF_Bar"
                                inputProps={{ id: pcpName + "-To_MDF_Bar" }}

                                value={formdata.To_MDF_Bar || ""}
                                onChange={handleChange}
                                disabled={isdisabled}
                                error={!isdisabled && errors.To_MDF_Bar}
                                helperText={!isdisabled && errors.To_MDF_Bar}
                            >
                                {
                                    tomdfbar ? tomdfbar.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    )) : <MenuItem >  </MenuItem>
                                }

                            </TextField>

                            <TextField
                                size="small"
                                select
                                label="Add new Bar Pair"
                                name="To_MDF_Pair"
                                inputProps={{ id: pcpName + "-To_MDF_Pair" }}

                                value={formdata.To_MDF_Pair || ""}
                                onChange={handleChange}
                                disabled={isdisabled}
                                error={!isdisabled && errors.To_MDF_Pair}
                                helperText={!isdisabled && errors.To_MDF_Pair}
                            >
                                {
                                    tomdfpair.length !== 0 ? tomdfpair?.map((option) => (
                                        <MenuItem key={option} value={option}>{option} </MenuItem>
                                    )) : null
                                }
                            </TextField>

                            <TextField
                                size="small"
                                id={pcpName + "-New_E_Side"}
                                name="New_E_Side"
                                label="New_E_Side"
                                value={eside}
                                inputProps={{ readOnly: true }}
                                autoComplete="current-Pcp"
                                onChange={handleChange}
                                disabled={isdisabled}
                            />

                            <TextField
                                id={pcpName + "-To_Status"}
                                size="small"
                                label="To Status"
                                value={status}
                                name="To_Status"
                                onChange={handleChange}
                                disabled={isdisabled}
                            />


                            <TextField
                                id={pcpName + "-Host_Cable"}
                                size="small"
                                label="Host cable"
                                value={hostcable}
                                name="Host_Cable"
                                onChange={handleChange}
                                disabled={isdisabled}
                            />
                        </div>
                        <div>
                            <p id={"MDF-No-must-be-a-number-" + pcpName} style={{ color: "red", margin: 0, fontSize: "12px" }}>{validation.validate_From_MDF_No}</p>
                            <p id={"MDF-Bar-must-be-a-character-" + pcpName} style={{ color: "red", margin: 0, fontSize: "12px" }}>{validation.validate_From_MDF_Bar}</p>
                            <p id={"MDF-Pair-must-be-a-number-" + pcpName} style={{ color: "red", margin: 0, fontSize: "12px" }}>{validation.validate_From_MDF_Pair}</p>
                        </div>
                        <div style={{ marginTop: "20px", display: "flex", justifyContent: "center", gap: "20px" }}>
                            <Button id={"button-dismiss-" + pcpName} data-testid="dismiss-button" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} onClick={handleClose}>Dismiss</Button>
                            <Button id={"button-apply-" + pcpName} data-testid="apply-button" style={{ color: "white", backgroundColor: "#142032", height: "30px", float: "right" }} type='submit' disabled={disableApply}>Apply</Button>
                        </div>
                    </form>
                </Box>
            </Box>
        </Modal>
    );
}

JumperAdd.propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    count: PropTypes.number,
    tabNames: PropTypes.array,
};



